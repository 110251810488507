import CollaborateNowBtn from './CollaborateNowBtn';
import SignUpButton from './SignUpButton';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DiscordIcon} from '../assets/images/discord-icon.svg';
import {ReactComponent as TwitterIcon} from '../assets/images/twitter-icon.svg';
import {useWindowSize} from "../utils/Utils";
import {useContext} from "react";
import AppContext from "../store/AppContext";

const Footer = ({openSignUpModal}) => {
  const {t} = useTranslation();
  const [width] = useWindowSize();
  const context = useContext(AppContext);

  return (
    <footer className='footer'>
      <div className='footer__wrapper'>
        <div className='footer__top'>
          <img className='footer__logo' src={require('../assets/images/logo.svg').default} alt='' />
          <div className='footer__contacts'>
            <ul className='footer__socials-list'>
              <li className='footer__social-item footer__discord-btn'>
                <a
                  className='footer__social-link footer__social-link--discord'
                  href='https://discord.gg/ZeSeUt3wEj'
                  target='_blank'
                  rel='noreferrer'
                  aria-label='Discord'>
                  <DiscordIcon />
                  {t('footer.discordBtn')}
                </a>
              </li>
              {
                <li className='footer__social-item footer__twitter-btn'>
                  <a
                    className='footer__social-link footer__social-link--twitter'
                    href='https://twitter.com/GyreTv'
                    target='_blank'
                    rel='noreferrer'
                    aria-label='Twitter'>
                    <TwitterIcon />
                  </a>
                </li>
              }
            </ul>
            <a className='footer__social-link footer__email' href='mailto:contact@gyre.tv'>
              contact@gyre.tv
            </a>
          </div>
          {width >= 1280 ? (
            <div className='footer__buttons'>
              {context.isAuthenticated ? null : (
                <SignUpButton openSignUpModal={openSignUpModal} classNames='footer__sign-up-btn' />
              )}
              <CollaborateNowBtn classNames='footer__collaborate-btn' />
            </div>
          ) : null}
        </div>
        <div className='footer__bottom'>
          <span className='footer__copyright'>
            © {new Date().getFullYear()} Gyre ·{' '}
            <Link className='footer__privacy-link' to='/privacy-policy'>
              {t('footer.privacyPolicyLink')}
            </Link>
          </span>
          <span className='footer__designer'>
            Designed with love by{' '}
            <a
              className='footer__designer-link'
              href='https://www.behance.net/pteeradukteel'
              target='_blank'
              rel='noreferrer'>
              Maxim Cheremisov
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
