import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ReactComponent as TwitchIcon} from '../assets/images/twitch-icon.svg';
import {ReactComponent as CloseIcon} from '../assets/images/close-icon.svg';
import {useState} from 'react';

const SignUpModal = ({
  onClick
}) => {
  const {t} = useTranslation();
  const [showError, setShowError] = useState(false);
  const {REACT_APP_GYRE_BACKEND} = process.env;

  return (
    <section className='sign-up-modal'>
      <div className='sign-up-modal__background' />
      <div className='sign-up-modal__modal'>
        <div className='sign-up-modal__wrapper'>
          <div className='sign-up-modal__header'>
            <h2 className='sign-up-modal__title'>{t('signUpMdl.title')}</h2>
            <button className='sign-up-modal__close-button' onClick={onClick}>
              <CloseIcon />
            </button>
          </div>
          <p className='sign-up-modal__description'>{t('signUpMdl.description')}</p>
          {showError ?
            <div className='sign-up-modal__error'>
              <p className='sign-up-modal__error-description'>
                {t('signUpMdl.error1')}
                <a href='mailto:contact@gyre.tv' className='sign-up-modal__email-link'>contact@gyre.tv</a>
                {t('signUpMdl.error2')}
              </p>
            </div>
            : null}
          <a
            href={`${REACT_APP_GYRE_BACKEND}/api/login/twitch?finish_url=${window.location.href}`}
            className='sign-up-modal__sign-up-with-twitch-button'>
            <TwitchIcon />
            {t('signUpMdl.twitchBtn')}
          </a>
          <button className='sign-up-modal__dont-have-twitch-btn' onClick={() => setShowError(true)}>
            {t('signUpMdl.noAccountBtn')}
          </button>
          <p className='sign-up-modal__agreement'>
            {t('signUpMdl.agreement.text')}{' '}
            <Link className='sign-up-modal__agreement-link' to='/privacy-policy' onClick={onClick}>
              {t('signUpMdl.agreement.linkText')}
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default SignUpModal;
