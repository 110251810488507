import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

const CollabHubPage = ({api, loggedInStatus, openSignUpModal}) => {
  const {t} = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('CollabHubPage.helmetTitle')}</title>
      </Helmet>
      Page development is in Progress
    </>
  );
};

export default CollabHubPage;
