import i18n from 'i18next';
import SelectorWithIcons from '../selectors/SelectorWithIcons';
import {ReactComponent as FlagRuIcon} from '../../assets/images/flag-ru.svg';
import {ReactComponent as FlagEnIcon} from '../../assets/images/flag-en.svg';
import SettingsService from "../../services/SettingService";

const languageOptions = [
  {value: 'en', label: 'English', icon: <FlagEnIcon />},
  {value: 'ru', label: 'Russian', icon: <FlagRuIcon />}
];

const SiteLanguageSelector = props => {

    const onChangeHandler = (selectedOption) => {
        i18n.changeLanguage(selectedOption.value);
        SettingsService.setWebsiteLanguage(selectedOption.value);
    }

    const getLanguageOption = () => {
        const lang = languageOptions.find(option => {
            const lang = i18n.language;
            return option.value === lang;
        });
        
        return lang || languageOptions[0];
    }

    return (
    <SelectorWithIcons
      isClearable={false}
      value={getLanguageOption()}
      options={languageOptions}
      onChange={onChangeHandler}
      {...props}
    />
  );
};

export default SiteLanguageSelector;
