import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';
import TwitchUserLink from '../components/TwitchUserLink';
import StreamerGames from '../components/find-collab/StreamerGames';
import {useState, useEffect, useContext} from 'react';
// import AcceptRequestModal from '../components/collaboration-request/AcceptRequestModal';
import {useTranslation} from 'react-i18next';
import {ReactComponent as TwitchIcon} from '../assets/images/twitch-icon.svg';
import ConfirmCollabModal from '../components/ConfirmCollabModal.js';
import SettingsService from '../services/SettingService';
import SignUpModal from "../components/SignUpModal";
import {PartnershipStatus} from "../utils/Collections";
import {getAvgViewersBucket, getIsMatureString, setStreamerLanguage} from "../utils/Utils";
import StreamersService from "../services/StreamersService";
import AppContext from "../store/AppContext";

const moment = require('moment');

const CollaborationRequestPage = () => {
  const {t} = useTranslation();
  const {REACT_APP_GYRE_BACKEND} = process.env;
  const [collab, setCollab] = useState();
  const [loading, setLoading] = useState(true);
  const collabId = window.location.pathname.replace('/collab/', '');
  // const [acceptModal, setAcceptModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [collabStatus, setCollabStatus] = useState('');
  const history = useHistory();
  const context = useContext(AppContext);

  // const showAcceptModal = () => {
  //   setAcceptModal(!acceptModal);
  // };
  
  const showConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  useEffect(() => {
    const fetchData = async () => {
      const getCollab = await fetch(`${REACT_APP_GYRE_BACKEND}/api/collab_request/${collabId}`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      })
        .then(res => {
          if (!res.ok) {
            history.push('/404');
            throw new Error(res.status);
          }
          return res.json();
        })
        .catch(error => {
          console.log(error);
        });

      const streamersData = await StreamersService.getStreamer(getCollab.sender_id, false, false, false);
      
      setCollab({
        sender: streamersData,
        data: getCollab
      });
      setLoading(false);
    };

    fetchData();
  }, []);
  
  const checkLoginStatus = () => {
    if (!context.isAuthenticated) {
      openSignUpForm();
      return false;
    }
    return true;
  };

  const updateCollabStatus = status => {
    if (!checkLoginStatus()) return;
    
    setCollabStatus(status);
    fetch(`${REACT_APP_GYRE_BACKEND}/api/collab_request?id=${collabId}&status=${status}`, {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'applicbation/json;charset=utf-8'
      }
    })
    .then(res => {
      if (res.ok) {
        showConfirmModal(!confirmModal);
        setCollabStatus(status);
      }
      else {
        res.text().then((value)=> {
              window.alert(value);
          }
        );
      }
    })
    .catch(() => {
      showConfirmModal(!confirmModal);
      setCollabStatus('error');
    });
  };

  const getStreamLink = () => {
    if (collab.data.twitch_vod_id) {
      return `https://www.twitch.tv/videos/${collab.data.twitch_vod_id}`;
    } else if (collab.data.youtube_id) {
      return `https://www.youtube.com/watch?v=${collab.data.youtube_id}`;
    }
  };

  const getStreamPreviewImage = () => {
    if (collab.data.youtube_id) {
      return `https://img.youtube.com/vi/${collab.data.youtube_id}/0.jpg`;
    } else {
      return require('../assets/images/stream-preview.png').default;
    }
  };

  const [signUpForm, setSignUpForm] = useState(false);
  const openSignUpForm = () => {
    setSignUpForm(!signUpForm);
  };

  useEffect(() => {
    if (signUpForm) {
      document.body.classList.add('scroll-off');
    } else {
      document.body.classList.remove('scroll-off');
    }
  }, [signUpForm]);

  return (
    <>
      {!loading ? (
        <>
          <Helmet>
            <title>
              {`${t('collaborationRequestPage.helmetTitle')} 
            ${collab.sender.twitch_username}`}
            </title>
          </Helmet>
          <section className='collaboration-request'>
            <div className='collaboration-request__wrapper'>
              <div className='collaboration-request__container'>
                <h2 className='collaboration-request__title'>
                  {`${t('collaborationRequestPage.mainTitle')} ${
                    collab.sender.twitch_username
                  }`}
                </h2>
                <p className='collaboration-request__description collaboration-request__description--block-description'>
                  {t('collaborationRequestPage.description')}
                </p>
                <h3 className='collaboration-request__subtitle'>
                  {t('collaborationRequestPage.streamerInfo.title')}
                </h3>
                <div className='collaboration-request__streamer-card'>
                  <div className='collaboration-request__about streamer-info__about'>
                    <div
                      className={`collaboration-request__avatar-wrapper
                    streamer-info__avatar-wrapper
                    ${
                        collab.sender.is_registered
                          ? 'streamer-info__avatar-wrapper--verified'
                          : ''
                      }`}>
                      <img
                        className='collaboration-request__avatar streamer-info__avatar'
                        src={collab.sender.picture}
                        alt=''
                      />
                    </div>
                    <div className='collaboration-request__nickname-wrapper streamer-info__nickname-wrapper'>
                      <span className='collaboration-request__nickname streamer-info__nickname'>
                        {collab.sender.twitch_username}
                      </span>
                      {collab.sender.is_registered ? (
                        <span className='streamer__partner-icon streamer-info__partner-icon'>
                          <img
                            src={require('../assets/images/gyre-verified-icon.svg').default}
                            alt='gyre verified'
                          />
                        </span>
                      ) : null}
                      {collab.sender.partner === PartnershipStatus.Partner ? (
                        <span className='streamer__partner-icon streamer-info__partner-icon'>
                          <img
                            src={require('../assets/images/twitch-partner-icon.svg').default}
                            alt='twitch partner'
                          />
                        </span>
                      ) : null}
                    </div>
                    <TwitchUserLink
                      classNames='streamers__twitch-link'
                      link={`https://www.twitch.tv/${collab.sender.twitch_username}`}
                    />
                    <div className='collaboration-request__break streamer-info__break' />
                    <StreamerGames
                      classNames='collaboration-request__games-list'
                      gameList={collab.sender.games}
                      streamerGameIds={collab.sender.game_ids}
                    />
                  </div>
                  <div className='collaboration-request__info streamer-info__info'>
                    <ul className='collaboration-request__info-list streamer-info__info-list'>
                      <li className='collaboration-request__info-item streamer-info__info-item'>
                        <span className='collaboration-request__info-title streamer-info__info-title'>
                          {t('collaborationRequestPage.streamerInfo.averageViews')}
                        </span>
                        <span className='collaboration-request__info-value streamer-info__info-value'>
                          {collab.sender.avg_viewers
                            ? getAvgViewersBucket(collab.sender.avg_viewers)
                            : t('streamersComponent.streamerInfo.noData')}
                        </span>
                      </li>
                      <li className='collaboration-request__info-item streamer-info__info-item'>
                        <span className='collaboration-request__info-title streamer-info__info-title'>
                          {t('collaborationRequestPage.streamerInfo.followers')}
                        </span>
                        <span className='collaboration-request__info-value streamer-info__info-value'>
                          {collab.sender.followers
                            ? collab.sender.followers.toLocaleString('en')
                            : t('streamersComponent.streamerInfo.noData')}
                        </span>
                      </li>
                      <li className='collaboration-request__info-item streamer-info__info-item'>
                        <span className='collaboration-request__info-title streamer-info__info-title'>
                          {t('collaborationRequestPage.streamerInfo.time')}
                        </span>
                        <span className='collaboration-request__info-value streamer-info__info-value'>
                          {collab.sender.stream_hours_min
                          && collab.sender.stream_hours_max
                            ? `${moment
                              .utc()
                              .hour(collab.sender.stream_hours_min)
                              .tz(SettingsService.getUserTimezone())
                              .locale('en')
                              .format('hh:00 A')} - ${moment
                              .utc()
                              .hour(collab.sender.stream_hours_max)
                              .tz(SettingsService.getUserTimezone())
                              .locale('en')
                              .format('hh:00 A')}`
                            : t('streamersComponent.streamerInfo.noData')}
                        </span>
                      </li>
                      <li className='collaboration-request__info-item streamer-info__info-item'>
                        <span className='collaboration-request__info-title streamer-info__info-title'>
                          {t('collaborationRequestPage.streamerInfo.mature')}
                        </span>
                        <span className='collaboration-request__info-value streamer-info__info-value'>
                          {getIsMatureString(collab.sender)}
                        </span>
                      </li>
                      <li className='collaboration-request__info-item streamer-info__info-item'>
                        <span className='collaboration-request__info-title streamer-info__info-title'>
                          {t('collaborationRequestPage.streamerInfo.language')}
                        </span>
                        <span className='collaboration-request__info-value streamer-info__info-value'>
                          {collab.sender.languages
                            ? setStreamerLanguage(collab.sender.languages)
                            : t('streamersComponent.streamerInfo.noData')}
                        </span>
                      </li>
                      <li className='collaboration-request__info-item streamer-info__info-item'>
                        <span className='collaboration-request__info-title streamer-info__info-title'>
                          {t('streamersComponent.streamerInfo.location')}
                        </span>
                        <span className='collaboration-request__info-value streamer-info__info-value'>
                          {collab.sender.timezone}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='collaboration-request__about-gyre collaboration-request__about-gyre--mobile'>
                  <h3 className='collaboration-request__subtitle collaboration-request__section-title'>
                    {t('collaborationRequestPage.about.title')}
                  </h3>
                  <p className='collaboration-request__description'>
                    {t('collaborationRequestPage.about.text')}
                  </p>
                </div>
                <div className='collaboration-request__comments'>
                  <h3 className='collaboration-request__subtitle collaboration-request__section-title'>
                    {t('collaborationRequestPage.streamerInfo.comments')}
                  </h3>
                  <span className='collaboration-request__streamer-name'>Courtney Henry:</span>
                  <p className='collaboration-request__description'>{collab.data.greeting}</p>
                  <p className='collaboration-request__description'>{collab.data.why}</p>
                </div>
                <div className='collaboration-request__propose-wrapper'>
                  <h3 className='collaboration-request__propose-title'>
                    {t('collaborationRequestPage.streamerInfo.proposal')}
                  </h3>
                  <p className='collaboration-request__description'>{collab.data.propose}</p>
                </div>
                <div className='collaboration-request__last-stream-container'>
                  {collab.data.youtube_id ? (
                    <a
                      className='collaboration-request__preview-wrapper'
                      href={getStreamLink()}
                      target='_blank'
                      rel='noreferrer'>
                      <img
                        className='collaboration-request__stream-preview'
                        src={getStreamPreviewImage()}
                        alt=''
                      />
                    </a>
                  ) : null}
                  <div className='collaboration-request__last-stream-wrapper'>
                    <h3 className='collaboration-request__last-stream-title'>
                      {t('collaborationRequestPage.streamerInfo.lastStream.title')}
                    </h3>
                    <a
                      href={getStreamLink()}
                      className='collaboration-request__twitch-link twitch-user-link'
                      target='_blank'
                      rel='noreferrer'>
                      <TwitchIcon />
                      {t('collaborationRequestPage.streamerInfo.lastStream.button')}
                    </a>
                  </div>
                </div>
                <div className='collaboration-request__buttons-container'>
                  <button
                    className='collaboration-request__accept-button primary-button'
                    onClick={() => updateCollabStatus('ACCEPTED')}>
                    {t('collaborationRequestPage.buttons.accept')}
                  </button>
                  <button
                    className='collaboration-request__reject-button red-bordered-button'
                    onClick={() => updateCollabStatus('REJECTED')}>
                    {t('collaborationRequestPage.buttons.reject')}
                  </button>
                </div>
              </div>
              <aside className='collaboration-request__about-gyre collaboration-request__about-gyre--desktop'>
                <h3 className='collaboration-request__subtitle collaboration-request__section-title'>
                  {t('collaborationRequestPage.about.title')}
                </h3>
                <p className='collaboration-request__description'>
                  {t('collaborationRequestPage.about.text')}
                </p>
              </aside>
            </div>
          </section>
        </>
      ) : null}
      {/* {acceptModal ? <AcceptRequestModal closeModal={showAcceptModal} /> : null} */}
      {confirmModal && collabStatus === 'ACCEPTED' ? (
        <ConfirmCollabModal
          title={t('collaborationRequestPage.confirmModalTitle')}
          message={t('collaborationRequestPage.acceptedMessage')}
          onClick={showConfirmModal}
          goTo='/collab-requests'
        />
      ) : confirmModal && collabStatus === 'REJECTED' ? (
        <ConfirmCollabModal
          title={t('collaborationRequestPage.confirmModalTitle')}
          message={t('collaborationRequestPage.rejectedMessage')}
          onClick={showConfirmModal}
          goTo='/find-collab'
        />
      ) : confirmModal && collabStatus === 'error' ? (
        <ConfirmCollabModal
          title={t('suggestCollabComponent.form.ModalErrorTitle')}
          message={t('suggestCollabComponent.form.ModalErrorMessage')}
          onClick={showConfirmModal}
          showEmail={true}
          goTo={window.location.pathname}
        />
      ) : null}
      {signUpForm ? (
          <SignUpModal onClick={openSignUpForm} api={REACT_APP_GYRE_BACKEND} />
      ) : null}
    </>
  );
};

export default CollaborationRequestPage;
