import {useTranslation} from "react-i18next";
import {ReactComponent as CloseIcon} from "../assets/images/close-icon.svg";
import {Link} from "react-router-dom";
import Utils from "../utils/Utils";
import {useContext} from "react";
import AppContext from "../store/AppContext";

const InviteStreamerModal = ({streamer, onClick}) => {
    
    const {t} = useTranslation();
    const context = useContext(AppContext);
    
    return (
        <section className='confirm-collab-modal'>
            <div className='confirm-collab-modal__background' />
            <div className='confirm-collab-modal__modal-wrapper'>
                <div className='confirm-collab-modal__modal confirm-collab-modal__modal'>
                    <div className='confirm-collab-modal__header'>
                        <h2 className='confirm-collab-modal__title'>{t('inviteStreamerModal.invite')} {streamer.twitch_username}</h2>
                        <button className='confirm-collab-modal__close-button' onClick={() => {
                            onClick();
                            document.body.classList.remove('scroll-off');
                        }}>
                            <CloseIcon />
                        </button>
                    </div>
                    <p className='confirm-collab-modal__message'>
                        {t('inviteStreamerModal.message')}
                    </p>
                    <p className='confirm-collab-modal__message'>
                        {window.location.origin + '/register?ref=' + context.user.id}
                    </p>
                    <Link className='confirm-collab-modal__apply-button' onClick={() => {
                        Utils.copyToClipboard(window.location.origin + '/register?ref=' + context.user.id);
                        onClick();
                        document.body.classList.remove('scroll-off');
                    }}>
                        {t('inviteStreamerModal.copyLink')}
                    </Link>
                </div>
            </div>
        </section>
    );
    
};

export default InviteStreamerModal;