import CircleLoader from 'react-spinners/CircleLoader';

const GyreSpinner = ({loading, size, label, onClick}) => {
  return (
    <div className='spinner-wrapper' onClick={onClick}>
        <CircleLoader loading={loading} size={size} />
        {label && loading ? <span className='spinner-wrapper__label'>{label}</span> : null}
    </div>
  );
};

export default GyreSpinner;
