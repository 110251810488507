import Select, {NonceProvider, createFilter} from 'react-select';

const ConcatStyles = (parentStyles, childStyles) => {
  let outputStyles = parentStyles;

  for (var item in childStyles) {
    let style = outputStyles[item] ? outputStyles[item]() : {};
    let childStyle = childStyles[item]();

    let outputStyle = {...style, ...childStyle};

    outputStyles[item] =
      item === 'option'
        ? (styles, {isFocused, isSelected} = {}) => {
          outputStyle.backgroundColor = isSelected
            ? '#2a356e'
            : isFocused
              ? '#eaebf0'
              : '#ffffff';
          outputStyle.color = isSelected ? '#ffffff' : isFocused ? '#2a356e' : '#2a356e';
          return {...styles, ...outputStyle};
        }
        : styles => ({...styles, ...outputStyle});
  }

  return outputStyles;
};

const GeneralSelector = ({
  customDesktopWidth,
  marginBottom = '8px',
  menuListHeight = 'auto',
  styles,
  ...props
}) => {
  const generalStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: '#ffffff',
      border: 'none',
      boxShadow: 'none',
      borderRadius: '12px',
      marginBottom: marginBottom,
      transitionDuration: '0.3s',
      display: 'flex',
      alignItems: 'normal',

      ':hover': {
        cursor: 'pointer',
        boxShadow: '0px 4px 12px rgba(7, 7, 73, 0.08)'
      },
      '@media (min-width: 1280px)': {
        width: customDesktopWidth
      }
    }),
    valueContainer: styles => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
      paddingRight: '0',
      height: '48px'
    }),
    clearIndicator: styles => ({
      ...styles,
      display: 'none',
      PointerEvent: 'none'
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none'
    }),
    option: (styles, {isFocused, isSelected} = {}) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#2a356e' : isFocused ? '#eaebf0' : '#ffffff',
        color: isSelected ? '#ffffff' : isFocused ? '#2a356e' : '#2a356e',
        padding: '16px',
        fontSize: '14px',
        borderBottom: '1px solid #eaebf0',
        transitionDuration: '300ms',
        position: 'relative',
        paddingLeft: '45px',
        fontWeight: '500',

        ':hover': {
          cursor: 'pointer'
        },

        ':active': {
          opacity: 0.5
        }
      };
    },
    menu: styles => ({
      ...styles,
      backgroundColor: '#eaebf0',
      zIndex: 1001,
      borderRadius: '12px',
      overflow: 'hidden'
    }),
    menuList: styles => ({
      ...styles,
      maxHeight: menuListHeight,
      overflow: 'auto',
      overflowX: 'hidden'
    }),
    input: styles => ({
      ...styles,
      color: '#4a5384',
      fontSize: '14px',
      fontWeight: '500',
      '& input': {
        olor: '#4a5384',
        fontSize: '14px !important',
        paddingLeft: '3px !important',
        fontWeight: '500 !important'
      }
    }),
    placeholder: styles => ({...styles, color: '#4a5384', fontSize: '14px', fontWeight: '500'}),
    singleValue: styles => ({
      ...styles,
      position: 'relative',
      display: 'inline-block',
      color: '#4a5384',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      fontWeight: '500'
    }),
    noOptionsMessage: styles => ({
      ...styles,
      color: '#4a5384',
      padding: '16px',
      fontSize: '14px',
      backgroundColor: '#f2f2f1'
    })
  };

  const selectorStyles = ConcatStyles(generalStyles, styles);

  return <Select styles={selectorStyles} isSearchable={false} {...props} />;
};

export default GeneralSelector;
