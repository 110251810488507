import {Link, useParams} from 'react-router-dom';
import 'moment/locale/ru.js';
import {useTranslation} from 'react-i18next';
import {useContext, useEffect, useState} from 'react';
import SettingsService from '../../services/SettingService';
import AppContext from "../../store/AppContext";
import ChatService from "../../services/ChatService";

const moment = require('moment');

const ChatListCard = ({
  sentMessageText, 
  chat,
  onChatSelected,
  selected
}) => {
  const {i18n} = useTranslation();
  const [sentMessageTime, setSentMessageTime] = useState(chat.sent_at);
  const [isRead, setIsRead] = useState(chat.receiver_already_read);
  const [lastMessageText, setLastMessageText] = useState(chat.message);
  const [lastMessageSender, setLastMessageSender] = useState(chat.sender_id);
  const context = useContext(AppContext);
  const [messageToProcess, setMessageToProcess] = useState();
  
  const onChatClick = () => {
    onChatSelected(chat);
    setIsRead(true);
  };
  
  useEffect(() => {
    ChatService.onNewMessage(chat.streamer.id + "_ChatListCard", onNewMessage);
  }, []);
  
  const onNewMessage = (newMessage) => {
    if ( (newMessage.sender_id === context.user.id && newMessage.receiver_id === chat.streamer.id)
        || (newMessage.sender_id === chat.streamer.id && newMessage.receiver_id === context.user.id)) {
      setMessageToProcess(newMessage);
    }
  };
  
  useEffect(() => {
    if (selected && !isRead) {
      // first load, if not read - mark as read after 2 seconds
      setTimeout(() => {
        setIsRead(true);
      }, 2000);
    }
  }, [selected]);
  
  useEffect(() => {
    // can only process here, as state will be up to date
    if (messageToProcess) {
      const newMessage = messageToProcess;
      if (selected) {
        setIsRead(true);
      } else {
        setIsRead(false);
        ChatService.unreadChatMessages.set(true);
      }

      setLastMessageSender(newMessage.sender_id);
      setLastMessageText(newMessage.content);
      setSentMessageTime(newMessage.sent_at);
    }

    setMessageToProcess(null);
  }, [messageToProcess]);

  useEffect(() => {
    if (sentMessageText.receiver === chat.streamer.id) {
      setLastMessageText(sentMessageText.message);
      setSentMessageTime(moment.utc());
      setIsRead(true);
    }
  }, [sentMessageText]);

  return (
    <li
      className={`chat-list__card ${selected ? 'chat-list__card--current' : ''}`}
      onClick={onChatClick}>
      <Link to={`/chat/${chat.streamer.id}`} className='chat-list__link-to-chat'>
        <div className='chat-list__avatar-and-status'>
          <div className='chat-list__avatar-wrapper'>
            <img className='chat-list__avatar' src={chat.streamer.picture} alt='' />
          </div>
          <div className='chat-list__streamer-status-wrapper'>
            <span className='chat-list__streamer-status' />
          </div>
        </div>
        <div className='chat-list__info-wrapper'>
          <h3
            className={`chat-list__streamer-nickname ${
              !isRead && context.user.streamer_id !== lastMessageSender
                ? 'chat-list__streamer-nickname--unread'
                : ''
            }`}>
            {chat.streamer.twitch_username}
          </h3>
          <div className='chat-list__last-message-wrapper'>
            <p className='chat-list__last-message'>{lastMessageText}</p>
            <span className='chat-list__send-time'>
              {moment
                .utc(sentMessageTime)
                .tz(SettingsService.getUserTimezone())
                .locale(i18n.language)
                .format('DD MMM LT')}
            </span>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default ChatListCard;