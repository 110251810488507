import {Fragment} from 'react';
import {components} from 'react-select';

const DimmedControl = ({...props}) => {
  return (
    <Fragment>
      {props.menuIsOpen && props.selectProps.showOverlay !== false ? <div className='selector-overlay' /> : null}
      <components.Control className={props.menuIsOpen ? 'selector-is-opened' : ''} {...props} />
    </Fragment>
  );
};

export default DimmedControl;
