import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../assets/images/close-icon.svg';
import {Link} from 'react-router-dom';

const ConfirmCollabModal = ({
  title,
  message,
  onClick,
  goTo,
  showEmail = false
}) => {
  const {t} = useTranslation();
  return (
    <section className='confirm-collab-modal'>
      <div className='confirm-collab-modal__background' />
      <div className='confirm-collab-modal__modal-wrapper'>
        <div className='confirm-collab-modal__modal confirm-collab-modal__modal'>
          <div className='confirm-collab-modal__header'>
            <h2 className='confirm-collab-modal__title'>{title}</h2>
            <button className='confirm-collab-modal__close-button' onClick={() => {
              onClick();
              document.body.classList.remove('scroll-off');
            }}>
              <CloseIcon />
            </button>
          </div>
          <p className='confirm-collab-modal__message'>
            {message}
            {showEmail === true ? (
              <a className='confirm-collab-modal__email' href='mailto:support@gyre.tv'>
                support@gyre.tv
              </a>
            ) : null}
          </p>
          <Link className='confirm-collab-modal__apply-button' to={goTo} onClick={() => {
            onClick();
            document.body.classList.remove('scroll-off');
          }}>
            OK
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ConfirmCollabModal;
