import {useState, useEffect} from 'react';
import Collapsible from 'react-collapsible';
import DimmedSelector from '../selectors/DimmedSelector.js';
import {useTranslation} from 'react-i18next';
import {useWindowSize} from "../../utils/Utils";
import {EventStatuses} from "../../services/EventsService";

const EventRequestsSorting = ({
                                checkedFilters,
                                setCheckedFilters,
                                sortingMethod,
                                setSortingMethod,
                                loading,
                                requestsCategory
                              }) => {
  const [width] = useWindowSize();
  const {t} = useTranslation();
  const [eventCounter, setEventCounter] = useState('');
  const filterIndexNotFound = -1

  useEffect(() => {
    if (!loading) {
      setEventCounter(document.getElementById('event-list').childElementCount);
    }
  }, [loading, checkedFilters, sortingMethod, requestsCategory]);

  const sortingOptions = [
    {
      value: 'newest',
      label: t('myEventRequestsPage.newest')
    },
    {
      value: 'oldest',
      label: t('myEventRequestsPage.oldest')
    }
  ];

  const handleChange = selectedOption => {
    setSortingMethod(selectedOption.value);
  };

  const getSelectedOption = () => {
    return sortingOptions.find(i => i.value === sortingMethod) || sortingOptions[0];
  };

  const statusOptions = [
    {
      id: 1,
      value: EventStatuses.pending,
      label: t('statuses.filters.pending')
    },
    {
      id: 2,
      value: EventStatuses.completed,
      label: t('statuses.filters.completed')
    },
    {
      id: 3,
      value: EventStatuses.cancelled,
      label: t('statuses.filters.cancelled')
    }
  ];

  const handleToggle = value => {
    const currentIndex = checkedFilters.indexOf(value);
    const newChecked = [...checkedFilters];

    if (currentIndex === filterIndexNotFound) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedFilters(newChecked);
  };

  return (
    <aside className='my-collab-requests__filters-wrapper'>
      <Collapsible
        trigger='Filters'
        className='my-collab-requests__accordion'
        open={width >= 1280}>
        <span className='my-collab-requests__filter-label'>
          {t('myEventRequestsPage.filterSortByLabel')}
        </span>
        <DimmedSelector
          defaultValue={getSelectedOption()}
          options={sortingOptions}
          onChange={handleChange}
          marginBottom={'24px'}
        />
        <span className='my-collab-requests__filter-label'>
          {t('myEventRequestsPage.filterStatusLabel')}
        </span>
        <ul className='filters__checkbox-list my-collab-requests__checkbox-list'>
          {statusOptions.map((checkbox, i) => (
            <li className='filters__checkbox-item' key={i}>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name={checkbox.value}
                id={`${checkbox.value}-input`}
                value={checkbox.value}
                onChange={() => handleToggle(checkbox.value)}
                checked={!!checkedFilters.includes(checkbox.value)}
              />
              <label className='filters__checkbox-label' htmlFor={`${checkbox.value}-input`}>
                {checkbox.label}
              </label>
            </li>
          ))}
        </ul>
        <span className='my-collab-requests__list-counter'>
          {`${eventCounter} ${t('myEventRequestsPage.inTheList')}`}
        </span>
      </Collapsible>
    </aside>
  );
};

export default EventRequestsSorting;
