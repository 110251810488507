import {useTranslation} from 'react-i18next';

const Pagination = ({
  paginationOffsetIndex,
  setPaginationOffsetIndex,
  count,
  itemsPerPageIndex,
  setScrollToTop
}) => {
  const {t} = useTranslation();

  return (
    <section className='pagination'>
      <button className='pagination__button' onClick={() => {
          setPaginationOffsetIndex(paginationOffsetIndex - itemsPerPageIndex);
          setScrollToTop(true);
        }} 
              disabled={paginationOffsetIndex === 0}>&#8592;  {t('previousPage')}
      </button>
      <button className='pagination__button' onClick={() => {
          setPaginationOffsetIndex(paginationOffsetIndex + itemsPerPageIndex);
          setScrollToTop(true);
      }} 
              disabled={count < 5}>
        {t('nextPage')} &#8594;</button>
    </section>
  );
};

export default Pagination;