import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ReactComponent as FindCollabIcon} from '../../assets/images/find-collab-icon.svg';
import {ReactComponent as DashboardIcon} from '../../assets/images/dashboard-icon.svg';
import {ReactComponent as IncomingRequestsIcon} from '../../assets/images/incoming-requests-icon.svg';
import {ReactComponent as MyRequestsIcon} from '../../assets/images/my-requests-icon.svg';
import {useEffect, useState} from "react";
import CollabService from "../../services/CollabService";

const NavigationBar = () => {
  const {t} = useTranslation();
  const [showUnreadCollabIndicator, setShowUnreadCollabIndicator] = useState(CollabService.hasPendingCollabRequests.get());
  
  useEffect(() => {
    CollabService.hasPendingCollabRequests.onValueChanged().subscribe(setShowUnreadCollabIndicator);
  });
  
  return (
    <>
      <ul className='navigation-bar'>
        <li className='navigation-bar__item'>
          <NavLink
            exact
            to='/find-collab'
            className='navigation-bar__link'
            activeClassName='navigation-bar__link--current'>
            <FindCollabIcon />
            <span>{t('navigation.findCollab')}</span>
          </NavLink>
        </li>
        <li className='navigation-bar__item'>
          <NavLink
            exact
            to='/gyre-events'
            className='navigation-bar__link'
            activeClassName='navigation-bar__link--current'>
            <IncomingRequestsIcon />
            <span>{t('navigation.findEvents')}</span>
          </NavLink>
        </li>
        {/*<li className="navigation-bar__item">*/}
        {/*  <NavLink*/}
        {/*    exact*/}
        {/*    to="/dashboard"*/}
        {/*    className="navigation-bar__link"*/}
        {/*    activeClassName="navigation-bar__link--current">*/}
        {/*    <DashboardIcon />*/}
        {/*    <span>{t('navigation.dashboard')}</span>*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        <li className='navigation-bar__item'>
          <NavLink
            exact
            to='/collab-requests'
            activeClassName='navigation-bar__link--current'
            className='navigation-bar__link'>
            <MyRequestsIcon />
            <span>{t('navigation.collabRequests')}</span>
            {showUnreadCollabIndicator && 
              <div className='main__status-wrapper'>
                <span className='main__status main__status--green' />
              </div>
            }
          </NavLink>
        </li>
        <li className='navigation-bar__item'>
          <NavLink
            exact
            to='/my-events'
            activeClassName='navigation-bar__link--current'
            className='navigation-bar__link'>
            <MyRequestsIcon />
            <span>{t('navigation.eventRequests')}</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default NavigationBar;
