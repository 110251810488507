import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';

const UnauthorizedPage = () => {
  const {t} = useTranslation();

  useEffect(() => {
    setTimeout( () => {
        window.location.replace('/find-collab');
    }, 5000);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('unauthorizedPage.helmetTitle')}</title>
      </Helmet>
      <section className='unauthorized-page'>
        <div className='unauthorized-page__container'>
          <h2 className='privacy-policy__main-title'>{t('unauthorizedPage.mainTitle')}</h2>
          <p className='privacy-policy__paragraph'>{t('unauthorizedPage.text')}</p>
        </div>
      </section>
    </>
  );
};

export default UnauthorizedPage;
