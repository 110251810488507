import {useEffect, useState} from 'react';
import DimmedSelector from '../selectors/DimmedSelector';
import {useTranslation} from 'react-i18next';
import SettingsService from '../../services/SettingService';
import {createFilter} from 'react-select';
import {PartnershipStatus} from "../../utils/Collections";
import {Languages} from "../../utils/Languages";
import GamesService from "../../services/GamesService";

const Filters = ({
  applyFilters,
  streamDays,
  setStreamDays,
  hoursMin,
  hoursMax,
  setHoursMin,
  setHoursMax,
  language,
  setLanguage,
  game,
  setGame,
  partners,
  setPartners,
  followers,
  setFollowers,
  avgViewers,
  setAvgViewers,
  mature,
  setMature,
  activeStreamers,
  setActiveStreamers,
  gyreOnly,
  setGyreOnly,
  resetFilters,
  setPaginationOffsetIndex,
  setScrollToTop,
  hasEmailOnly,
  setHasEmailOnly
}) => {
  const {t} = useTranslation();
  const days = [
    {
      value: 0,
      label: t('filtersComponent.daysOfTheWeek.monday')
    },
    {
      value: 1,
      label: t('filtersComponent.daysOfTheWeek.tuesday')
    },
    {
      value: 2,
      label: t('filtersComponent.daysOfTheWeek.wednesday')
    },
    {
      value: 3,
      label: t('filtersComponent.daysOfTheWeek.thursday')
    },
    {
      value: 4,
      label: t('filtersComponent.daysOfTheWeek.friday')
    },
    {
      value: 5,
      label: t('filtersComponent.daysOfTheWeek.saturday')
    },
    {
      value: 6,
      label: t('filtersComponent.daysOfTheWeek.sunday')
    }
  ];

  const hours = [
    {
      value: 0,
      label: '00:00'
    },
    {
      value: 1,
      label: '01:00'
    },
    {
      value: 2,
      label: '02:00'
    },
    {
      value: 3,
      label: '03:00'
    },
    {
      value: 4,
      label: '04:00'
    },
    {
      value: 5,
      label: '05:00'
    },
    {
      value: 6,
      label: '06:00'
    },
    {
      value: 7,
      label: '07:00'
    },
    {
      value: 8,
      label: '08:00'
    },
    {
      value: 9,
      label: '09:00'
    },
    {
      value: 10,
      label: '10:00'
    },
    {
      value: 11,
      label: '11:00'
    },
    {
      value: 12,
      label: '12:00'
    },
    {
      value: 13,
      label: '13:00'
    },
    {
      value: 14,
      label: '14:00'
    },
    {
      value: 15,
      label: '15:00'
    },
    {
      value: 16,
      label: '16:00'
    },
    {
      value: 17,
      label: '17:00'
    },
    {
      value: 18,
      label: '18:00'
    },
    {
      value: 19,
      label: '19:00'
    },
    {
      value: 20,
      label: '20:00'
    },
    {
      value: 21,
      label: '21:00'
    },
    {
      value: 22,
      label: '22:00'
    },
    {
      value: 23,
      label: '23:00'
    }
  ];

  const partnerships = [
    {
      value: PartnershipStatus.Affiliate,
      label: t('filtersComponent.partnership.affiliate')
    },
    {
      value: PartnershipStatus.Partner,
      label: t('filtersComponent.partnership.partner')
    },
    {
      value: null, // using null instead of empty string so listbox doesn't put this instead of placeholder
      label: t('any')
    }
  ];

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'start',
    trim: true
  };

  useEffect(() => {
    document.querySelectorAll('.filters__time-input').forEach(item => {
      item.addEventListener('focusout', event => {
        const target = event.target;
        if (target.value) {
          target.classList.add('filled');
        } else {
          target.classList.remove('filled');
        }
      });
    });
  });

  const inputHandleChange = (selectedOption, setValue) => {
    const value = selectedOption === null ? '' : selectedOption.value;
    setValue(value);
    saveFilters();
  };

  const checkboxFilterHandler = (e, state, setState) => {
    let checkedValues = [];
    const checked = e.target.checked;
    const value = e.target.value;

    if (checked === true) {
      if (value.includes('-')) {
        const newValue = value.split('-').map(item => item);
        setState([...state, ...newValue]);
      } else {
        checkedValues.push(value);
        setState([...state, value]);
      }
    } else {
      if (value.includes('-')) {
        const newValue = value.split('-');
        state.forEach(item => {
          if (item !== newValue[0] && item !== newValue[1]) {
            checkedValues.push(item);
          }
        });

        setState(checkedValues);
      } else {
        state.forEach(item => {
          if (item !== value) {
            checkedValues.push(item);
          }
        });
        setState(checkedValues);
      }
    }

    saveFilters();
  };

  const getMultiSelectValues = arr => {
    let items = [];
    arr.forEach((item, i) => {
      items.push(
        <li className='multi-select__item' key={i}>
          <input
            className='multi-select__input visually-hidden'
            type='checkbox'
            value={item.value}
            name={item.label.toLowerCase().replace(' ', '-')}
            id={`${item.label.toLowerCase().replace(' ', '-')}-input`}
            onChange={e => checkboxFilterHandler(e, streamDays, setStreamDays)}
          />
          <label
            className='multi-select__label'
            htmlFor={`${item.label.toLowerCase().replace(' ', '-')}-input`}>
            {item.label}
          </label>
        </li>
      );
    });
    return items;
  };

  const [gamesList, setGamesList] = useState([]);
  let gamesInputValue = '';

  useEffect(() => {
    const input = document.getElementById('games-input');
    input.addEventListener('input', e => {
      gamesInputValue = e.target.value;
      if (gamesInputValue !== '') {
        getGamesList();
      }
    });

    loadFilters();
  }, []);

  const loadFilters = () => {
    const filterJSON = window.localStorage.getItem('_gyre_filters');
    if (filterJSON) {
      const filters = JSON.parse(filterJSON);
      if (filters) {
        if (Number.isInteger(filters.streamDays)) {
          setStreamDays(filters.streamDays);
        }
        if (Number.isInteger(filters.hoursMin)) {
          setHoursMin(filters.hoursMin);
        }
        if (Number.isInteger(filters.hoursMax)) {
          setHoursMax(filters.hoursMax);
        }
        if (filters.language) {
          setLanguage(filters.language);
        }
        if (filters.game) {
          setGame(filters.game);
        }
        if (typeof filters.partners !== 'undefined') {
          setPartners(filters.partners);
        }
        if (filters.followers) {
          setFollowers(filters.followers);
        }
        if (filters.avgViewers) {
          setAvgViewers(filters.avgViewers);
        }
        if (typeof filters.mature !== 'undefined') {
          setMature(filters.mature);
        }
        if (typeof filters.activeStreamers !== 'undefined') {
          setActiveStreamers(filters.activeStreamers);
        }
        if (typeof filters.gyreOnly !== 'undefined') {
          setGyreOnly(filters.gyreOnly);
        }
      }
    }

    if (!language) {
      setLanguage(SettingsService.getUserLanguage());
    }
  };

  const saveFilters = () => {
    const filters = {
      streamDays: streamDays,
      hoursMin: hoursMin,
      hoursMax: hoursMax,
      language: language,
      game: game,
      partners: partners,
      followers: followers,
      avgViewers: avgViewers,
      mature: mature,
      activeStreamers: activeStreamers,
      gyreOnly: gyreOnly
    };

    window.localStorage.setItem('_gyre_filters', JSON.stringify(filters));
  };

  const clearFilters = () => {
    resetFilters();
    window.localStorage.removeItem('_gyre_filters');
    setScrollToTop(true);
    document.getElementById('find-by-name-input').value = '';
    document.getElementById('find-by-name-input').classList.remove('filled');
  };

  const getGamesList = async () => {
    const res = await GamesService.getGamesByName(gamesInputValue);
    const newGamesArray = res.data.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });
    setGamesList(newGamesArray);
  };

  const applyFiltersHandler = () => {
    applyFilters();
    setScrollToTop(true);
    setPaginationOffsetIndex(0);
    document.getElementById('find-by-name-input').value = '';
    document.getElementById('find-by-name-input').classList.remove('filled');
  }

  return (
    <section className='filters'>
      <form id='filters-form'>
        <div className='filters__stream-time'>
          <h3 className='filters__header'>{t('filtersComponent.streamTime')}</h3>
          <ul className='filters__days-of-week multi-select__list'>{getMultiSelectValues(days)}</ul>
          <div className='filters__time-wrapper'>
            <DimmedSelector
              options={hours}
              value={hours.filter(option => {
                return option.value == hoursMin;
              })}
              classNamePrefix={'time-input'}
              placeholder={t('filtersComponent.timeInterval.from')}
              menuListHeight={'489px'}
              onChange={selectedOption => inputHandleChange(selectedOption, setHoursMin)}
            />
            <span className='filters__time-separator' />
            <DimmedSelector
              classNamePrefix={'time-input'}
              options={hours}
              value={hours.filter(option => {
                return option.value == hoursMax;
              })}
              placeholder={t('filtersComponent.timeInterval.to')}
              menuListHeight={'489px'}
              onChange={selectedOption => inputHandleChange(selectedOption, setHoursMax)}
            />
          </div>
        </div>
        <DimmedSelector
          options={Languages.list}
          value={Languages.list.filter(option => {
            return option.value === language;
          })}
          isSearchable={true}
          isClearable={true}
          backspaceRemovesValue={true}
          placeholder={t('filtersComponent.languagePlaceholder')}
          menuListHeight={'489px'}
          filterOption={createFilter(filterConfig)}
          onChange={selectedOption => inputHandleChange(selectedOption, setLanguage)}
        />
        <DimmedSelector
          options={partnerships}
          value={partnerships.filter(option => {
            return option.value === partners;
          })}
          placeholder={t('filtersComponent.partnershipPlaceholder')}
          onChange={selectedOption => inputHandleChange(selectedOption, setPartners)}
        />
        <DimmedSelector
          placeholder={t('filtersComponent.gamePlaceholder')}
          options={gamesList}
          value={gamesList.filter(option => {
            return option.value === game;
          })}
          indicatorContainers={{isDisabled: true}}
          isSearchable={true}
          isClearable={true}
          filterOption={createFilter(filterConfig)}
          backspaceRemovesValue={true}
          menuListHeight={'489px'}
          noOptionsMessage={() => t('filtersComponent.gameFilterDescription')}
          inputId={'games-input'}
          onChange={selectedOption => inputHandleChange(selectedOption, setGame)}
        />
        <div className='filters__followers'>
          <h3 className='filters__header'>{t('filtersComponent.followers')}</h3>
          <ul className='filters__checkbox-list'>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='followers-0-999'
                id='followers-0-999-input'
                value='0-999'
                checked={followers.indexOf('999') >= 0}
                onChange={e => checkboxFilterHandler(e, followers, setFollowers)}
              />
              <label className='filters__checkbox-label' htmlFor='followers-0-999-input'>
                <p>0 - 1K</p>
              </label>
            </li>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='followers-1000-4999'
                id='followers-1000-4999-input'
                value='1000-4999'
                checked={followers.indexOf('4999') >= 0}
                onChange={e => checkboxFilterHandler(e, followers, setFollowers)}
              />
              <label className='filters__checkbox-label' htmlFor='followers-1000-4999-input'>
                <p>1K - 5K</p>
              </label>
            </li>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='followers-5000-9999'
                id='followers-5000-9999-input'
                value='5000-9999'
                checked={followers.indexOf('9999') >= 0}
                onChange={e => checkboxFilterHandler(e, followers, setFollowers)}
              />
              <label className='filters__checkbox-label' htmlFor='followers-5000-9999-input'>
                <p>5K - 10K</p>
              </label>
            </li>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='followers-more-10000'
                id='followers-more-10000-input'
                value='10000'
                checked={followers.indexOf('10000') >= 0}
                onChange={e => checkboxFilterHandler(e, followers, setFollowers, 'number')}
              />
              <label className='filters__checkbox-label' htmlFor='followers-more-10000-input'>
                <p>&gt; 10K</p>
              </label>
            </li>
          </ul>
        </div>
        <div className='filters__average-views'>
          <h3 className='filters__header'>{t('filtersComponent.avgViewers')}</h3>
          <ul className='filters__checkbox-list'>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='0-49'
                id='0-49-input'
                value='0-49'
                checked={avgViewers.indexOf('49') >= 0}
                onChange={e => checkboxFilterHandler(e, avgViewers, setAvgViewers)}
              />
              <label className='filters__checkbox-label' htmlFor='0-49-input'>
                <p>0 - 50</p>
              </label>
            </li>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='50-249'
                id='50-249-input'
                value='50-249'
                checked={avgViewers.indexOf('249') >= 0}
                onChange={e => checkboxFilterHandler(e, avgViewers, setAvgViewers)}
              />
              <label className='filters__checkbox-label' htmlFor='50-249-input'>
                <p>50 - 250</p>
              </label>
            </li>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='250-499'
                id='250-499-input'
                value='250-499'
                checked={avgViewers.indexOf('499') >= 0}
                onChange={e => checkboxFilterHandler(e, avgViewers, setAvgViewers)}
              />
              <label className='filters__checkbox-label' htmlFor='250-499-input'>
                <p>250 - 500</p>
              </label>
            </li>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='500-999'
                id='500-999-input'
                value='500-999'
                checked={avgViewers.indexOf('999') >= 0}
                onChange={e => checkboxFilterHandler(e, avgViewers, setAvgViewers)}
              />
              <label className='filters__checkbox-label' htmlFor='500-999-input'>
                <p>500 - 1K</p>
              </label>
            </li>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='more-1000'
                id='more-1000-input'
                value='1000'
                checked={avgViewers.indexOf('1000') >= 0}
                onChange={e => checkboxFilterHandler(e, avgViewers, setAvgViewers)}
              />
              <label className='filters__checkbox-label' htmlFor='more-1000-input'>
                <p>1K+</p>
              </label>
            </li>
          </ul>
        </div>
        <div className='filters__mature'>
          <h3 className='filters__header'>{t('filtersComponent.mature.title')}</h3>
          <ul className='filters__checkbox-list'>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='non-mature'
                value='false'
                id='non-mature-input'
                checked={mature.indexOf('false') >= 0}
                onChange={e => checkboxFilterHandler(e, mature, setMature)}
              />
              <label className='filters__checkbox-label' htmlFor='non-mature-input'>
                <p>{t('filtersComponent.mature.no')}</p>
              </label>
            </li>
            <li className='filters__checkbox-item'>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name='mature'
                id='mature-input'
                value='true'
                checked={mature.indexOf('true') >= 0}
                onChange={e => checkboxFilterHandler(e, mature, setMature)}
              />
              <label className='filters__checkbox-label' htmlFor='mature-input'>
                <p>{t('filtersComponent.mature.yes')}</p>
              </label>
            </li>
          </ul>
        </div>
        <div className='filters__active-streamers'>
          <input
            className='filters__checkbox visually-hidden'
            type='checkbox'
            name='active-streamers'
            id='active-streamers-input'
            checked={activeStreamers}
            onChange={() => setActiveStreamers(!activeStreamers)}
          />
          <label className='filters__checkbox-label' htmlFor='active-streamers-input' title={t('filtersComponent.activeStreamersTooltip')}>
            <p>{t('filtersComponent.activeStreamers')}</p>
          </label>
        </div>
        <div className='filters__checkbox-item'>
          <input
              className='filters__checkbox visually-hidden'
              type='checkbox'
              name='active-streamers'
              id='has-email-only-input'
              checked={hasEmailOnly}
              onChange={() => setHasEmailOnly(!hasEmailOnly)}
          />
          <label className='filters__checkbox-label' htmlFor='has-email-only-input'>
            <p>{t('filtersComponent.hasEmailOnly')}</p>
          </label>
        </div>
        <div className='filters__checkbox-item'>
          <input
            className='filters__checkbox visually-hidden'
            type='checkbox'
            name='gyre-only'
            id='gyre-only-input'
            checked={gyreOnly}
            onChange={() => setGyreOnly(!gyreOnly)}
          />
          <label className='filters__checkbox-label' htmlFor='gyre-only-input'>
            <p>{t('filtersComponent.gyreOnly')}</p>
          </label>
        </div>
        <button
          type='button'
          className='filters__apply-button primary-button'
          onClick={applyFiltersHandler}>
          {t('filtersComponent.applyBtn')}
        </button>
        <button
          className='filters__reset-button red-bordered-button'
          type='reset'
          onClick={clearFilters}>
          {t('filtersComponent.resetBtn')}
        </button>
      </form>
    </section>
  );
};

export default Filters;
