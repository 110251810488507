import {useEffect} from 'react';
import {withRouter, useLocation} from 'react-router-dom';

function Analytics() {
  let location = useLocation();

  useEffect(() => {
    // Way to reset ScrollDepth trigger for GTM analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'gtm.load'
    });
  }, [location]);

  return null;
}

export default withRouter(Analytics);
