import CollaborateNowBtn from '../CollaborateNowBtn';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DiscordIcon} from '../../assets/images/discord-icon.svg';
import {ReactComponent as ArrowRightIcon} from '../../assets/images/arrow-right-icon.svg';

const Features = () => {
  const {t} = useTranslation();

  return (
    <section className='features' id='features'>
      <div className='features__wrapper'>
        <h2 className='features__title h2-title'>{t('landingPage.features.title')}</h2>
        <div className='features__list'>
          <div className='features__container'>
            <div className='features__item'>
              <div className='features__item-icon features__item-icon--1' />
              <h3 className='features__item-title'>{t('landingPage.features.li1.title')}</h3>
              <p className='features__item-description'>
                {t('landingPage.features.li1.description')}
              </p>
            </div>
            <div className='features__item'>
              <div className='features__item-icon features__item-icon--2' />
              <h3 className='features__item-title'>{t('landingPage.features.li2.title')}</h3>
              <p className='features__item-description'>
                {t('landingPage.features.li2.description')}
              </p>
            </div>
          </div>
          <div className='features__container'>
            <div className='features__item'>
              <div className='features__item-icon features__item-icon--3' />
              <h3 className='features__item-title'>{t('landingPage.features.li3.title')}</h3>
              <p className='features__item-description'>
                {t('landingPage.features.li3.description')}
              </p>
            </div>
            <div className='features__item'>
              <div className='features__item-icon features__item-icon--4' />
              <h3 className='features__item-title'>{t('landingPage.features.li4.title')}</h3>
              <p className='features__item-description'>
                {t('landingPage.features.li4.description')}
              </p>
            </div>
          </div>
        </div>
        <div className='features__item features__item--discord'>
          <div className='features__item-icon features__item-icon--5'>
            <DiscordIcon />
          </div>
          <h3 className='features__item-title'>{t('landingPage.features.li5.title')}</h3>
          <p className='features__item-description'>{t('landingPage.features.li5.description')}</p>
          <a
            className='features__discord-btn primary-button'
            href='https://discord.gg/ZeSeUt3wEj'
            target='_blank'
            rel='noreferrer'
            aria-label='Discord'>
            <ArrowRightIcon className='small-arrow' />
            {t('landingPage.features.discordBtnText')}
          </a>
        </div>
        <CollaborateNowBtn classNames='features__collaborate-btn' />
      </div>
    </section>
  );
};

export default Features;
