import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {useLocation} from 'react-router-dom';

const queryString = require('query-string');
const {REACT_APP_GYRE_BACKEND} = process.env;

const UnsubscribePage = () => {
  const {t} = useTranslation();
  const location = useLocation();

  const [unsubscribeStatus, setUnsubscribeStatus] = useState('');

  const cancel = () => {
    setUnsubscribeStatus(t('unsubscribePage.unsubscribeCancel'));
  };

  const unsubscribe = async params => {
    const querryString = queryString.parse(location.search);
    const token = querryString.unsubscribe_token;

    if (!token) {
      setUnsubscribeStatus(t('unsubscribePage.unsubscribeError') + ' token:' + token);
      return;
    }

    let formData = new FormData();
    formData.append('unsubscribe_token', token);

    await fetch(`${REACT_APP_GYRE_BACKEND}/api/settings/unsubscribe`, {
      body: formData,
      method: 'POST',
      credentials: 'include',
      mode: 'cors'
    }).then(res => {
        if (res.ok) {
          setUnsubscribeStatus(t('unsubscribePage.unsubscribeSuccesseful'));
        } else {
          setUnsubscribeStatus(t('unsubscribePage.unsubscribeError'));
        }
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('unsubscribePage.helmetTitle')}</title>
      </Helmet>
      <section className='unsubscribe'>
        <div className='unsubscribe__container'>
          <h2 className='unsubscribe__main-title'>{t('unsubscribePage.mainTitle')}</h2>
          <p className='unsubscribe__paragraph'>{t('unsubscribePage.intro')}</p>
          {
            !unsubscribeStatus
              ?
              <div>
                <button className='unsubscribe__warning-button'
                        onClick={() => unsubscribe()}>
                  {t('unsubscribePage.unsubscribeBtn')}
                </button>
                <span> </span>
                <button className='unsubscribe__warning-button'
                        onClick={() => cancel()}>
                  {t('unsubscribePage.cancelBtn')}
                </button>
              </div>
              :
              <div>
                        <span className='unsubscribe__status'>
                            {unsubscribeStatus}
                        </span>
              </div>
          }
        </div>
      </section>
    </>
  );
};

export default UnsubscribePage;