import {useContext, useState} from 'react';
import TwitchUserLink from '../TwitchUserLink';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg';
import {ReactComponent as ArrowBottomIcon} from '../../assets/images/arrow-bottom-icon.svg';
import {ReactComponent as TwitchIcon} from '../../assets/images/twitch-icon.svg';
import StreamerGames from '../find-collab/StreamerGames.js';
import ResponseButtons from './ResponseButtons.js';
import SettingsService from '../../services/SettingService.js';
import {PartnershipStatus} from "../../utils/Collections";
import {getAvgViewersBucket, getIsMatureString, setStreamerLanguage, valueToStringOrNoData} from "../../utils/Utils";
import AppContext from "../../store/AppContext";

const moment = require('moment');

const CollabRequestModal = ({
  onClick,
  gamesList,
  collabStatuses,
  selectedCollab,
  selectedCollabStatus,
  setSelectedCollabStatus,
  determineStatus,
  setNewStatus,
  showConfirmModal
}) => {
  const [infoVisible, setInfoVisible] = useState(false);
  const {t, i18n} = useTranslation();
  const context = useContext(AppContext);

  const getStreamLink = () => {
    if (selectedCollab.twitch_vod_id) {
      return `https://www.twitch.tv/videos/${selectedCollab.twitch_vod_id}`;
    } else if (selectedCollab.youtube_id) {
      return `https://www.youtube.com/watch?v=${selectedCollab.youtube_id}`;
    }
  };

  const getStreamPreviewImage = () => {
    if (selectedCollab.youtube_id) {
      return `https://img.youtube.com/vi/${selectedCollab.youtube_id}/0.jpg`;
    }
  };

  return (
    <section className='collab-request-modal'>
      <div className='collab-request-modal__background' />
      <div className='collab-request-modal__modal'>
        <div className='collab-request-modal__wrapper'>
          <div className='collab-request-modal__header'>
            <h2 className='collab-request-modal__title'>{t('collabRequestModal.title')}</h2>
            <button className='collab-request-modal__close-button' onClick={onClick}>
              <CloseIcon />
            </button>
          </div>
          <div className='my-collab-requests__status-wrapper'>
            <span
              className={`my-collab-requests__status my-collab-requests__status--${selectedCollabStatus}`}>
              {determineStatus(selectedCollabStatus)}
            </span>
            <span className='my-collab-requests__request-date'>
              {`${t('myCollabRequestsPage.requested')} ${moment
                .utc(selectedCollab.created_at)
                .tz(SettingsService.getUserTimezone())
                .locale(i18n.language)
                .format('LLL')}`}
            </span>
          </div>
          <p className='collab-request-modal__description'>
            {selectedCollab.sender_id === context.user.id
              ? t('collabRequestModal.description')
              : t('collaborationRequestPage.streamerInfo.title')}
          </p>
          <div className='collab-request-modal__streamer-card'>
            <div className='my-collab-requests__streamer-info'>
              <div
                className={`my-collab-requests__avatar-wrapper streamer-info__avatar-wrapper ${
                  selectedCollab.streamer.is_registered
                    ? 'streamer-info__avatar-wrapper--verified'
                    : ''
                }`}>
                <img
                  className='my-collab-requests__streamer-avatar streamer-info__avatar'
                  src={selectedCollab.streamer.picture}
                  alt=''
                />
              </div>
              <div className='my-collab-requests__streamer-info-wrapper my-collab-requests__streamer-info-wrapper--modal'>
                <div className='my-collab-requests__nickname-wrapper streamer-info__nickname-wrapper'>
                  <span className='my-collab-requests__streamer-nickname streamer-info__nickname'>
                    {selectedCollab.streamer.twitch_username}
                  </span>
                  {selectedCollab.streamer.is_registered
                    ? (
                      <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                        src={require('../../assets/images/gyre-verified-icon.svg').default}
                        alt='gyre verified'
                      />
                    </span>
                    ) : null}
                  {selectedCollab.streamer.partner === PartnershipStatus.Partner
                    ? (
                      <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                        src={require('../../assets/images/twitch-partner-icon.svg').default}
                        alt='twitch partner'
                      />
                    </span>
                    ) : null}
                </div>
                <TwitchUserLink
                  classNames='collab-request-modal__twitch-link'
                  link={`https://www.twitch.tv/${selectedCollab.streamer.twitch_username}`}
                />
              </div>
            </div>
            <div
              className={`collab-request-modal__more-content ${
                infoVisible ? 'collab-request-modal__more-content--visible' : ''
              }`}>
              <span className='collab-request-modal__games-label'>
                {t('collabRequestModal.streamerInfo.games')}
              </span>
              <div className='streamer-info__about'>
                <div className='streamer-info__break' />
                <StreamerGames
                  classNames='collab-request-modal__games-list'
                  gameList={gamesList}
                  streamerGameIds={selectedCollab.streamer.game_ids}
                />
              </div>
              <div className='streamer-info__info'>
                <ul className='streamer-info__info-list collab-request-modal__info-list'>
                  <li className='streamer-info__info-item collab-request-modal__info-item'>
                    <span className='streamer-info__info-title'>
                      {t('collabRequestModal.streamerInfo.averageViews')}
                    </span>
                    <span className='streamer-info__info-value'>
                      {getAvgViewersBucket(selectedCollab.streamer.avg_viewers)}
                    </span>
                  </li>
                  <li className='streamer-info__info-item collab-request-modal__info-item'>
                    <span className='streamer-info__info-title'>
                      {t('collabRequestModal.streamerInfo.followers')}
                    </span>
                    <span className='streamer-info__info-value'>
                      {valueToStringOrNoData(selectedCollab.streamer.followers)}
                    </span>
                  </li>
                  <li className='streamer-info__info-item collab-request-modal__info-item'>
                    <span className='streamer-info__info-title'>
                      {t('collabRequestModal.streamerInfo.time')}
                    </span>
                    <span className='streamer-info__info-value'>
                      {selectedCollab.streamer.stream_hours_min &&
                      selectedCollab.streamer.stream_hours_max
                        ? `${moment
                          .utc()
                          .hour(selectedCollab.streamer.stream_hours_min)
                          .tz(SettingsService.getUserTimezone())
                          .locale('en')
                          .format('hh:00 A')} - 
                         ${moment
                          .utc()
                          .hour(selectedCollab.streamer.stream_hours_max)
                          .tz(SettingsService.getUserTimezone())
                          .locale('en')
                          .format('hh:00 A')}`
                        : t('streamersComponent.streamerInfo.noData')}
                    </span>
                  </li>
                  <li className='streamer-info__info-item collab-request-modal__info-item'>
                    <span className='streamer-info__info-title'>
                      {t('collabRequestModal.streamerInfo.mature')}
                    </span>
                    <span className='streamer-info__info-value'>
                      {getIsMatureString(selectedCollab.streamer)}
                    </span>
                  </li>
                  <li className='streamer-info__info-item collab-request-modal__info-item'>
                    <span className='streamer-info__info-title'>
                      {t('collabRequestModal.streamerInfo.language')}
                    </span>
                    <span className='streamer-info__info-value'>
                      {setStreamerLanguage(selectedCollab.streamer.languages)}
                    </span>
                  </li>
                  <li className='streamer-info__info-item collab-request-modal__info-item'>
                    <span className='streamer-info__info-title'>
                      {t('streamersComponent.streamerInfo.location')}
                    </span>
                    <span className='streamer-info__info-value'>
                      {selectedCollab.streamer.timezone}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <button
              onClick={() => setInfoVisible(!infoVisible)}
              className={`collab-request-modal__show-more-button ${
                infoVisible ? 'collab-request-modal__show-more-button--opened' : ''
              }`}>
              <ArrowBottomIcon />
              {!infoVisible ? t('collabRequestModal.showMore') : t('collabRequestModal.showLess')}
            </button>
            {/*<div className='collab-request-modal__reply-wrapper'>*/}
            {/*  <span className='collab-request-modal__reply-label'>*/}
            {/*    {t('collabRequestModal.reply')}*/}
            {/*  </span>*/}
            {/*  <p className='collab-request-modal__streamer-reply'>*/}
            {/*    hey, thanks for the offer but for now I'm not really interested. Have good luck with*/}
            {/*    searching for your partner and thank you one more time, I’m sure you*/}
            {/*  </p>*/}
            {/*</div>*/}
          </div>
          <div className='collab-request-modal__comment-wrapper'>
            <h3 className='collab-request-modal__comments-title'>
              {t('collabRequestModal.commentTitle')}
            </h3>
            <span className='collab-request-modal__commentator-name'>
              {selectedCollab.sender_id !== context.user.id
                ? selectedCollab.streamer.twitch_username
                : 'Your greeting:'}
            </span>
            <p className='collab-request-modal__comment'>{selectedCollab.greeting}</p>
            <p className='collab-request-modal__comment'>{selectedCollab.why}</p>
          </div>
          <div className='collab-request-modal__propose-wrapper'>
            <h3 className='collab-request-modal__propose-title'>
              {t('collabRequestModal.proposeTitle')}
            </h3>
            <p className='collab-request-modal__comment collab-request-modal__comment--propose'>
              {selectedCollab.propose}
            </p>
          </div>
          {selectedCollab.collab_time ? (
            <div className='collab-request-modal__propose-wrapper'>
              <h3 className='collab-request-modal__propose-title'>
                {t('collabRequestModal.proposeTime')}
              </h3>
              <p className='collab-request-modal__comment collab-request-modal__comment--propose'>
                {selectedCollab.collab_time}
              </p>
            </div>
          ) : null }
          <div className='collab-request-modal__last-stream'>
            {selectedCollab.youtube_id
              ? (
                <a
                  className='collaboration-request__preview-wrapper'
                  href={getStreamLink()}
                  target='_blank'
                  rel='noreferrer'>
                  <img
                    className='collaboration-request__stream-preview'
                    src={getStreamPreviewImage()}
                    alt=''
                  />
                </a>
              ) : null}
            <div className='collab-request-modal__stream-preview-button-wrapper'>
              <h3 className='collab-request-modal__stream-preview-title'>
                {t('collabRequestModal.lastStreamTitle')}
              </h3>
              <a
                href={getStreamLink()}
                className='collab-request-modal__check-stream-link'
                target='_blank'
                rel='noreferrer'>
                <TwitchIcon />
                {t('collaborationRequestPage.streamerInfo.lastStream.button')}
              </a>
            </div>
          </div>
          {(selectedCollabStatus === collabStatuses.pending && selectedCollab.sender_id !== context.user.id) ||
          selectedCollabStatus === collabStatuses.accepted
            ? (
              <div className='collab-request-modal__response-buttons-wrapper'>
                <ResponseButtons
                  collabStatus={selectedCollabStatus}
                  collabStatuses={collabStatuses}
                  setCollabStatus={setSelectedCollabStatus}
                  collabId={selectedCollab.id}
                  setNewStatus={setNewStatus}
                  showConfirmModal={showConfirmModal}
                />
              </div>
            ) : null}
        </div>
        <div className='suggest-collab__indent' />
      </div>
    </section>
  );
};

export default CollabRequestModal;
