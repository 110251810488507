import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import Switch from '@mui/material/Switch';
import Footer from '../components/Footer';
import Select from '@mui/material/Select';
import {MenuItem} from '@mui/material';
import {useState, useEffect} from 'react';
import Timezones from '../utils/Timezones';
import SettingsService from '../services/SettingService';
import {Languages} from "../utils/Languages";
import Utils from "../utils/Utils";
import {ReactComponent as CopyIcon} from "../assets/images/copy-icon.svg";

const UserSettingsPage = ({
    openSignUpModal,
    loggedInStatus,
    user
}) => {
  const {t} = useTranslation();
  const [sendCollabSuggestionEmails, setSendCollabSuggestionEmails] = useState(true);
  const [sendNewMessageNotifications, setSendNewMessageNotifications] = useState(true);
  const [hideMyProfileFromSearchPage, setHideMyProfileFromSearchPage] = useState(false);
  const [timezone, setTimezone] = useState('');
  const [language, setLanguage] = useState('');

  const getTimezones = () => {
    return Timezones;
  };

  const getSettings = async () => {
    const res = await SettingsService.getSettings();
    updateViewFromResponce(res);
  };

  const saveSettings = async params => {
    const res = await SettingsService.saveSettings(
      params,
      (status) => {
        if (status == 401) {
          window.location.reload();
        }
      }
    );

    updateViewFromResponce(res);
  };

  const updateViewFromResponce = (res) => {
    setSendCollabSuggestionEmails(res.available_for_notification_collab_request);
    setSendNewMessageNotifications(res.available_for_notification_chat_message);
    setHideMyProfileFromSearchPage(!res.available_for_collab_request);
    setLanguage(res.lang || Languages.default);
    setTimezone(res.timezone || '');
  };

  const handleSendCollabSuggestionEmailsChange = e => {
    setSendCollabSuggestionEmails(e.target.checked);
    saveSettings({available_for_notification_collab_request: e.target.checked});
  };

  const handleSendNewMessageNotificationsChange = e => {
    setSendNewMessageNotifications(e.target.checked);
    saveSettings({available_for_notification_chat_message: e.target.checked});
  };

  const handleHideMyProfileFromSearchPageChange = e => {
    if (!e.target.checked || window.confirm(t('userSettingsPage.confirmHideMyProfileFromSearchPage'))) {
      setHideMyProfileFromSearchPage(!e.target.checked);
      saveSettings({available_for_collab_request: !e.target.checked});
    }
  };

  const handleTimezoneChange = e => {
    setTimezone(e.target.value);
    saveSettings({timezone: e.target.value});
  };

  const handleLanguageChange = e => {
    setLanguage(e.target.value);
    saveSettings({lang: e.target.value});
  };

  const onCopyLinkClick = () => {
    Utils.copyToClipboard(window.location.host + '/streamer/' + user.streamer_id);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('userSettingsPage.helmetTitle')}</title>
      </Helmet>
      <main>
        <section className='user-settings'>
          <div className='user-settings__container'>
            <h2 className='user-settings__main-title'>{t('userSettingsPage.mainTitle')}</h2>

            <div className='row justify-content-center'>
              <div className='col-12 col-lg-10 col-xl-8 mx-auto'>
                <div className='my-4'>
                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col-auto justify-content-center'>
                        <div className='page-header__avatar-wrapper'>
                          <img className='page-header__avatar' src={user.twitch_id_token.picture} alt='' />
                        </div>
                        <div>
                          {user.twitch_id_token.preferred_username}
                          <button
                              className='streamer-info__copy-button'
                              onClick={() => {
                                onCopyLinkClick();
                              }}>
                            <CopyIcon alt={t('copyLink')} title={t('copyLink')}   />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='list-group-item'>
                    <div className='row align-items-center'>
                      <div className='col-auto justify-content-center'>
                        <div>
                          {"UserID:" + user.streamer_id}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className='my-4' />
                  <strong className='mb-0'>{t('userSettingsPage.notificationSettings')}</strong>
                  <div className='list-group mb-5 shadow'>
                    <div className='list-group-item'>
                      <div className='row align-items-center'>
                        <div className='col-auto'>
                          <span>{t('userSettingsPage.sendCollabSuggestionEmails')}</span>
                          <Switch checked={sendCollabSuggestionEmails} onChange={handleSendCollabSuggestionEmailsChange}></Switch>
                        </div>
                      </div>
                    </div>
                    <div className='list-group-item'>
                      <div className='row align-items-center'>
                        <div className='col-auto'>
                          <span>{t('userSettingsPage.sendNewMessageNotifications')}</span>
                          <Switch checked={sendNewMessageNotifications} onChange={handleSendNewMessageNotificationsChange}></Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className='my-4' />
                  <strong className='mb-0'>{t('userSettingsPage.systemSettings')}</strong>
                  <div className='list-group mb-5 shadow'>
                    <div className='list-group-item'>
                      <div className='row align-items-center'>
                        <div className='col-auto'>
                          <span>{t('userSettingsPage.hideMyProfileFromSearchPage')}</span>
                          <Switch checked={hideMyProfileFromSearchPage} onChange={handleHideMyProfileFromSearchPageChange}></Switch>
                        </div>
                      </div>
                    </div>
                    <div className='list-group-item'>
                      <div className='row align-items-center'>
                        <div className='col-auto'>
                          <span className='item-lable'>{t('userSettingsPage.timezone')}</span>
                          <Select className='select' onChange={handleTimezoneChange} value={timezone}>
                            {getTimezones().map((tz, i) => {
                              return (<MenuItem value={tz.tzCode}>{tz.label}</MenuItem>);
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className='list-group-item'>
                      <div className='row align-items-center'>
                        <div className='col-auto'>
                          <span className='item-lable'>{t('userSettingsPage.language')}</span>
                          <Select className='select' onChange={handleLanguageChange} value={language}>
                            {Languages.list.map((l, i) => {
                              return (<MenuItem value={l.value}>{l.label}</MenuItem>);
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default UserSettingsPage;