import TwitchUserLink from '../TwitchUserLink';
import Collapsible from 'react-collapsible';
import TextareaAutosize from 'react-textarea-autosize';
import NextStepBtn from './NextStepBtn';
import {useState, useEffect, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg';
import ConfirmCollabModal from '../ConfirmCollabModal';
import SettingsService from '../../services/SettingService';
import StreamerGames from './StreamerGames.js';
import {PartnershipStatus} from "../../utils/Collections";
import {getAvgViewersBucket, getIsMatureString, setStreamerLanguage, valueToStringOrNoData} from "../../utils/Utils";
import CollabService from "../../services/CollabService";
import useInput from "../../utils/Input";
import GyreSpinner from "../GyreSpinner";
import AppContext from "../../store/AppContext";

const moment = require('moment');

const SuggestCollab = ({
  onClick,
  selectedStreamer
}) => {
  
  const throwInputError = input => {
    if (input.isDirty && input.isEmpty) {
      return (
        <span className='suggest-collab__error-message'>
          {t('suggestCollabComponent.form.dontForgetFillError')}
        </span>
      );
    } else if (input.isDirty && input.minLengthError) {
      return (
        <span className='suggest-collab__error-message'>
          {t('suggestCollabComponent.form.charactersError')}
        </span>
      );
    } else if (input.isDirty && !input.isValidStreamUrl) {
      return (
        <span className='suggest-collab__error-message'>
          {t('suggestCollabComponent.form.streamUrlError')}
        </span>
      );
    }
  };

  const context = useContext(AppContext);

  const [accordion1, setAccordion1] = useState(false);
  const [accordion2, setAccordion2] = useState(true);
  const [accordion3, setAccordion3] = useState(false);
  const [accordion4, setAccordion4] = useState(false);
  const [accordion5, setAccordion5] = useState(false);
  
  const [prefillLoaded, setPrefillLoaded] = useState(false);
  

  const MIN_LENGTH = 30;
  
  const greeting = useInput('', {});
  const why = useInput('', {
    isEmpty: true,
    minLength: MIN_LENGTH
  });
  const [whyPrefill, setWhyPrefill] = useState('');
  const propose = useInput('', {
    isEmpty: true,
    minLength: MIN_LENGTH
  });
  const [proposePrefill, setProposePrefill] = useState('');
  const dateInput = useInput('');
  const timeInput = useInput('');
  const lastStream = useInput('', {
    isEmpty: true,
    minLength: 10,
    validStreamUrl: false
  });

  const validColors = input => {
    if ((input.isDirty && input.minLengthError) || (input.isDirty && !input.isValidStreamUrl)) {
      return 'error';
    } else if (input.isDirty && input.minLengthError === false) {
      return 'success';
    }
  };

  const focusOnInput = id => {
    document.getElementById(id).focus();
  };

  const {t} = useTranslation();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (
      greeting.minLengthError ||
      why.minLengthError ||
      propose.minLengthError ||
      lastStream.minLengthError ||
      !lastStream.isValidStreamUrl
    ) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [greeting, why, propose, dateInput, timeInput, lastStream]);

  const [buttonText, setButtonText] = useState(t('suggestCollabComponent.form.sendBtn'));
  const [submitStatus, setSubmitStatus] = useState();
  
  useEffect(() => {
    if (!why.value && !propose.value) {
      loadPrefill();
    }
  }, []);
  
  const loadPrefill = () => {
    let prefillLoadStartTime = Date.now();
    CollabService.getCollabRequestPrefill(selectedStreamer.id, 
        selectedStreamer.languages, context.user.streamer_settings.lang).then(response => {

      const prefillLoadTime = Date.now() - prefillLoadStartTime;
      setTimeout(() => {
        if (response && !prefillLoaded) {
          if (response.why) setWhyPrefill(response.why);
          if (response.propose) setProposePrefill(response.propose);
        }
        setPrefillLoaded(true);
      }, prefillLoadTime > 3000 ? 0 : 3000 - prefillLoadTime);
    });
  };
  
  useEffect(() => {
      if (whyPrefill && !why.value) why.setValue(whyPrefill);
      if (proposePrefill && !propose.value) propose.setValue(proposePrefill);
  }, [whyPrefill, proposePrefill]);

  const sendCollabRequest = () => {
    const data = {
      receiver_id: Number(selectedStreamer.id),
      greeting: greeting.value,
      why: why.value,
      propose: propose.value,
      collab_time: `${dateInput.value} ${timeInput.value}`,
      last_stream: lastStream.value
    };
    
    const submitButton = document.getElementById('collab-submit-button');
    submitButton.disabled = true;

    CollabService.sendCollabRequest(data).then(res => {
      if (res.ok) {
        setSubmitStatus('success');
      } else {
        res.text().then(text => {
          if (text.includes('invalid or missing URL scheme')) {
            setSubmitStatus('invalidURL');
            submitButton.disabled = false;
          }
        });
        showErrorMessageModal(t('suggestCollabComponent.form.ModalErrorMessageCantSendRequestToThisUSer'), false);
      }
    }).catch(err => {
      console.log(err);
      showErrorMessageModal(t('suggestCollabComponent.form.ModalErrorMessage'), true);
    });
  };

  const [showEmailInErrorMessages, setShowEmailInErrorMessages] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  
  const showErrorMessageModal = (errorMessage, showEmail) => {
    setSubmitStatus('error');
    setShowEmailInErrorMessages(showEmail);
    setErrorMessage(errorMessage);
  }

  const handleClick = e => {
    if (!formValid) {
      e.target.classList.add('suggest-collab__send-request-button--error');
      e.target.disabled = true;
      setButtonText(t('suggestCollabComponent.form.fillAllFields'));

      setTimeout(() => {
        e.target.classList.remove('suggest-collab__send-request-button--error');
        e.target.disabled = false;
        setButtonText(t('suggestCollabComponent.form.sendBtn'));
      }, 2000);
    } else {
      sendCollabRequest();
    }
  };
  
  const onProposeChange = e => {
    propose.onChange(e);
    setPrefillLoaded(true);
  };

  const onWhyChange = e => {
    why.onChange(e);
    setPrefillLoaded(true);
  };

  return submitStatus === 'success'
    ? (
      <ConfirmCollabModal
        title={t('suggestCollabComponent.form.thankYou')}
        message={t('suggestCollabComponent.form.successMessage')}
        onClick={onClick}
        goTo='/collab-requests'
      />
    ) : submitStatus === 'error'
      ? (
        <ConfirmCollabModal
          title={t('suggestCollabComponent.form.ModalErrorTitle')}
          message={errorMessage}
          showEmail={showEmailInErrorMessages}
          onClick={onClick}
          goTo='/find-collab'
        />

      ) : (
        <section className='suggest-collab' id='suggest-modal'>
          <div className='suggest-collab__background' />
          <div className='suggest-collab__modal-wrapper'>
            <div className='suggest-collab__modal'>
              <div className='suggest-collab__wrapper'>
                <div className='suggest-collab__header'>
                  <h2 className='suggest-collab__title'>{t('suggestCollabComponent.title')}</h2>
                  <button
                    className='suggest-collab__close-button'
                    onClick={() => {
                      onClick();
                    }}>
                    <CloseIcon />
                  </button>
                </div>
                <div className='suggest-collab__streamer-card'>
                  <div className='suggest-collab__about streamer-info__about'>
                    <div
                      className={`suggest-collab__avatar-wrapper 
                streamer-info__avatar-wrapper 
                ${
                        selectedStreamer.is_registered ? 'streamer-info__avatar-wrapper--verified' : ''
                      }`}>
                      <img
                        className='suggest-collab__avatar streamer-info__avatar'
                        src={selectedStreamer.picture}
                        alt=''
                      />
                    </div>
                    <div className='suggest-collab__nickname-wrapper streamer-info__nickname-wrapper'>
                  <span className='suggest-collab__nickname streamer-info__nickname'>
                    {selectedStreamer.twitch_username}
                  </span>
                      {selectedStreamer.is_registered
                        ? (
                          <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                        src={require('../../assets/images/gyre-verified-icon.svg').default}
                        alt='gyre verified'
                      />
                    </span>
                        ) : null}
                      {selectedStreamer.partner === PartnershipStatus.Partner
                        ? (
                          <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                        src={require('../../assets/images/twitch-partner-icon.svg').default}
                        alt='twitch partner'
                      />
                    </span>
                        ) : null}
                    </div>
                    <TwitchUserLink
                      classNames='suggest-collab__twitch-link'
                      link={`https://www.twitch.tv/${selectedStreamer.twitch_username}`}
                    />
                    <div className='suggest-collab__break streamer-info__break' />
                    <StreamerGames
                      gameList={selectedStreamer.games}
                      classNames='suggest-collab__games-list'
                    />
                  </div>
                  <div className='suggest-collab__info streamer-info__info'>
                    <ul className='suggest-collab__info-list streamer-info__info-list'>
                      <li className='suggest-collab__info-item streamer-info__info-item'>
                    <span className='suggest-collab__info-title streamer-info__info-title'>
                      {t('streamerInfo.avgViews')}
                    </span>
                        <span className='suggest-collab__info-value streamer-info__info-value'>
                      {selectedStreamer.avg_viewers
                        ? getAvgViewersBucket(selectedStreamer.avg_viewers)
                        : t('streamersComponent.streamerInfo.noData')}
                    </span>
                      </li>
                      <li className='suggest-collab__info-item streamer-info__info-item'>
                    <span className='suggest-collab__info-title streamer-info__info-title'>
                      {t('streamerInfo.followers')}
                    </span>
                        <span className='suggest-collab__info-value streamer-info__info-value'>
                          {valueToStringOrNoData(selectedStreamer.followers)}
                    </span>
                      </li>
                      <li className='suggest-collab__info-item streamer-info__info-item'>
                    <span className='suggest-collab__info-title streamer-info__info-title'>
                      {t('streamerInfo.localTime')}
                    </span>
                        <span className='suggest-collab__info-value streamer-info__info-value'>
                      {selectedStreamer.stream_hours_min && selectedStreamer.stream_hours_max
                        ? `${moment
                          .utc()
                          .hour(selectedStreamer.stream_hours_min)
                          .tz(SettingsService.getUserTimezone())
                          .locale('en')
                          .format('hh:00 A')} - ${moment
                          .utc()
                          .hour(selectedStreamer.stream_hours_max)
                          .tz(SettingsService.getUserTimezone())
                          .locale('en')
                          .format('hh:00 A')}`
                        : t('streamersComponent.streamerInfo.noData')}
                    </span>
                      </li>
                      <li className='suggest-collab__info-item streamer-info__info-item'>
                        <span className='suggest-collab__info-title streamer-info__info-title'>
                          {t('streamerInfo.mature')}
                        </span>
                        <span className='suggest-collab__info-value streamer-info__info-value'>
                          {getIsMatureString(selectedStreamer)}
                        </span>
                      </li>
                      <li className='suggest-collab__info-item streamer-info__info-item'>
                    <span className='suggest-collab__info-title streamer-info__info-title'>
                      {t('streamerInfo.language')}
                    </span>
                        <span className='suggest-collab__info-value streamer-info__info-value'>
                      {setStreamerLanguage(selectedStreamer.languages)}
                    </span>
                      </li>
                      <li className='suggest-collab__info-item streamer-info__info-item'>
                    <span className='suggest-collab__info-title streamer-info__info-title'>
                      {t('streamersComponent.streamerInfo.location')}
                    </span>
                        <span className='suggest-collab__info-value streamer-info__info-value'>
                          {selectedStreamer.timezone}
                    </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <form>
                  <div className='suggest-collab__spoilers-container'>
                    {/*<Collapsible*/}
                    {/*  trigger={t('suggestCollabComponent.form.greeting.title') + ' (' + t('optional') + ')'}*/}
                    {/*  classParentString='suggest-collab-spoiler'*/}
                    {/*  className='is-closed'*/}
                    {/*  triggerOpenedClassName={validColors(greeting)}*/}
                    {/*  triggerClassName={validColors(greeting)}*/}
                    {/*  openedClassName='is-open'*/}
                    {/*  open={accordion1}>*/}
                    {/*  <div className='suggest-collab__input-wrapper'>*/}
                    {/*    <span className='suggest-collab__input-counter' title={t("minLength")}>*/}
                    {/*      {greeting.value.length}/{MIN_LENGTH}*/}
                    {/*    </span>*/}
                    {/*    <TextareaAutosize*/}
                    {/*      className={`suggest-collab__input suggest-collab__input--textarea ${validColors(*/}
                    {/*        greeting*/}
                    {/*      )} ${greeting.value.length > 0 ? 'filled' : ''}`}*/}
                    {/*      id='greeting-input'*/}
                    {/*      name='greeting'*/}
                    {/*      placeholder={t('suggestCollabComponent.form.greeting.placeholder')}*/}
                    {/*      autoComplete='off'*/}
                    {/*      autoFocus*/}
                    {/*      minLength='0'*/}
                    {/*      maxLength='800'*/}
                    {/*      value={greeting.value}*/}
                    {/*      onChange={e => greeting.onChange(e)}*/}
                    {/*      onBlur={e => greeting.onBlur(e)}*/}
                    {/*    />*/}
                    {/*    <label className='suggest-collab__input-label' htmlFor='greeting-input'>*/}
                    {/*      {t('suggestCollabComponent.form.greeting.label')}*/}
                    {/*    </label>*/}
                    {/*  </div>*/}
                    {/*  <div className='suggest-collab__optional'>{t('suggestCollabComponent.form.optional')}</div>*/}
                    {/*  <span className='suggest-collab__example'>*/}
                    {/*    {t('suggestCollabComponent.form.greeting.example.title')}*/}
                    {/*  </span>*/}
                    {/*  <p className='suggest-collab__example-text'>*/}
                    {/*    {t('suggestCollabComponent.form.greeting.example.text')}*/}
                    {/*  </p>*/}
                    {/*  <NextStepBtn*/}
                    {/*    onClick={() => {*/}
                    {/*      focusOnInput('reason-to-collab-input');*/}
                    {/*      setAccordion1(false);*/}
                    {/*      setAccordion2(true);*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*</Collapsible>*/}
                    <Collapsible
                      trigger={t('suggestCollabComponent.form.reasonToCollab.title')}
                      classParentString='suggest-collab-spoiler'
                      className='is-closed'
                      openedClassName='is-open'
                      open={accordion2}
                      triggerOpenedClassName={validColors(why)}
                      triggerClassName={validColors(why)}>
                      <div className='suggest-collab__input-wrapper'>
                        <div className='suggest-collab__input-counter' title={t("minLength")}>
                          {why.value.length}/{MIN_LENGTH}
                        </div>
                        <div className='suggest-collab__input-spinner' > 
                          <GyreSpinner loading={!prefillLoaded} size={15} 
                                       label={t("loadingSuggestions")}/>
                        </div>
                        <TextareaAutosize
                          className={`suggest-collab__input suggest-collab__input--textarea ${validColors(
                            why
                          )} ${why.value.length > 0 ? 'filled' : ''}`}
                          id='reason-to-collab-input'
                          name='reasonToCollab'
                          value={why.value}
                          autoComplete='off'
                          maxLength='800'
                          onChange={onWhyChange}
                          onBlur={e => why.onBlur(e)}
                        />
                        <label className='suggest-collab__input-label' htmlFor='reason-to-collab-input'>
                          {t('suggestCollabComponent.form.reasonToCollab.label')}
                        </label>
                      </div>
                      {throwInputError(why)}
                      <span className='suggest-collab__example'>
                    {t('suggestCollabComponent.form.reasonToCollab.example.title')}
                  </span>
                      <p className='suggest-collab__example-text'>
                        {t('suggestCollabComponent.form.reasonToCollab.example.text')}
                        <ul className='suggest-collab__example-list'>
                          <li>{t('suggestCollabComponent.form.reasonToCollab.example.l1')}</li>
                          <li>{t('suggestCollabComponent.form.reasonToCollab.example.l2')}</li>
                          <li>{t('suggestCollabComponent.form.reasonToCollab.example.l3')}</li>
                        </ul>
                      </p>
                      <NextStepBtn
                        onClick={() => {
                          focusOnInput('propose-input');
                          setAccordion2(false);
                          setAccordion3(true);
                        }}
                      />
                    </Collapsible>
                    <Collapsible
                      trigger={t('suggestCollabComponent.form.idea.title')}
                      classParentString='suggest-collab-spoiler'
                      className='is-closed'
                      openedClassName='is-open'
                      open={accordion3}
                      triggerOpenedClassName={validColors(propose)}
                      triggerClassName={validColors(propose)}>
                      <div className='suggest-collab__input-wrapper'>
                        <span className='suggest-collab__input-counter'  title={t("minLength")}>
                          {propose.value.length}/{MIN_LENGTH}
                        </span>
                        <div className='suggest-collab__input-spinner' >
                          <GyreSpinner loading={!prefillLoaded} size={15} 
                                       label={t("loadingSuggestions")}/>
                        </div>
                        <TextareaAutosize
                          className={`suggest-collab__input suggest-collab__input--textarea ${validColors(
                            propose
                          )} ${propose.value.length > 0 ? 'filled' : ''}`}
                          id='propose-input'
                          name='propose'
                          value={propose.value}
                          autoComplete='off'
                          maxLength='800'
                          onChange={onProposeChange}
                          onBlur={e => propose.onBlur(e)}
                        />
                        <label className='suggest-collab__input-label' htmlFor='propose-input'>
                          {t('suggestCollabComponent.form.idea.label')}
                        </label>
                      </div>
                      {throwInputError(propose)}
                      <span className='suggest-collab__example'>
                    {t('suggestCollabComponent.form.idea.example.title')}
                  </span>
                      <p className='suggest-collab__example-text'>
                        {t('suggestCollabComponent.form.idea.example.text')}
                      </p>
                      <NextStepBtn
                        onClick={() => {
                          focusOnInput('date-input');
                          setAccordion3(false);
                          setAccordion4(true);
                        }}
                      />
                    </Collapsible>
                    <Collapsible
                      trigger={t('suggestCollabComponent.form.time.title')}
                      classParentString='suggest-collab-spoiler'
                      className='is-closed'
                      openedClassName='is-open'
                      open={accordion4}
                      triggerOpenedClassName={validColors(timeInput || dateInput)}
                      triggerClassName={validColors(timeInput || dateInput)}>
                      <p className='suggest-collab__description'>
                        {t('suggestCollabComponent.form.time.subtitle')}
                      </p>
                      <div className='suggest-collab__input-wrapper'>
                        <input
                          type='text'
                          name='date'
                          id='date-input'
                          className={`suggest-collab__input ${validColors(dateInput)} ${
                            dateInput.value.length > 0 ? 'filled' : ''
                          }`}
                          value={dateInput.value}
                          autoComplete='off'
                          maxLength='74'
                          onChange={e => dateInput.onChange(e)}
                          onBlur={e => dateInput.onBlur(e)}
                        />
                        <label className='suggest-collab__input-label' htmlFor='date-input'>
                          {t('suggestCollabComponent.form.time.inputLabel')}
                        </label>
                      </div>
                      <div className='suggest-collab__input-wrapper'>
                        <input
                          type='text'
                          name='convenientTime'
                          id='time-input'
                          className={`suggest-collab__input ${validColors(timeInput)} ${
                            timeInput.value.length > 0 ? 'filled' : ''
                          }`}
                          placeholder={t('suggestCollabComponent.form.time.placeholder')}
                          value={timeInput.value}
                          autoComplete='off'
                          maxLength='74'
                          onChange={e => timeInput.onChange(e)}
                          onBlur={e => timeInput.onBlur(e)}
                        />
                        <label className='suggest-collab__input-label' htmlFor='time-input'>
                          {t('suggestCollabComponent.form.time.usualTime')}
                        </label>
                      </div>
                      <span className='suggest-collab__example'>
                    {t('suggestCollabComponent.form.time.example.title')}
                  </span>
                      <p className='suggest-collab__example-text'>
                        {t('suggestCollabComponent.form.time.example.text')}
                      </p>
                      <NextStepBtn
                        onClick={() => {
                          focusOnInput('my-stream-input');
                          setAccordion4(false);
                          setAccordion5(true);
                        }}
                      />
                    </Collapsible>
                    <Collapsible
                      trigger={t('suggestCollabComponent.form.lastStream.title')}
                      classParentString='suggest-collab-spoiler'
                      className='is-closed'
                      openedClassName='is-open'
                      open={accordion5}
                      triggerOpenedClassName={validColors(lastStream)}
                      triggerClassName={validColors(lastStream)}>
                      <div className='suggest-collab__input-wrapper'>
                        <input
                          type='text'
                          name='twitchLink'
                          id='my-stream-input'
                          className={`suggest-collab__input suggest-collab__input--twitch-link ${validColors(
                            lastStream
                          )} ${lastStream.value.length > 0 ? 'filled' : ''}`}
                          placeholder={t('suggestCollabComponent.form.lastStream.placeholder')}
                          value={lastStream.value}
                          autoComplete='off'
                          maxLength='2083'
                          onChange={e => {
                            lastStream.onChange(e);
                            setSubmitStatus('');
                          }}
                          onBlur={e => {
                            lastStream.onBlur(e);
                            validColors(lastStream);
                          }}
                        />
                        <label className='suggest-collab__input-label' htmlFor='my-stream-input'>
                          {t('suggestCollabComponent.form.lastStream.label')}
                        </label>
                      </div>
                      {throwInputError(lastStream)}
                      <span className='suggest-collab__example'>
                        {t('suggestCollabComponent.form.lastStream.hintLabel')}
                      </span>
                      <p className='suggest-collab__example-text'>
                        {t('suggestCollabComponent.form.lastStream.hintText')}
                      </p>
                    </Collapsible>
                  </div>
                  <button
                    type='button'
                    onClick={e => handleClick(e)}
                    id='collab-submit-button'
                    className='suggest-collab__send-request-button'>
                    {buttonText}
                  </button>
                </form>
              </div>
              <div className='suggest-collab__indent' />
            </div>
          </div>
        </section>
      );
};

export default SuggestCollab;
