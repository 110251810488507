import React, {useContext} from 'react';
import {Helmet} from 'react-helmet';
import {useState, useEffect} from 'react';
import Collapsible from 'react-collapsible';
import Sorting from '../components/find-collab/Sorting';
import Streamers from '../components/find-collab/Streamers';
import Filters from '../components/find-collab/Filters';
import Pagination from '../components/Pagination';
import BottomBanners from '../components/find-collab/BottomBanners';
import SuggestCollab from '../components/find-collab/SuggestCollab';
import {useTranslation} from 'react-i18next';
import TopEvents from '../components/find-collab/TopEvents.js';
import SettingsService from '../services/SettingService.js';
import EventCreateModal from '../components/events/EventCreateModalDialog.js';
import {setMaxValue, setMinValue, useWindowSize} from "../utils/Utils";
import StreamersService from "../services/StreamersService";
import AppContext from "../store/AppContext";

const moment = require('moment');
let searchTimeout = 0;

const FindCollabPage = ({
  openSignUpModal
}) => {
  const {t} = useTranslation();
  const [width] = useWindowSize();
  const [paginationOffsetIndex, setPaginationOffsetIndex] = useState(0);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [count, setCount] = useState();
  const [sortingMethod, setSortingMethod] = useState('recommended');
  const [userName, setUserName] = useState('');
  const [streamersList, setStreamersList] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const context = useContext(AppContext);

  const [streamDays, setStreamDays] = useState([]);
  const [hoursMin, setHoursMin] = useState();
  const [hoursMax, setHoursMax] = useState();
  const [language, setLanguage] = useState(SettingsService.getUserLanguage());
  const [partners, setPartners] = useState('');
  const [game, setGame] = useState('');
  const [followers, setFollowers] = useState([]);
  const [avgViewers, setAvgViewers] = useState([]);
  const [mature, setMature] = useState([]);
  const [activeStreamers, setActiveStreamers] = useState(true);
  const [gyreOnly, setGyreOnly] = useState(false);
  const [hasEmailOnly, setHasEmailOnly] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [needApplyFilters, setNeedApplyFilters] = useState(false);
  
  const applyFilters = (delay = 100) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setNeedApplyFilters(true);
    }, delay);
  }

  const sortingOptions = [
    {
      value: 'recommended',
      label: t('sorting.recommendedFirst')
    },
    {
      value: '-followers',
      label: t('sorting.followersDescending')
    },
    {
      value: 'followers',
      label: t('sorting.followersAscending')
    },
    {
      value: '-avg_viewers',
      label: t('sorting.avgViewsDescending')
    },
    {
      value: 'avg_viewers',
      label: t('sorting.avgViewsAscending')
    }
  ];

  useEffect(() => {
    if (context.user && context.user.is_admin) {
      setAdmin(true);
    }
  }, []);

  const ITEMS_ON_PAGE = 7;

  const getUtcTime = (time) => {
    return moment(time, 'HH:mm').utc().format('H');
  };

  const resetFilters = () => {
    setStreamDays('');
    setHoursMin(null);
    setHoursMax(null);
    setLanguage(SettingsService.getUserLanguage());
    setPartners('');
    setGame('');
    setFollowers('');
    setAvgViewers('');
    setMature([]);
    setActiveStreamers(true);
    setGyreOnly(false);
    setUserName('');
    setSortingMethod('recommended');
    setPaginationOffsetIndex(0);
    setHasEmailOnly(false);

    applyFilters();
  };
  
  const getStreamers = async () => {
    let params;
    
    if (userName && userName.length > 3) {
      // search by user name only
      params = { 
        username: userName,
        active_only: false,
        registered_only: false,
        has_email_only: false,
        limit: ITEMS_ON_PAGE,
        offset: paginationOffsetIndex
      };
    }
    else {
      params = {
        sorting: sortingMethod,
        followers_min: setMinValue(followers, '10000'),
        followers_max: setMaxValue(followers, '10000'),
        avg_viewers_min: setMinValue(avgViewers, '1000'),
        avg_viewers_max: setMaxValue(avgViewers, '1000'),
        stream_hours_min: hoursMin ? getUtcTime(hoursMin) : '',
        stream_hours_max: hoursMax ? getUtcTime(hoursMax) : '',
        stream_days: streamDays,
        game_ids: game,
        languages: language,
        partnerships: partners,
        mature: mature,
        active_only: activeStreamers,
        registered_only: gyreOnly,
        has_email_only: hasEmailOnly,
        valid_only: !admin, // let admin search everyone
        offset: paginationOffsetIndex,
        shuffle: sortingMethod === 'recommended',
        username: userName || '',
        limit: ITEMS_ON_PAGE
      };
    }
    
    setLoading(true);
    const res = await StreamersService.getStreamers(params);
    
    setCount(res.meta.count);
    setStreamersList(res.streamers);
    setLoading(false);
  };

  const [suggest, setSuggest] = useState(false);
  const openSuggestForm = () => {
    setSuggest(!suggest);
  };

  const toggleCreateEventModal = () => {
    if (context.isAuthenticated) {
      setShowCreateEventModal(!showCreateEventModal);
    } else {
      openSignUpModal();
    }
  };

  useEffect(() => {
    if (suggest) {
      document.body.classList.add('scroll-off');
    } else {
      document.body.classList.remove('scroll-off');
    }
  }, [suggest]);
  
  useEffect(() => {
    if (userName.length > 2 || !userName) {
      setPaginationOffsetIndex(0);
      applyFilters(1000);
    }
  }, [userName]);

  useEffect(() => {
    setPaginationOffsetIndex(0);
    applyFilters();
  }, [sortingMethod]);
  
  useEffect(() => {
    applyFilters();
  }, [paginationOffsetIndex]);

  useEffect(() => {
    if (needApplyFilters) {
      getStreamers();
      setNeedApplyFilters(false);
    }
  }, [needApplyFilters]);

  const [selectedStreamer, setSelectedStreamer] = useState();

  const getSelectedStreamer = currentId => {
    streamersList.map(streamer => {
      if (streamer.id === currentId) {
        setSelectedStreamer(streamer);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{t('findCollabPage.helmetTitle')}</title>
      </Helmet>
      <section className='main'>
        <div className='main__wrapper'>
          <h2 className='main__title'>{t('findCollabPage.mainTitle')}</h2>
          <p className='main__description'>
            {t('findCollabPage.mainDescription.p1')}
            <br />
            {t('findCollabPage.mainDescription.p2')}
          </p>
          <TopEvents openSignUpModal={openSignUpModal} />
          <div className='main__container'>
            <aside className='main__filters'>
              {width < 1280 ? <>
                <h3 className='main__block-title'>{t('findCollabPage.allStreamers')}</h3>
                <p className='main__block-description'>{t('findCollabPage.allStreamersDescr')}</p>
              </> : null}
              <Collapsible trigger={t('filtersComponent.title')} open={width >= 1280}>
                <Filters
                  applyFilters={applyFilters}
                  streamDays={streamDays}
                  setStreamDays={setStreamDays}
                  hoursMin={hoursMin}
                  hoursMax={hoursMax}
                  setHoursMin={setHoursMin}
                  setHoursMax={setHoursMax}
                  language={language}
                  setLanguage={setLanguage}
                  partners={partners}
                  setPartners={setPartners}
                  game={game}
                  setGame={setGame}
                  followers={followers}
                  setFollowers={setFollowers}
                  avgViewers={avgViewers}
                  setAvgViewers={setAvgViewers}
                  mature={mature}
                  setMature={setMature}
                  activeStreamers={activeStreamers}
                  setActiveStreamers={setActiveStreamers}
                  gyreOnly={gyreOnly}
                  hasEmailOnly={hasEmailOnly}
                  setHasEmailOnly={setHasEmailOnly}
                  setGyreOnly={setGyreOnly}
                  resetFilters={resetFilters}
                  setPaginationOffsetIndex={setPaginationOffsetIndex}
                  setScrollToTop={setScrollToTop}
                />
              </Collapsible>
            </aside>
            <div className='main__streamers-wrapper'>
              {width >= 1280 ? <>
                <h3 className='main__block-title'>{t('findCollabPage.allStreamers')}</h3>
                <p className='main__block-description'>{t('findCollabPage.allStreamersDescr')}</p>
              </> : null}
              <Sorting
                sortingOptions={sortingOptions}
                sortingMethod={sortingMethod}
                setSortingMethod={setSortingMethod}
                userName={userName}
                setUserName={setUserName}
                setPaginationOffsetIndex={setPaginationOffsetIndex}
              />
              <Streamers
                onClick={openSuggestForm}
                streamersList={streamersList}
                loading={loading}
                openSignUpModal={openSignUpModal}
                getSelectedStreamer={getSelectedStreamer}
                admin={admin}
                scrollToTop={scrollToTop}
              />
              <Pagination setScrollToTop={setScrollToTop} paginationOffsetIndex={paginationOffsetIndex} setPaginationOffsetIndex={setPaginationOffsetIndex} count={count} itemsPerPageIndex={5} />
              <section className='bottom-banners'>
                <BottomBanners openSignUpModal={openSignUpModal} toggleCreateEventModal={toggleCreateEventModal} />
              </section>
            </div>
          </div>
        </div>
      </section>
      {suggest ? (
        <SuggestCollab
          onClick={openSuggestForm}
          selectedStreamer={selectedStreamer}
        />
      ) : null}
      {showCreateEventModal ? (
        <EventCreateModal
          onCloseClick={toggleCreateEventModal}
        />
      ) : null}
    </>
  );
};

export default FindCollabPage;
