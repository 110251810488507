import CollaborateNowBtn from '../CollaborateNowBtn';
import {useTranslation} from 'react-i18next';

const About = () => {
  const {t} = useTranslation();

  return (
    <section className="about" id="about">
      <div className="about__wrapper">
        <h2 className="about__title h2-title">{t('landingPage.about.title')}</h2>
        <p className="about__description">
          {t('landingPage.about.p1')}
          <br />
          {t('landingPage.about.p2')}
        </p>
        <p className="about__video-description">{t('landingPage.about.p3')}</p>
        <div className="about__player">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/20RHWTVuPyI?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <b className="about__cta">
          {t('landingPage.about.p4')} <span>{t('landingPage.about.p5')}</span>
        </b>
        <CollaborateNowBtn classNames="about__collaborate-btn" />
      </div>
    </section>
  );
};

export default About;
