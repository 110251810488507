import {useTranslation} from 'react-i18next';
import {ReactComponent as LogInIcon} from '../assets/images/login-icon.svg';

const SignUpButton = ({classNames, openSignUpModal}) => {
  const {t} = useTranslation();

  return (
    <button className={`${classNames} sign-up-btn`} onClick={openSignUpModal}>
      <LogInIcon />
      {t('signUpBtn')}
    </button>
  );
};

export default SignUpButton;
