import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    // lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en', 'ru'],

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
