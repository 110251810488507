import AuthService from './AuthService';

const moment = require('moment-timezone');

const {REACT_APP_GYRE_BACKEND} = process.env;

class SettingsService {

  static saveSettings = async (params, onError) => {
    const formData = new FormData();
    if (params.available_for_notification_collab_request != null) {
      formData.append('available_for_notification_collab_request',
        params.available_for_notification_collab_request);
    }

    if (params.available_for_notification_chat_message != null) {
      formData.append('available_for_notification_chat_message',
        params.available_for_notification_chat_message);
    }

    if (params.available_for_collab_request != null) {
      formData.append('available_for_collab_request',
        params.available_for_collab_request);
    }

    if (params.lang != null) {
      formData.append('lang', params.lang);
    }

    if (params.timezone != null) {
      formData.append('timezone', params.timezone);
    }

    const res = await fetch(`${REACT_APP_GYRE_BACKEND}/api/settings`, {
      body: formData,
      method: 'PUT',
      credentials: 'include',
      mode: 'cors'
    })
      .then(res => {
        if (onError) {
          onError(res.status);
        }
        return res;
      })
      .then(res => res.json());

    return res;
  };

  static getSettings = async () => {
    const res = await fetch(`${REACT_APP_GYRE_BACKEND}/api/settings`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then(res => res.json());

    return res;
  };

  static getUserLanguageFromBrowser = () => {
    let lang = navigator.language;
    return lang.split('-')[0]; // take first part of ru-RU for example
  };

  static getUserTimezoneFromBrowser = () => {
    return moment.tz.guess();
  };

  static getUserLanguage = () => {
    const user = AuthService.getUser();
    let lang = null;
    if (user) {
      lang = user.streamer_settings.lang;
    } else {
      lang = this.getUserLanguageFromBrowser();
    }

    return lang;
  };

  static getUserTimezone = () => {
    const user = AuthService.getUser();
    let timezone = null;
    if (user) {
      timezone = user.streamer_settings.timezone;
    } else {
      timezone = this.getUserTimezoneFromBrowser();
    }

    return timezone;
  };

  static getUserTimezoneMoment = () => {
    return moment.tz(this.getUserTimezone());
  };

  static setDefaults = (user) => {
    let params = null;
    if (!user.streamer_settings.lang) {
      params ||= {};
      params.lang = this.getUserLanguageFromBrowser();
      user.streamer_settings.lang = params.lang;
    }

    if (!user.streamer_settings.timezone) {
      params ||= {};
      params.timezone = this.getUserTimezoneFromBrowser();
      user.streamer_settings.timezone = params.timezone;
    }

    if (params) {
      this.saveSettings(params);
    }
  }

  static setWebsiteLanguage = (language) => {
    localStorage.setItem('gyre_website_language', language);
  }

  static getWebsiteLanguage = () => {
    return localStorage.getItem('gyre_website_language') || 'eng';
  }
}

export default SettingsService;