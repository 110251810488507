import '../assets/sass/style.scss';
// import Feature from './Feature';
import {useEffect, useState, Suspense} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ScrollToTop from './ScrollToTop';
import Analytics from './Analytics';
import PageHeader from './page-header/PageHeader';
import LandingPage from '../pages/LandingPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import FindCollabPage from '../pages/FindCollabPage';
import CollabHubPage from '../pages/CollabHubPage';
import CollaborationRequestPage from '../pages/CollaborationRequestPage';
import MyCollabRequestsPage from '../pages/MyCollabRequestsPage';
import MyGyreEventPage from '../pages/MyGyreEventPage';
import FindEventsPage from '../pages/FindEventsPage';
import ChatsPage from '../pages/ChatsPage';
import SignUpModal from './SignUpModal';
import CookieBanner from './CookieBanner';
import NotFound from './NotFound';
import UnsubscribePage from '../pages/UnsubscribePage';
import UserSettingsPage from '../pages/UserSettingsPage';
import UnauthorizedPage from '../pages/UnauthorizedPage';
import AuthService from '../services/AuthService';
import SettingsService from '../services/SettingService';
import StreamerPage from "../pages/StreamerPage";
import AppContext from "../store/AppContext";
import ChatService from "../services/ChatService";
import NotificationsService from "../services/NotificationsService";
import CollabService from "../services/CollabService";
import SupportPage from "../pages/SupportPage";
import i18n from "i18next";
import queryString from "query-string";

const initFeatures = () => {
  localStorage.setItem(
    'flags',
    JSON.stringify([
      {
        name: 'my-collab-requests-page',
        active: true
      }
    ])
  );
};

const App = () => {
  initFeatures();
  const [cookiesModalVisible, setCookiesModalVisible] = useState(true);
  const cookiesAccept = () => {
    setCookiesModalVisible(false);
  };

  const {REACT_APP_GYRE_BACKEND} = process.env;

  const [signUpForm, setSignUpForm] = useState(false);
  const openSignUpForm = () => {
    setSignUpForm(!signUpForm);
  };

  useEffect(() => {
    if (signUpForm) {
      document.body.classList.add('scroll-off');
    } else {
      document.body.classList.remove('scroll-off');
    }
  }, [signUpForm]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  
  const checkAuthorization = async () => {
    setLoading(true);

    const user = await AuthService.checkAuthorization(() => {
      setIsAuthenticated(true);
    });
    
    if (user) {
      user.id = user.streamer_id;
      setUser(user);
      loadUserData(user);

      // change back color for fake mode
      if (user.is_fake) {
        document.body.classList.add('fake-login');
      } else {
        document.body.classList.remove('fake-login');
      }
    }
    setLoading(false);
  };


  const loadUserData = (user) => {
    if (user) {
      SettingsService.setDefaults(user);

      NotificationsService.getNotifications().then(notifications => {
        if (notifications) {
          ChatService.unreadChatMessages.set(notifications.has_unread_chat_messages);
          CollabService.hasPendingCollabRequests.set(notifications.has_unread_pending_collab_requests);
        }
      });
    }
  };

  const loadSettings = () => {
    const query = queryString.parse(window.location.search);
    const queryLang = query.site_language;
    if (queryLang) {
      i18n.changeLanguage(queryLang);
    } 
    else {
      const language = SettingsService.getWebsiteLanguage();
      if (language) {
        if (i18n.language !== language && i18n.options.supportedLngs.includes(language)) {
          i18n.changeLanguage(language);
        }
      }
    }
  }
  
  useEffect(() => {
    loadSettings();
    checkAuthorization();
  }, []);

  return (
  <AppContext.Provider value={
    {
      user: user,
      isAuthenticated: isAuthenticated,
      loading: loading
    }
  }>
    <Suspense fallback={<h1>Loading</h1>}>
      {!loading
        ? (
          <>
            <Router>
              {user && user.is_fake ? (
                <div className='fake-login'>
                  <div className='fake-login__wrapper'>
                    <p className='fake-login__notice'>This is fake login</p>
                  </div>
                </div>
              ) : null}
              <PageHeader
                openSignUpModal={openSignUpForm}
              />
              <Analytics />
              <ScrollToTop>
                <Switch>
                  <Route
                    exact
                    path='/'
                    render={() => (
                      <LandingPage
                        openSignUpModal={openSignUpForm}
                      />
                    )}
                  />
                  <Route
                      exact
                      path='/register'
                      render={() => (
                          <LandingPage
                              openSignUpModal={openSignUpForm}
                              register={true}
                          />
                      )}
                  />
                  <Route
                    exact
                    path='/privacy-policy'
                    render={() => (
                      <PrivacyPolicyPage
                        openSignUpModal={openSignUpForm}
                      />
                    )}
                  />
                  <Route
                      exact
                      path='/support'
                      render={() => (
                          <SupportPage />
                      )}
                  />
                  <Route
                    exact
                    path='/find-collab'
                    render={() => (
                      <FindCollabPage
                        openSignUpModal={openSignUpForm}
                      />
                    )}
                  />
                  <Route
                    exact
                    path='/collab/:id'
                    render={() => <CollaborationRequestPage />}
                  />
                  <PrivateRoute
                      exact
                      path='/streamer/:streamerId'
                      isAuthenticated={isAuthenticated}
                      component={() => <StreamerPage />}
                  />
                  <PrivateRoute
                    exact
                    path='/collab-requests'
                    isAuthenticated={isAuthenticated}
                    component={() => <MyCollabRequestsPage />}
                  />
                  <PrivateRoute
                    exact
                    path='/my-events'
                    isAuthenticated={isAuthenticated}
                    component={() => <MyGyreEventPage userName={user.twitch_id_token.preferred_username} />}
                  />
                  <Route
                      exact
                      path='/gyre-events/:eventId?'
                      render={() => (
                          <FindEventsPage
                              api={REACT_APP_GYRE_BACKEND}
                              openSignUpModal={openSignUpForm}
                              loggedInStatus={isAuthenticated}
                              user={user}
                          />
                      )}
                  />
                  <PrivateRoute
                    exact
                    path='/chat/:chatId?'
                    isAuthenticated={isAuthenticated}
                    component={() => <ChatsPage />}
                  />
                  <Route
                    exact
                    path='/collab-hub'
                    render={() => (
                      <CollabHubPage
                        api={REACT_APP_GYRE_BACKEND}
                        openSignUpModal={openSignUpForm}
                        loggedInStatus={isAuthenticated}
                      />
                    )}
                  />
                  <Route exact path='/unsubscribe' component={() => <UnsubscribePage />} />
                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path='/user-settings'
                    component={() => (
                      <UserSettingsPage
                        openSignUpModal={openSignUpForm}
                        loggedInStatus={isAuthenticated}
                        user={user}
                      />
                    )}
                  />
                  <Route exact path='/unauthorized' render={() => <UnauthorizedPage />} />
                  <Route render={() => <NotFound />} />
                </Switch>
              </ScrollToTop>
              {signUpForm ? (
                <SignUpModal onClick={openSignUpForm} />
              ) : null}
              {cookiesModalVisible ? <CookieBanner cookiesAccept={cookiesAccept} /> : null}
            </Router>
          </>
        ) : null}
    </Suspense>
  </AppContext.Provider>
  );
};

export default App;
