import {Subject} from "rxjs";

class ObservableProperty {
    constructor(initialValue) {
        this._value = initialValue;
        this._subject = new Subject();
        this._observable = this._subject.asObservable();
    }
    
    get = () => this._value;

    set = (newValue) => {
        if (this._value !== newValue) {
            this._value = newValue;
            this._subject.next(newValue);
        }
        return this._observable;
    }

    onValueChanged = () => this._observable;
}


export default ObservableProperty;


    