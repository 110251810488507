import Collapsible from 'react-collapsible';
import TextareaAutosize from 'react-textarea-autosize';
import NextStepBtn from '../find-collab/NextStepBtn';
import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg';
import ConfirmCollabModal from '../ConfirmCollabModal';
import DimmedSelector from '../selectors/DimmedSelector';
import {ReactComponent as CopyIcon} from "../../assets/images/copy-icon.svg";
import Utils from "../../utils/Utils";

const queryString = require('query-string');

const EditEventModal = ({
                          event,
                          getEventData,
                          selectedStreamer,
                          getGamesForSelectedEvent,
                          gamesSelectedEvent,
                          toggleEventModal,
                          toggleEditEventModal,
}) => {
  const {t} = useTranslation();
  const {REACT_APP_GYRE_BACKEND} = process.env;
  const [accordion1, setAccordion1] = useState(true);
  const [accordion2, setAccordion2] = useState(false);
  const [accordion3, setAccordion3] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [buttonTextCreateAnEvent, setButtonTextCreateAnEvent] = useState(t('EventCreateModalComponent.form.editEvent'));
  const [submitStatus, setSubmitStatus] = useState();
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const [gamesFromSearch, setGamesFromSearch] = useState(gamesSelectedEvent);
  const [gameList, setGameList] = useState([]);
  const [gameListIds, setGameListIds] = useState([]);

  const MAX_LENGTH_DESCRIPTION = 800;
  const MAX_LENGTH_NAME = 75;
  const MIN_DESCRIPTION_LENGTH = 30;


  const getNameGamesSelectedEvent = async () => {
    const gameList = await getGamesForSelectedEvent()

    setGameList(gameList.map(item => {
      return {
        'id': item.value,
        'name': item.label
      }
    }));
    
    setGameListIds(gameList.map(item => {
      return item.value;
    }))
  };

  useEffect(() => {
    getNameGamesSelectedEvent()
  }, []);

  const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true);
    const [minLengthError, setMinLengthError] = useState(false);

    useEffect(() => {
      for (const validation in validations) {
        switch (validation) {
          case 'minLength':
            value.length < validations[validation]
              ? setMinLengthError(true)
              : setMinLengthError(false);
            break;

          case 'isEmpty':
            value ? setEmpty(false) : setEmpty(true);
            break;

          default:
            break;
        }
      }
    }, [value]);

    return {
      isEmpty,
      minLengthError
    };
  };

  const useInput = (initinalValue, validations) => {
    const [value, setValue] = useState(initinalValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChange = e => {
      setValue(e.target.value);
    };

    const onBlur = e => {
      setDirty(true);
    };

    return {
      value,
      onChange,
      onBlur,
      ...valid,
      isDirty
    };
  };

  const eventNameInput = useInput(event.event_name, {
    isEmpty: true,
    minLength: 5
  });
  const eventDescriptionInput = useInput(event.description, {
    isEmpty: true,
    minLength: MIN_DESCRIPTION_LENGTH
  });
  const whenStreamInput = useInput(event.when, {
    isEmpty: true,
    minLength: 1
  });
  const aboutYourselfInput = useInput(event.about_creator, {
    isEmpty: true,
    minLength: MIN_DESCRIPTION_LENGTH
  });

  const throwInputError = input => {
    if (input.isDirty && input.isEmpty) {
      return (
        <span className='event-details__error-message'>
          {t('EventCreateModalComponent.form.emptyFieldError')}
        </span>
      );
    } else if (input.isDirty && input.minLengthError) {
      return (
        <span className='event-details__error-message'>
          {t('EventCreateModalComponent.form.charactersError')}
        </span>
      );
    }
  };

  const validColors = input => {
    if (input.isDirty && input.minLengthError) {
      return 'error';
    } else if (input.isDirty && input.minLengthError === false) {
      return 'success';
    }
  };

  const focusOnInput = id => {
    document.getElementById(id).focus();
  };

  useEffect(() => {
    if (
      eventNameInput.minLengthError ||
      eventDescriptionInput.minLengthError ||
      whenStreamInput.minLengthError ||
      aboutYourselfInput.minLengthError
    ) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [eventNameInput, eventDescriptionInput, whenStreamInput, aboutYourselfInput]);

  let gamesInputValue = '';
  const inputHandleChangeGame = (event) => {
    gamesInputValue=event
    if (gamesInputValue !== '') {
      getGamesFromSearch();
    }
  }

  const inputHandleChange = (selectedOption) => {
    if (selectedOption && !gameListIds.includes(selectedOption.value)){
      setGameList([...gameList, {
        'id': selectedOption.value,
        'name': selectedOption.label
      }]);

      setGameListIds([...gameListIds, selectedOption.value]);
    }
    setMenuIsOpened(false)
  };

  const getGamesFromSearch = async () => {
    const res = await fetch(`${REACT_APP_GYRE_BACKEND}/api/games?name=${gamesInputValue}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then(res => res.json());
    const newGamesArray = res.data.map((item, i) => {
      return {
        value: item.id,
        label: item.name
      };
    });
    setGamesFromSearch(newGamesArray);
  };

  const deleteGame = (game) => {
    const filterGameByGameId = (item) => {
      return item.id !== game.id;
    };

    const filterIdByGameId = (item) => {
      return item !== game.id;
    };

    setGameList(gameList.filter(filterGameByGameId));
    setGameListIds(gameListIds.filter(filterIdByGameId));
  };

  let data = queryString.stringify({
    event_name: eventNameInput.value,
    description: eventDescriptionInput.value,
    when: whenStreamInput.value,
    game_ids: gameListIds,
    about_creator: aboutYourselfInput.value,
  });

  const sendEditEventRequest = () => {
    const submitButton = document.getElementById('event-edit-button');
    submitButton.disabled = true;

    fetch(`${REACT_APP_GYRE_BACKEND}/api/event/${event.id}`, {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: data
    }).then(res => {
      if (res.ok) {
        setSubmitStatus('success');
      } else {
        setSubmitStatus('error');
      }
    });
  };

  const handleClick = e => {
    if (!formValid) {
      e.target.classList.add('event-details__send-request-button--error');
      e.target.disabled = true;
      setButtonTextCreateAnEvent(t('EventCreateModalComponent.form.fillAllFields'));

      setTimeout(() => {
        e.target.classList.remove('event-details__send-request-button--error');
        e.target.disabled = false;
        setButtonTextCreateAnEvent(t('EventCreateModalComponent.form.editEvent'));
      }, 2000);
    } else {
      sendEditEventRequest();
    }
  };

  const handleClickCloseEditModal = () => {
    toggleEditEventModal();
    toggleEventModal();
  };

  const handleClickEditRequestSent = () => {
    toggleEditEventModal();
    getEventData()
  };

  const onCopyLinkClick = () => {
    Utils.copyToClipboard(window.location.host + '/gyre-events/' + event.id);
  };


  return submitStatus === 'success' ? (
    <ConfirmCollabModal
      title={t('EventCreateModalComponent.form.ModalSuccessTitle')}
      message={t('myGyreEventsModal.ModalSuccessMessage')}
      onClick={handleClickEditRequestSent}
      goTo='/my-events'
    />
  ) : submitStatus === 'error' ? (
    <ConfirmCollabModal
      title={t('EventCreateModalComponent.form.ModalErrorTitle')}
      message={t('myGyreEventsModal.ModalErrorMessage')}
      showEmail={true}
      onClick={handleClickEditRequestSent}
      goTo='/my-events'
    />
  ) : (
    <section className='event-details'>
      <div className='event-details__background' />
      <div className='event-details__modal-wrapper'>
        <div className='event-details__modal'>
          <div className='event-details__wrapper'>
            <div className='event-details__header'>
              <h2 className='event-details__title'>{t('EventCreateModalComponent.title')}
                <button
                    className='event-details__button event-details__copy-button'
                    onClick={() => {
                      onCopyLinkClick();
                    }}>
                  <CopyIcon alt={t('copyLink')} title={t('copyLink')}  />
                </button>
              </h2>
              <button
                className='event-details__button event-details__close-button' onClick={handleClickCloseEditModal}>
                <CloseIcon />
              </button>
            </div>
            <div className='event-details__streamer-card'>
              <div className='event-details__streamer-info-about'>
                <div
                  className={`streamer-info__avatar-wrapper 
                ${
                    selectedStreamer.is_registered ? 'streamer-info__avatar-wrapper--verified' : ''
                  }`}>
                  <img
                    className='streamer-info__avatar'
                    src={selectedStreamer.picture}
                  />
                </div>
                <div className='streamer-info__nickname-wrapper'>
                  <span className='streamer-info__nickname'>
                    {selectedStreamer.twitch_username}
                  </span>
                  {selectedStreamer.is_registered ? (
                    <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                        src={require('../../assets/images/gyre-verified-icon.svg').default}
                      />
                    </span>
                  ) : null}
                </div>
                <div className='streamer-info__break' />
                <div>
                  <p>{t('EventCreateModalComponent.profileInfo')}</p>
                </div>
              </div>
            </div>
            <form>
              <div className='event-details__spoilers-container'>
                <Collapsible
                  trigger={t('EventCreateModalComponent.form.collaboration.title')}
                  classParentString='suggest-collab-spoiler'
                  className='is-closed'
                  triggerOpenedClassName={validColors(eventDescriptionInput)}
                  triggerClassName={validColors(eventDescriptionInput)}
                  openedClassName='is-open'
                  open={accordion1}>
                  <div className='event-details__input-wrapper'>
                    <input
                      type='text'
                      name='event-name'
                      id='event-name-input'
                      className={`event-details__input ${validColors(eventNameInput)} ${
                        eventNameInput.value.length > 0 ? 'filled' : ''
                      }`}
                      placeholder={t('EventCreateModalComponent.form.collaboration.placeholderName')}
                      value={eventNameInput.value}
                      autoComplete='off'
                      autoFocus
                      maxLength={MAX_LENGTH_NAME}
                      onChange={e => eventNameInput.onChange(e)}
                      onBlur={e => eventNameInput.onBlur(e)}
                    />
                    <label className='event-details__input-label' htmlFor='event-name-input'>
                      {t('EventCreateModalComponent.form.collaboration.labelName')}
                    </label>
                  </div>
                  {throwInputError(eventNameInput)}
                  <div className='event-details__input-wrapper'>
                    <span className='event-details__input-counter'>
                      {eventDescriptionInput.value.length}/{MIN_DESCRIPTION_LENGTH}
                    </span>
                    <TextareaAutosize
                      className={`event-details__input event-details__input--textarea ${validColors(
                        eventDescriptionInput
                      )} ${eventDescriptionInput.value.length > 0 ? 'filled' : ''}`}
                      name='event-description'
                      id='event-description-input'
                      placeholder={t('EventCreateModalComponent.form.collaboration.placeholderDescription')}
                      autoComplete='off'
                      maxLength={MAX_LENGTH_DESCRIPTION}
                      value={eventDescriptionInput.value}
                      onChange={e => eventDescriptionInput.onChange(e)}
                      onBlur={e => eventDescriptionInput.onBlur(e)}
                    />
                  </div>
                  {throwInputError(eventDescriptionInput)}
                  <span className='event-details__example'>
                    {t('EventCreateModalComponent.form.collaboration.example.title')}
                    <div className='event-details__private_checkbox'>
                          <input
                              className='filters__checkbox visually-hidden'
                              type='checkbox'
                              name='chkIsPrivate'
                              id='chkIsPrivate'
                              checked={event.is_private}
                              disabled={true}
                          />
                          <label className='filters__checkbox-label' htmlFor='chkIsPrivate' title={t('EventCreateModalComponent.form.collaboration.cantBeChanged')}>
                            {t('EventCreateModalComponent.form.collaboration.private')}
                          </label>
                    </div>
                  </span>
                  <p className='event-details__example-text'>
                    {t('EventCreateModalComponent.form.collaboration.example.text')}
                  </p>
                  <NextStepBtn
                    onClick={() => {
                      focusOnInput('when-stream-input');
                      setAccordion1(false);
                      setAccordion2(true);
                    }}
                  />
                </Collapsible>
                <Collapsible
                  trigger={t('EventCreateModalComponent.form.games.title')}
                  classParentString='suggest-collab-spoiler'
                  className='is-closed'
                  openedClassName='is-open is-open-overflow'
                  open={accordion2}
                  triggerOpenedClassName={validColors(whenStreamInput)}
                  triggerClassName={validColors(whenStreamInput)}>
                  <p className='event-details__description'>
                    {t('EventCreateModalComponent.form.games.subtitle')}
                  </p>
                  <div className='event-details__input-wrapper'>
                    <input
                      type="text"
                      name="when-stream"
                      id="when-stream-input"
                      className={`event-details__input ${validColors(whenStreamInput)} ${
                        whenStreamInput.value.length > 0 ? 'filled' : ''
                      }`}
                      value={whenStreamInput.value}
                      autoComplete="off"
                      maxLength={MAX_LENGTH_NAME}
                      onChange={e => whenStreamInput.onChange(e)}
                      onBlur={e => whenStreamInput.onBlur(e)}
                    />
                    <label className="event-details__input-label" htmlFor="when-stream-input">
                      {t('EventCreateModalComponent.form.games.label')}
                    </label>
                  </div>
                  {throwInputError(whenStreamInput)}
                  <p className="event-details__description">
                    {t('EventCreateModalComponent.form.games.subtitleGames')}
                  </p>
                  <div className="event-details__games-wrapper">
                    {gameList.map((game) => {
                      return (
                        <button
                          type="button"
                          onClick={() => {
                            deleteGame(game);
                          }}
                          id="delete-game-button"
                          className="event-details__games-item-button">
                          {game.name + '   ×'}
                        </button>
                      );
                    })}
                    <div className="event-details__add-game-wrapper">
                      <button
                        type="button"
                        onClick={() => {
                          setMenuIsOpened(!menuIsOpened);
                        }}
                        id="add-game-button"
                        className="event-details__add-game-button">
                        {t('EventCreateModalComponent.form.games.addGamesBtn')}
                      </button>
                      {menuIsOpened ? (
                        <div className="event-details__game-selector-popup">
                          <DimmedSelector
                            classNamePrefix={'event-details__games-input'}
                            placeholder={t('EventCreateModalComponent.form.games.gamePlaceholder')}
                            options={gamesFromSearch}
                            showOverlay={false}
                            indicatorContainers={{isDisabled: true}}
                            isSearchable={true}
                            isClearable={true}
                            backspaceRemovesValue={true}
                            menuListHeight={'192px'}
                            noOptionsMessage={() => t('EventCreateModalComponent.form.games.gameFilterDescription')}
                            inputId={'games-input-modal'}
                            autoFocus
                            onInputChange={inputHandleChangeGame}
                            onChange={selectedOption => inputHandleChange(selectedOption)}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <NextStepBtn
                    onClick={() => {
                      focusOnInput('about-yourself-input');
                      setAccordion2(false);
                      setAccordion3(true);
                    }}
                  />
                </Collapsible>
                <Collapsible
                  trigger={t('EventCreateModalComponent.form.aboutYourself.title')}
                  classParentString='suggest-collab-spoiler'
                  className='is-closed'
                  openedClassName='is-open'
                  open={accordion3}
                  triggerOpenedClassName={validColors(aboutYourselfInput)}
                  triggerClassName={validColors(aboutYourselfInput)}>
                  <div className='event-details__input-wrapper'>
                    <span className='event-details__input-counter'>
                      {eventDescriptionInput.value.length}/{MIN_DESCRIPTION_LENGTH}
                    </span>
                    <TextareaAutosize
                      className={`event-details__input event-details__input--textarea ${validColors(
                        aboutYourselfInput
                      )} ${aboutYourselfInput.value.length > 0 ? 'filled' : ''}`}
                      name='about-yourself'
                      id='about-yourself-input'
                      placeholder={t('EventCreateModalComponent.form.aboutYourself.placeholder')}
                      value={aboutYourselfInput.value}
                      autoComplete='off'
                      maxLength={MAX_LENGTH_DESCRIPTION}
                      onChange={e => aboutYourselfInput.onChange(e)}
                      onBlur={e => aboutYourselfInput.onBlur(e)}
                    />
                  </div>
                  {throwInputError(aboutYourselfInput)}
                  <span className='event-details__example'>
                    {t('EventCreateModalComponent.form.aboutYourself.example.title')}
                  </span>
                  <p className='event-details__example-text'>
                    {t('EventCreateModalComponent.form.aboutYourself.example.text')}
                  </p>
                </Collapsible>
              </div>
              <button
                type='button'
                onClick={e => handleClick(e)}
                id='event-edit-button'
                className='event-details__send-request-button'>
                {buttonTextCreateAnEvent}
              </button>
            </form>
          </div>
          <div className="event-details__indent" />
        </div>
      </div>
    </section>
  );
};

export default EditEventModal;