import {useState, useEffect} from 'react';
import EventDetailsModal from '../events/EventDetailsModal.js';
import {getAbilityToRespondForCurrentUser} from "../../utils/Utils";

const CameFrom = ({eventId, text}) => {
  const {REACT_APP_GYRE_BACKEND} = process.env;
  const [event, setEvent] = useState();
  const [loading, setLoading] = useState(true);
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [abilityToRespondToAnEvent, setAbilityToRespondToAnEvent] = useState(false);

  const getEvent = async (eventId) => {
    const eventData = await fetch(`${REACT_APP_GYRE_BACKEND}/api/event/${eventId}`, {
      method: 'GET', credentials: 'include', mode: 'cors', headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then(res => res.json());

    eventData.event.streamer = eventData.author;
    setEvent(eventData.event);
    setLoading(false);
  };

  useEffect(() => {
    getEvent(eventId);
  }, []);

  const toggleEventDetails = e => {
    setSelectedEvent(e);
    setShowEventDetailsModal(!showEventDetailsModal);
  };

  useEffect(() => {
    getAbilityToRespondForCurrentUser(selectedEvent, setAbilityToRespondToAnEvent);
  }, [selectedEvent]);

  return (
    <>
      {!loading
        ? <div className='chat-window__came-from'>
          {text}
          <span className='chat-window__came-from-link' onClick={() => toggleEventDetails(event)}>{event.event_name}</span>
        </div>
        : null}
      {showEventDetailsModal
        ? (<EventDetailsModal
          onCloseClick={toggleEventDetails}
          event={selectedEvent}
          abilityToRespondToAnEvent={abilityToRespondToAnEvent}
          showEventDetailsModal={showEventDetailsModal}
          setShowEventDetailsModal={setShowEventDetailsModal}
        />)
        : null}
    </>
  );
};

export default CameFrom;