import ObservableProperty from "../store/ObservableProperty";

const {REACT_APP_GYRE_WS_CHAT} = process.env;
const {REACT_APP_GYRE_BACKEND} = process.env;

let wsChat;
const callbacks = {};
const openCallbacks = [];
let isReady = false;
let retries = 0;
const MAX_RETRIES = 3;

const openChat = () => {
    wsChat = new WebSocket(`${REACT_APP_GYRE_WS_CHAT}`);
    wsChat.addEventListener('open', () => {
        isReady = true;
        wsChat.addEventListener('message', onNewMessageHandler);
        for (const callback of openCallbacks) {
            callback(wsChat);
        }
        retries = 0;
        console.log('wsChat is ready');
    });

    wsChat.addEventListener('close', () => {
        console.log('wsChat is closed');
    });

    wsChat.addEventListener('error', (e) => {
        console.log('wsChat error', e);
        if (retries < MAX_RETRIES) {
            retries++;
            console.log('wsChat open failed, retrying...');
            openChat();
        }
    });
}

const onNewMessageHandler = (e) => {
    let newMessage = JSON.parse(e.data);
    for (const callback in callbacks) {
        callbacks[callback](newMessage);
    }
}

class ChatService {
    static onNewMessage(id, onNewMessage) {
        callbacks[id] = onNewMessage;
    }

    static onOpen(callback) {
        if (isReady) {
            callback();
        } else {
            openCallbacks.push(callback);
            if (!wsChat) {
                openChat()
            }
        }
    }

    static send(message) {
        if (isReady) {
            wsChat.send(JSON.stringify(message));
            return true;
        } else {
            return false;
        }
    }

    static unreadChatMessages = new ObservableProperty(false);
    
    static getChats = async () => {
        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/chat`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then(res => res.json())
            .catch(error => {
                console.log(error);
            });
    }
    
    static getChatHistory = async (streamerId) => {
        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/chat/${streamerId}/history`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }).then(res => res.json())
            .catch(error => console.log(error))
    }
    
    static markChatAsRead = (chatId) => {
        return fetch(`${REACT_APP_GYRE_BACKEND}/api/chat/${chatId}/history/mark_as_read`, {
            method: 'PATCH',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(error => {
            console.log(error);
        });
    }

    static createNewChat = (me, participant) => {
        return {
            streamer: participant,
            receiver_id: participant.id,
            sender_id: me.id,
            receiver_already_read: true,
            members: [participant.id, me.id]
        };
    };
}

export default ChatService;