import Collapsible from 'react-collapsible';
import TextareaAutosize from 'react-textarea-autosize';
import NextStepBtn from '../find-collab/NextStepBtn';
import {useState, useEffect, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg';
import ConfirmCollabModal from '../ConfirmCollabModal';
import DimmedSelector from '../selectors/DimmedSelector';
import {SubmitStatuses} from '../../utils/Collections';
import AppContext from '../../store/AppContext';
import useInput from '../../utils/Input';
import EventsService from '../../services/EventsService';

const queryString = require('query-string');

const EventCreateModal = ({onCloseClick}) => {
  const context = useContext(AppContext);

  const throwInputError = input => {
    if (input.isDirty && input.isEmpty) {
      return (<span className='event-details__error-message'>
          {t('EventCreateModalComponent.form.emptyFieldError')}
        </span>);
    } else if (input.isDirty && input.minLengthError) {
      return (<span className='event-details__error-message'>
          {t('EventCreateModalComponent.form.charactersError')}
        </span>);
    }
  };

  const [accordion1, setAccordion1] = useState(true);
  const [accordion2, setAccordion2] = useState(false);
  const [accordion3, setAccordion3] = useState(false);
  const MAX_LENGTH_DESCRIPTION = 800;
  const MAX_LENGTH_NAME = 75;
  const MIN_DESCRIPTION_LENGTH = 30;
  const [eventLimits, setEventLimits] = useState(null);

  const eventNameInput = useInput('', {
    isEmpty: true,
    minLength: 5
  });
  const eventDescriptionInput = useInput('', {
    isEmpty: true,
    minLength: MIN_DESCRIPTION_LENGTH
  });
  const whenStreamInput = useInput('', {
    isEmpty: true,
    minLength: 1
  });
  const aboutYourselfInput = useInput('', {
    isEmpty: true,
    minLength: MIN_DESCRIPTION_LENGTH
  });

  const validColors = input => {
    if (input.isDirty && input.minLengthError) {
      return SubmitStatuses.Error;
    } else if (input.isDirty && input.minLengthError === false) {
      return SubmitStatuses.Success;
    }
  };

  const focusOnInput = id => {
    document.getElementById(id).focus();
  };

  const {t} = useTranslation();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    EventsService.getMyEventsLimit().then(
      data => {
        setEventLimits(data);
      }
    );
  }, []);

  useEffect(() => {
    if (eventDescriptionInput.minLengthError || whenStreamInput.minLengthError || aboutYourselfInput.minLengthError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [eventDescriptionInput, whenStreamInput, aboutYourselfInput]);

  const [buttonTextCreateAnEvent, setButtonTextCreateAnEvent] = useState(t('EventCreateModalComponent.form.createAnEvent'));
  const [submitStatus, setSubmitStatus] = useState();
  const {REACT_APP_GYRE_BACKEND} = process.env;

  const [addGamesButtonText] = useState(t('EventCreateModalComponent.form.games.addGamesBtn'));
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const [gamesList, setGamesList] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [gameListIds, setGameListIds] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  let gamesInputValue = '';

  const inputHandleChangeGame = (event) => {
    gamesInputValue = event;
    if (gamesInputValue !== '') {
      getGamesList(gamesInputValue);
    }
  };

  const inputHandleChange = (selectedOption) => {
    if (selectedOption && !gameListIds.includes(selectedOption.value)) {
      setGameList([...gameList, {
        'id': selectedOption.value,
        'name': selectedOption.label
      }]);

      setGameListIds([...gameListIds, selectedOption.value]);
    }
    setMenuIsOpened(false);
  };

  const getGamesList = async (names) => {
    const res = await fetch(`${REACT_APP_GYRE_BACKEND}/api/games?name=${names}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then(res => res.json());
    const newGamesArray = res.data.map((item, i) => {
      return {
        value: item.id,
        label: item.name
      };
    });
    setGamesList(newGamesArray);
  };

  const deleteGame = (game) => {
    const filterGameByGameId = (item) => {
      return item.id !== game.id;
    };

    const filterIdByGameId = (item) => {
      return item !== game.id;
    };

    setGameList(gameList.filter(filterGameByGameId));
    setGameListIds(gameListIds.filter(filterIdByGameId));
  };

  let data = queryString.stringify({
    event_name: eventNameInput.value,
    description: eventDescriptionInput.value,
    when: whenStreamInput.value,
    game_ids: gameListIds,
    about_creator: aboutYourselfInput.value,
    is_private: isPrivate
  });

  const sendEventRequest = () => {
    const submitButton = document.getElementById('event-submit-button');
    submitButton.disabled = true;

    fetch(`${REACT_APP_GYRE_BACKEND}/api/event`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: data
    }).then(res => {
      if (res.ok) {
        setSubmitStatus(SubmitStatuses.Success);
      } else {
        setSubmitStatus(SubmitStatuses.Error);
      }
    });
  };

  const handleClick = e => {
    if (!formValid) {
      e.target.classList.add('event-details__send-request-button--error');
      e.target.disabled = true;
      setButtonTextCreateAnEvent(t('EventCreateModalComponent.form.fillAllFields'));

      setTimeout(() => {
        e.target.classList.remove('event-details__send-request-button--error');
        e.target.disabled = false;
        setButtonTextCreateAnEvent(t('EventCreateModalComponent.form.createAnEvent'));
      }, 2000);
    } else {
      sendEventRequest();
    }
  };


  return submitStatus === SubmitStatuses.Success
    ? (
      <ConfirmCollabModal
        title={t('EventCreateModalComponent.form.ModalSuccessTitle')}
        message={t('EventCreateModalComponent.form.ModalSuccessMessage')}
        onClick={() => {
          onCloseClick(true);
        }}
        goTo={window.location.pathname}
      />
    ) : submitStatus === SubmitStatuses.Error
      ? (
        <ConfirmCollabModal
          title={t('EventCreateModalComponent.form.ModalErrorTitle')}
          message={t('EventCreateModalComponent.form.ModalErrorMessage')}
          showEmail={true}
          onClick={onCloseClick}
          goTo={window.location.pathname}
        />
      ) : (
        <section className='event-details'>
          <div className='event-details__background' />
          <div className='event-details__modal-wrapper'>
            <div className='event-details__modal'>
              <div className='event-details__wrapper'>
                <div className='event-details__header'>
                  <h2 className='event-details__title'>
                    {t('EventCreateModalComponent.title')} {eventLimits && ('(' + eventLimits.active + '/' + eventLimits.limit) + ')'}
                  </h2>
                  <button
                    className='event-details__button event-details__close-button' onClick={onCloseClick}>
                    <CloseIcon />
                  </button>
                </div>
                <div className='event-details__streamer-card'>
                  <div className='event-details__streamer-info-about'>
                    <div
                      className='streamer-info__avatar-wrapper streamer-info__avatar-wrapper--verified'>
                      <img
                        className='streamer-info__avatar'
                        src={context.user.twitch_id_token.picture}
                      />
                    </div>
                    <div className='streamer-info__nickname-wrapper'>
                  <span className='streamer-info__nickname'>
                   {context.user.twitch_id_token.preferred_username}
                  </span>
                      <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                        src={require('../../assets/images/gyre-verified-icon.svg').default}
                      />
                    </span>
                    </div>
                    <div className='streamer-info__break' />
                    <div className='streamer-info__profile-info'>
                      {!eventLimits || eventLimits.available > 0 ? (
                        t('EventCreateModalComponent.profileInfo')
                      ) : (
                        t('EventCreateModalComponent.profileInfoLimitReached')
                      )}
                    </div>
                  </div>
                </div>

                {eventLimits && eventLimits.available <= 0 ? null :
                  (
                    <form>
                      <div className='event-details__spoilers-container'>
                        <Collapsible
                          trigger={t('EventCreateModalComponent.form.collaboration.title')}
                          classParentString='suggest-collab-spoiler'
                          className='is-closed'
                          triggerOpenedClassName={validColors(eventDescriptionInput)}
                          triggerClassName={validColors(eventDescriptionInput)}
                          openedClassName='is-open'
                          open={accordion1}>
                          <div className='event-details__input-wrapper'>
                            <input
                              type='text'
                              name='event-name'
                              id='event-name-input'
                              className={`event-details__input ${validColors(eventNameInput)} ${eventNameInput.value.length > 0
                                ? 'filled'
                                : ''}`}
                              placeholder={t('EventCreateModalComponent.form.collaboration.placeholderName')}
                              value={eventNameInput.value}
                              autoComplete='off'
                              autoFocus
                              maxLength={MAX_LENGTH_NAME}
                              onChange={e => eventNameInput.onChange(e)}
                              onBlur={e => eventNameInput.onBlur(e)}
                            />
                            <label className='event-details__input-label' htmlFor='event-name-input'>
                              {t('EventCreateModalComponent.form.collaboration.labelName')}
                            </label>
                          </div>
                          <div className='event-details__input-wrapper'>
                    <span className='event-details__input-counter'>
                      {eventDescriptionInput.value.length}/{MIN_DESCRIPTION_LENGTH}
                    </span>
                            <TextareaAutosize
                              className={`event-details__input event-details__input--textarea ${validColors(eventDescriptionInput)} ${eventDescriptionInput.value.length > 0
                                ? 'filled'
                                : ''}`}
                              name='event-description'
                              id='event-description-input'
                              placeholder={t('EventCreateModalComponent.form.collaboration.placeholderDescription')}
                              autoComplete='off'
                              maxLength={MAX_LENGTH_DESCRIPTION}
                              value={eventDescriptionInput.value}
                              onChange={e => eventDescriptionInput.onChange(e)}
                              onBlur={e => eventDescriptionInput.onBlur(e)}
                            />
                          </div>
                          {throwInputError(eventDescriptionInput)}
                          <span className='event-details__example'>
                        {t('EventCreateModalComponent.form.collaboration.example.title')}
                            <div className='event-details__private_checkbox'>
                          <input
                            className='filters__checkbox visually-hidden'
                            type='checkbox'
                            name='chkIsPrivate'
                            id='chkIsPrivate'
                            onChange={e => setIsPrivate(e.target.checked)}
                          />
                          <label className='filters__checkbox-label' htmlFor='chkIsPrivate'
                                 title={t('EventCreateModalComponent.form.collaboration.privateHint')}>
                            {t('EventCreateModalComponent.form.collaboration.private')}
                          </label>
                        </div>
                      </span>
                          <p className='event-details__example-text'>
                            {t('EventCreateModalComponent.form.collaboration.example.text')}
                          </p>
                          <NextStepBtn
                            onClick={() => {
                              focusOnInput('when-stream-input');
                              setAccordion1(false);
                              setAccordion2(true);
                            }}
                          />
                        </Collapsible>
                        <Collapsible
                          trigger={t('EventCreateModalComponent.form.games.title')}
                          classParentString='suggest-collab-spoiler'
                          className='is-closed'
                          openedClassName='is-open is-open-overflow'
                          open={accordion2}
                          triggerOpenedClassName={validColors(whenStreamInput)}
                          triggerClassName={validColors(whenStreamInput)}>
                          <p className='event-details__description'>
                            {t('EventCreateModalComponent.form.games.subtitle')}
                          </p>
                          <div className='event-details__input-wrapper'>
                            <input
                              type='text'
                              name='when-stream'
                              id='when-stream-input'
                              className={`event-details__input ${validColors(whenStreamInput)} ${whenStreamInput.value.length > 0
                                ? 'filled'
                                : ''}`}
                              value={whenStreamInput.value}
                              autoComplete='off'
                              maxLength={MAX_LENGTH_NAME}
                              onChange={e => whenStreamInput.onChange(e)}
                              onBlur={e => whenStreamInput.onBlur(e)}
                            />
                            <label className='event-details__input-label' htmlFor='when-stream-input'>
                              {t('EventCreateModalComponent.form.games.label')}
                            </label>
                          </div>
                          <p className='event-details__description'>
                            {t('EventCreateModalComponent.form.games.subtitleGames')}
                          </p>
                          <div className='event-details__games-wrapper'>
                            {gameList.map((game) => {
                              return (<button
                                type='button'
                                onClick={() => {
                                  deleteGame(game);
                                }}
                                id='delete-game-button'
                                className='event-details__games-item-button'>
                                {game.name + '   ×'}
                              </button>);
                            })}
                            <div className='event-details__add-game-wrapper'>
                              <button
                                type='button'
                                onClick={() => {
                                  setMenuIsOpened(!menuIsOpened);
                                }}
                                id='add-game-button'
                                className='event-details__add-game-button'>
                                {addGamesButtonText}
                              </button>
                              {menuIsOpened ? (
                                <div className='event-details__game-selector-popup'>
                                  <DimmedSelector
                                    classNamePrefix={'event-details__games-input'}
                                    placeholder={t('EventCreateModalComponent.form.games.gamePlaceholder')}
                                    options={gamesList}
                                    showOverlay={false}
                                    indicatorContainers={{isDisabled: true}}
                                    isSearchable={true}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                    menuListHeight={'192px'}
                                    noOptionsMessage={() => t('EventCreateModalComponent.form.games.gameFilterDescription')}
                                    inputId={'games-input-modal'}
                                    autoFocus
                                    onInputChange={inputHandleChangeGame}
                                    onChange={selectedOption => inputHandleChange(selectedOption)}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <NextStepBtn
                            onClick={() => {
                              focusOnInput('about-yourself-input');
                              setAccordion2(false);
                              setAccordion3(true);
                            }}
                          />
                        </Collapsible>
                        <Collapsible
                          trigger={t('EventCreateModalComponent.form.aboutYourself.title')}
                          classParentString='suggest-collab-spoiler'
                          className='is-closed'
                          openedClassName='is-open'
                          open={accordion3}
                          triggerOpenedClassName={validColors(aboutYourselfInput)}
                          triggerClassName={validColors(aboutYourselfInput)}>
                          <div className='event-details__input-wrapper'>
                            <span className='event-details__input-counter'>
                              {aboutYourselfInput.value.length}/{MIN_DESCRIPTION_LENGTH}
                            </span>
                            <TextareaAutosize
                              className={`event-details__input event-details__input--textarea ${validColors(aboutYourselfInput)} ${aboutYourselfInput.value.length > 0
                                ? 'filled'
                                : ''}`}
                              name='about-yourself'
                              id='about-yourself-input'
                              placeholder={t('EventCreateModalComponent.form.aboutYourself.placeholder')}
                              value={aboutYourselfInput.value}
                              autoComplete='off'
                              maxLength={MAX_LENGTH_DESCRIPTION}
                              onChange={e => aboutYourselfInput.onChange(e)}
                              onBlur={e => aboutYourselfInput.onBlur(e)}
                            />
                          </div>
                          {throwInputError(aboutYourselfInput)}
                          <span className='event-details__example'>
                    {t('EventCreateModalComponent.form.aboutYourself.example.title')}
                  </span>
                          <p className='event-details__example-text'>
                            {t('EventCreateModalComponent.form.aboutYourself.example.text')}
                          </p>
                        </Collapsible>
                      </div>
                      <button
                        type='button'
                        onClick={e => handleClick(e)}
                        id='event-submit-button'
                        className='event-details__send-request-button'>
                        {buttonTextCreateAnEvent}
                      </button>
                    </form>
                  )}
              </div>
              <div className='event-details__indent' />
            </div>
          </div>
        </section>);
};

export default EventCreateModal;