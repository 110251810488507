import {useEffect, useState, useRef, useContext} from 'react';
import CollaborateNowBtn from '../CollaborateNowBtn';
import SignUpButton from '../SignUpButton';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import SiteLanguageSelector from './SiteLanguageSelector';
import {ReactComponent as ArrowBottomIcon} from '../../assets/images/arrow-bottom-icon.svg';
import ChatService from "../../services/ChatService";
import AppContext from "../../store/AppContext";

const UserBar = ({
  openSignUpModal
}) => {
  const {REACT_APP_GYRE_BACKEND} = process.env;
  const [menuIsOpened, setMenuIsOpened] = useState(false);
  const context = useContext(AppContext);

  const useOutsideAlerter = ref => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuIsOpened(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const {t} = useTranslation();

  const [hasUnreadChatMessages, setHasUnreadChatMessages] = useState(ChatService.unreadChatMessages.get());
  useEffect(() => {
    ChatService.unreadChatMessages.onValueChanged().subscribe(setHasUnreadChatMessages);
  });
  
  return (
    <div className='page-header__userbar-wrapper'>
      <div className='page-header__userbar'>
        <a className='navigation-bar__link' href='https://blog.gyre.tv' rel='noreferrer' target='_blank'>
          {t('landingPage.navigation.blog')}
        </a>
        <SiteLanguageSelector className='page-header__language-selector' />
        {context.isAuthenticated && context.user ? (
          <>
            <Link className='page-header__messages' to='/chat' aria-label='Messages' >
              {hasUnreadChatMessages &&
                <div className='main__status-wrapper'>
                  <span className='main__status main__status--green' />
                </div>
              }
            </Link>
            <div className='page-header__userbar-action' ref={wrapperRef}>
              <div
                className={`page-header__userbar-container ${
                  menuIsOpened
                    ? 'page-header__userbar-container--opened'
                    : 'page-header__userbar-container--closed'
                }`}
                onClick={() => {
                  setMenuIsOpened(!menuIsOpened);
                }}>
                <span className='page-header__user-name'>
                  {context.user.twitch_id_token.preferred_username}
                </span>
                <div className='page-header__avatar-wrapper'>
                  <img className='page-header__avatar' src={context.user.twitch_id_token.picture} alt='' />
                </div>
                <ArrowBottomIcon className='page-header__userbar-arrow' />
              </div>
              {menuIsOpened ? (
                <div className='page-header__user-menu'>
                  <ul
                    className='page-header__user-menu-list'
                    onClick={() => {
                      setMenuIsOpened(false);
                    }}>
                    <li className='page-header__user-menu-item page-header__user-menu-item--settings'>
                      <a
                        className='page-header__user-menu-link page-header__user-menu-link--settings'
                        href={'/user-settings'}>
                        {t('header.settings')}
                      </a>
                    </li>
                    <li className='page-header__user-menu-item page-header__user-menu-item--support'>
                      <a
                          className='page-header__user-menu-link page-header__user-menu-link--support'
                          href={'/support'}>
                        {t('header.support')}
                      </a>
                    </li>
                    <li className='page-header__user-menu-item page-header__user-menu-item--logout'>
                      <a
                        className='page-header__user-menu-link page-header__user-menu-link--logout'
                        onClick={() => sessionStorage.clear()}
                        href={`${REACT_APP_GYRE_BACKEND}/api/logout`}>
                        {t('header.logout')}
                      </a>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <SignUpButton openSignUpModal={openSignUpModal} classNames='page-header__sign-up-btn' />
            {window.location.pathname === '/' || window.location.pathname === '/privacy-policy' ? (
              <CollaborateNowBtn classNames='page-header__collaborate-btn' />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default UserBar;
