import {ReactComponent as CompletionIcon} from '../../assets/images/completion-icon.svg';
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';

const ResponseButtons = ({
  collabStatus,
  collabStatuses,
  setCollabStatus,
  collabId,
  setNewStatus,
  showConfirmModal
}) => {
  const {t} = useTranslation();
  const {REACT_APP_GYRE_BACKEND} = process.env;

  const updateCollabStatus = status => {
    setCollabStatus(status);
    fetch(`${REACT_APP_GYRE_BACKEND}/api/collab_request?id=${collabId}&status=${status}`, {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
      .then(res => {
        if (res.ok) {
          showConfirmModal();
          setCollabStatus(status);
          setNewStatus(status);
        }
      })
      .catch(() => {
        showConfirmModal();
        setNewStatus('error');
      });
  };

  const defineNewStatus = () => {
    if (collabStatus === collabStatuses.pending) {
      return collabStatuses.accepted;
    } else if (collabStatus === collabStatuses.accepted) {
      return collabStatuses.completed;
    }
  };

  return (
    <>
      <button
        className='my-collab-requests__response-button my-collab-requests__completion-button'
        onClick={() => updateCollabStatus(defineNewStatus())}>
        <CompletionIcon />
        {collabStatus === collabStatuses.pending
          ? t('collaborationRequestPage.buttons.accept')
          : t('myCollabRequestsPage.markCompletedRequestButton')}
      </button>
      <button
        className='my-collab-requests__response-button my-collab-requests__cancel-button'
        onClick={() => updateCollabStatus(collabStatuses.rejected)}>
        <CloseIcon />
        {collabStatus === collabStatuses.pending
          ? t('collaborationRequestPage.buttons.reject')
          : t('myCollabRequestsPage.markCanceledRequestButton')}
      </button>
    </>
  );
};

export default ResponseButtons;
