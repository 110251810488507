import {Link} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {ReactComponent as SendIcon} from '../../assets/images/send-message-icon.svg';
import ChatInfo from './ChatInfo.js';
import 'moment/locale/ru.js';
import {useTranslation} from 'react-i18next';
import ReceivedMessage from './ReceivedMessage.js';
import SentMessage from './SentMessage.js';
import SettingsService from '../../services/SettingService';
import CameFrom from './CameFrom.js';
import {useWindowSize} from "../../utils/Utils";
import ChatService from "../../services/ChatService";

const moment = require('moment');

const ChatWindow = ({userId, chatData, chat, setSentMessageText, onSendMessage}) => {
  const {t, i18n} = useTranslation();
  const [width] = useWindowSize();
  const [showInfo, setShowInfo] = useState(false);
  const messagesEndRef = useRef(null);
  const [isAutoScroll, setIsAutoScroll] = useState(true);
  
  const [chatHistory, setChatHistory] = useState();

  const [loading, setLoading] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [message, setMessage] = useState('');
  const [newMessages, setNewMessages] = useState([]);
  const [isChatReady, setIsChatReady] = useState(false);

  useEffect(() => {
    if (width >= 1280) {
      setShowInfo(true);
    } else if (width < 1280) {
      setShowInfo(false);
    }
  }, [width]);

  const scrollHandler = e => {
    let element = e.currentTarget;
    if (Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 200) {
      !isAutoScroll && setIsAutoScroll(true);
    } else {
      isAutoScroll && setIsAutoScroll(false);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current && isAutoScroll) {
      messagesEndRef.current.scrollIntoView();
    }
  }, [loadingMessages, newMessages]);

  useEffect(() => {
    if (chat) {
      setLoading(false);
      ChatService.onOpen(() => {
        setIsChatReady(true);
      });
      ChatService.onNewMessage( "ChatWindow", onNewMessage);
    }
  }, [chat]);

  const getChatHistory = async () => {
    if (!chat) return;
    
    setLoadingMessages(true);
    const chatHistoryData = await ChatService.getChatHistory(chat.streamer.id);

    setChatHistory(chatHistoryData.messages);
    setNewMessages([]);
  };

  useEffect(() => {
    getChatHistory().then(() => setLoadingMessages(false));
  }, [chat]);

  const renderMessageList = () => {
    return chatHistory
      .slice(0)
      .reverse()
      .map((message) => {
        if (message.receiver_id === userId) {
          if (message.content.includes('#gyre:incoming_event:')) {
            const eventId = message.content.substring(21);
            return <CameFrom eventId={eventId} text={t('ChatsPage.cameFrom')} userId={chat.streamer.id} key={message.id} />;
          }
          return (
            <ReceivedMessage
              avatar={chat.streamer.picture}
              message={message.content}
              key={message.id}
              date={moment
                .utc(message.sent_at)
                .tz(SettingsService.getUserTimezone())
                .locale(i18n.language)
                .format('LLL')}
            />);
        } else if (message.sender_id === userId) {
          if (message.content.includes('#gyre:incoming_event:')) {
            const eventId = message.content.substring(21);
            return <CameFrom eventId={eventId} text={t('ChatsPage.youResponsed')} userId={chat.streamer.id} key={message.id}/>;
          } else {
            return (<SentMessage
              message={message.content}
              key={message.id}
              date={moment
                .utc(message.sent_at)
                .tz(SettingsService.getUserTimezone())
                .locale(i18n.language)
                .format('LLL')}
            />);
          }
        }
      });
  };

  const sendMessage = () => {
    if (!message) {
      return;
    }
    
    const res = ChatService.send(
      {
        message_content: message, 
        receiver_id: chat.streamer.id
      }
    );
    if (!res) return;
    
    setSentMessageText({
      receiver: chat.streamer.id, message: message
    });

    onSendMessage(chat);
    
    setNewMessages(prevMessages => [...prevMessages, <SentMessage
      message={message}
      key={prevMessages.length}
      date={moment().tz(SettingsService.getUserTimezone()).locale(i18n.language).format('LLL')}
    />]);
    setMessage('');
  };
  
  const onNewMessage = (newMessage) => {
    const message = processNewMessage(newMessage);
    if (message)
      setNewMessages(prevMessages => {
        return [...prevMessages, message];
      });
  };
  
  const processNewMessage = (newMessage) => {
    if (newMessage.sender_id === chat.streamer.id && newMessage.receiver_id === userId) {
      return <ReceivedMessage
          avatar={chat.streamer.picture}
          key={newMessage.id}
          message={newMessage.content}
          date={moment
              .utc(newMessage.sent_at)
              .tz(SettingsService.getUserTimezone())
              .locale(i18n.language)
              .format('LLL')}
      />;
    }
    return null;
  }

  const submitOnEnter = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage();
    }
  };

  return !loading ? (<>
    <section className='chat-window'>
      <div className='chat-window__container'>
        <div className='chat-window__header'>
          {width < 768 ? <Link className='chat-window__go-back-btn' to='/chat' /> : null}
          <div className='chat-window__avatar-and-status'>
            <div className='chat-window__avatar-wrapper'>
              <img className='chat-window__avatar' src={chat.streamer.picture} alt='' />
            </div>
            <div className='chat-window__streamer-status-wrapper'>
              <span className='chat-window__streamer-status chat-window__streamer-status--offline' />
            </div>
          </div>
          <div className='chat-window__receiver-nickname-wrapper'>
            <h3 className='chat-window__receiver-nickname'>{chat.streamer.twitch_username}</h3>
            <span className='chat-window__receiver-status'>{t('ChatsPage.offlineStatus')}</span>
          </div>
          {width < 1280
            ? (<button
              className='chat-window__about-streamer-button'
              onClick={() => setShowInfo(true)}
            />)
            : null}
        </div>
        <div className='chat-window__messages-container'>
          <div className='chat-window__messages-wrapper' onScroll={e => scrollHandler(e)}>
            {!loadingMessages && chatHistory ? renderMessageList() : null}
            {newMessages}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <div className='chat-window__message-input-wrapper'>
          <TextareaAutosize
            className='chat-window__send-message-input'
            name='message'
            id='message-input'
            placeholder={t('ChatsPage.inputPlaceholder')}
            onChange={e => setMessage(e.currentTarget.value)}
            value={message}
            onKeyDown={e => submitOnEnter(e)}
            autoComplete='off'
          />
          <button
            className='chat-window__send-message-button'
            aria-label='Send message'
            disabled={!isChatReady}
            onClick={sendMessage}>
            <SendIcon width='32' height='32' />
          </button>
        </div>
      </div>
    </section>
    {showInfo
      ? (
        <ChatInfo setShowInfo={setShowInfo} streamerInfo={chat.streamer}  />)
      : null}
  </>) : null;
};

export default ChatWindow;
