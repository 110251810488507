import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import ChatList from '../components/chats/ChatList.js';
import ChatWindow from '../components/chats/ChatWindow.js';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useWindowSize} from "../utils/Utils";
import StreamersService from "../services/StreamersService";
import AppContext from "../store/AppContext";
import ChatService from "../services/ChatService";

const ChatsPage = () => {
  const [width] = useWindowSize();
  const {chatId} = useParams();
  const {t} = useTranslation();

  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [chatData, setChatData] = useState({});
  const [selectedChat, setSelectedChat] = useState(null);
  
  const [sentMessageText, setSentMessageText] = useState({
    receiver: null,
    message: null
  });

  const fetchData = async () => {
    const streamersIds = [];
    const streamersList = [];
    const mergeData = {
      chats: [],
      games: [],
      timezones: {}
    };
    
    const chatListData = await ChatService.getChats();
    
    chatListData.chats.forEach(item => {
      if (item.sender_id !== context.user.id) {
        streamersIds.push(item.sender_id);
      } else if (item.receiver_id !== context.user.id) {
        streamersIds.push(item.receiver_id);
      }
    });
    
    if (streamersIds.length > 0)  {
      // only fetch streamers data if there are any streamers in chat list
      const streamersInfoData = await StreamersService.getStreamers(
          {
            streamer_ids: streamersIds,
            active_only: false,
            valid_only: false
          }
      ).catch(error => {
        console.log(error);
      });

      streamersList.push(...streamersInfoData.streamers);
    }

    await chatListData.chats.forEach(chat => {
      chat.members.forEach(member => {
        streamersList.forEach(streamer => {
          if (member === streamer.id) {
            chat.streamer = streamer;
            mergeData.chats.push(chat);
          }
        });
      });
    });
    
    setChatData(mergeData);
    checkChatsStatus(mergeData.chats);
    setLoading(false);
    
  };
  
  const checkChatsStatus = (chats) => {
    let hasUnread = false;
    for (const item of chats) {
      if (!item.receiver_already_read && item.receiver_id === context.user.id) {
        hasUnread = true;
        break;
      }
    }
  
    ChatService.unreadChatMessages.set(hasUnread);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChatSelected = (chat) => {
    setSelectedChat(chat);
    markAsRead(chat);
  }
  
  const markAsRead = (chat, force= false) => {
    if ( (context.user.id === chat.receiver_id && !chat.receiver_already_read)
        || force) {
      ChatService.markChatAsRead(chat.streamer.id);
      chat.receiver_already_read = true;
    }

    checkChatsStatus(chatData.chats);
  };
  
  const onSendMessage = (chat) => {
    markAsRead(chat, true);
  }

  const showComponents = () => {
    if (loading !== true) {
      if (width < 768) {
        if (chatId) {
          return (
            <ChatWindow
              chatData={chatData}
              userId={context.user.id}
              chat={selectedChat}
              setSentMessageText={setSentMessageText}
              onSendMessage={onSendMessage}
            />
          );
        } else {
          return (
            <ChatList
              chatData={chatData}
              sentMessageText={sentMessageText}
              onChatSelected={onChatSelected}
            />
          );
        }
      } else if (width >= 768) {
        if (chatId) {
          return (
            <>
              <ChatList
                chatData={chatData}
                sentMessageText={sentMessageText}
                onChatSelected={onChatSelected}
              />
              <ChatWindow
                chatData={chatData}
                userId={context.user.id}
                chat={selectedChat}
                setSentMessageText={setSentMessageText}
                onSendMessage={onSendMessage}
              />
            </>
          );
        } else {
          return (
            <>
              <ChatList
                chatData={chatData}
                sentMessageText={sentMessageText}
                onChatSelected={onChatSelected}
              />
              <div className='chat-window'>
                <div className='chat-window__container chat-window__container--empty'>
                  <p className='chat-window__no-active-chats'>{t('ChatsPage.noActiveChats')}</p>
                </div>
              </div>
              {width >= 1280 ? (
                <div className='chat-info'>
                  <div className='chat-info__container chat-info__container--empty'>
                    <div className='chat-info__smile-wrapper'>
                      <img
                        src={require('../assets/images/smile-icon.svg').default}
                        alt=''
                        className='chat-info__streamer-avatar'
                      />
                    </div>
                    <p className='chat-info__message'>{t('ChatsPage.noInformation')}</p>
                    <Link to='/find-collab' className='primary-button'>
                      {t('ChatsPage.findPartner')}
                    </Link>
                  </div>
                </div>
              ) : null}
            </>
          );
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Chats</title>
      </Helmet>
      <div className='chat-page'>{showComponents()}</div>
    </>
  );
};

export default ChatsPage;
