/** @jsxImportSource @emotion/react */
import {components} from 'react-select';
import GeneralSelector from './GeneralSelector';

const SingleValueWithIcon = ({data, cx, getStyles, className, isDisabled, ...props}) => (
  <div
    css={getStyles('singleValue', props)}
    className={cx(
      {
        'single-value': true,
        'single-value--is-disabled': isDisabled
      },
      className
    )}>
    {data.icon && <span className='selector-with-icons__icon'>{data.icon}</span>}
    <span className='selector-with-icons__text'>{data.label}</span>
  </div>
);

const IconOption = props => (
  <components.Option {...props}>
    {props.data.icon && <span className='selector-with-icons__icon'>{props.data.icon}</span>}
    <span className='selector-with-icons__text'>{props.data.label}</span>
  </components.Option>
);

const SelectorWithIcons = ({...props}) => {
  const selectorStyles = {
    option: styles => {
      return {
        ...styles,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '5px',
        padding: '10px 24px',

        '.selector-with-icons__icon': {
          margin: '0 10px'
        }
      };
    },
    valueContainer: styles => ({
      ...styles,
      padding: 0
    }),
    singleValue: styles => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '.selector-with-icons__icon': {
        margin: '0 10px'
      }
    })
  };

  return (
    <GeneralSelector
      components={{SingleValue: SingleValueWithIcon, Option: IconOption}}
      classNamePrefix='selector-with-icons'
      styles={selectorStyles}
      {...props}
    />
  );
};

export default SelectorWithIcons;
