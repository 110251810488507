import DimmedSelector from '../selectors/DimmedSelector';
import {useEffect} from 'react';

const Sorting = ({
  userName,
  setSortingMethod,
  sortingMethod,
  setUserName,
  sortingOptions
}) => {
  
  useEffect(() => {
    document.querySelectorAll('.sorting__input').forEach(item => {
      item.addEventListener('change', event => {
        const target = event.target;
        if (target.value) {
          target.classList.add('filled');
        } else {
          target.classList.remove('filled');
        }
      });
    });
    
  }, []);
  
  const handleChange = selectedOption => {
    setSortingMethod(selectedOption.value);
  };

  const handleUserNameChange = e => {
    const name = e.target.value.replace(/[`~!@#$%^&*()|+\-=?;:'",.<>{}\[\]\\\/ ]/gi, "");
    setUserName(name);
  };

  const getSelectedOption = () => {
    return sortingOptions.find(i => i.value == sortingMethod) || sortingOptions[0];
  };

  return (
    <div className='sorting'>
      <div className='sorting__wrapper'>
        <DimmedSelector
          customDesktopWidth={'305px'}
          defaultValue={getSelectedOption()}
          options={sortingOptions}
          onChange={handleChange}
          marginBottom={'0'}
        />
        <div className='sorting__input-wrapper'>
          <input
            type='text'
            name='find-by-name'
            id='find-by-name-input'
            className='sorting__input'
            autoComplete='off'
            onChange={handleUserNameChange}
            value={userName}
          />
          <label className='sorting__input-label' htmlFor='find-by-name-input'>
            Twitch handle
          </label>
        </div>
      </div>
    </div>
  );
};

export default Sorting;
