import TwitchUserLink from '../TwitchUserLink';
import {Link} from 'react-router-dom';
import SuggestBtn from './SuggestBtn';
import {useTranslation} from 'react-i18next';
import StreamerGames from './StreamerGames';
import GyreSpinner from '../GyreSpinner';
import {ReactComponent as TwitchIcon} from '../../assets/images/twitch-icon.svg';
import {ReactComponent as ChatIcon} from '../../assets/images/new-chat-icon.svg';
import {ReactComponent as LockIcon} from '../../assets/images/lock-icon.svg';
import SettingsService from '../../services/SettingService';
import {useRef, useEffect, useState} from 'react';
import React from 'react';
import {PartnershipStatus} from "../../utils/Collections";
import Utils, {
  getAvgViewersBucket,
  getIsMatureString,
  setStreamerLanguage,
  useWindowSize,
  valueToStringOrNoData
} from "../../utils/Utils";
import {ReactComponent as CopyIcon} from "../../assets/images/copy-icon.svg";
import CollabService from "../../services/CollabService";
import AppContext from "../../store/AppContext";
import InviteStreamerModal from "../InviteStreamerModal";

const moment = require('moment');

const Streamers = ({
  onClick,
  streamersList,
  getSelectedStreamer,
  loading,
  openSignUpModal,
  admin,
  scrollToTop  
}) => {

  const context = React.useContext(AppContext);
  const [canSuggestionsBeSent, setCanSuggestionsBeSent] = useState(false);
  const {t} = useTranslation();
  const [width] = useWindowSize();
  const {REACT_APP_GYRE_BACKEND} = process.env;
  const streamersContainer = useRef();
  const [showInviteStreamerModal, setShowInviteStreamerModal] = useState(false);
  const [selectedStreamer, setSelectedStreamer] = useState(null);
  
  const fakeLogin = streamerId => {
    window.location.href = `${REACT_APP_GYRE_BACKEND}/api/login/fake?streamer_id=${streamerId}&finish_url=${window.location.origin}/`;
  };
  
  const loadCollabLimits = async () => {
    const collabData = await CollabService.getCurrentUserCollabs()
    setCanSuggestionsBeSent(collabData.count < collabData.limit);
  };

  useEffect(() => {
    const htmlElement = streamersContainer.current;
    if (htmlElement && scrollToTop) {
      htmlElement.parentElement.scrollIntoView({behavior: "smooth"});
    }
    loadCollabLimits();
  }, [streamersList]);

  const onCopyLinkClick = (streamer) => {
    Utils.copyToClipboard(window.location.host + '/streamer/' + streamer.id);
  };

  if (loading) {
    return <GyreSpinner loading={true} size={70} />;
  } else if (streamersList.length === 0) {
    return <h2 ref={streamersContainer}>{t('streamersComponent.noResult')}</h2>;
  } else {
    return (<section className='streamers' id='streamers_container' ref={streamersContainer}>
      <ul
        className={`streamers__list ${!context.isAuthenticated? 'streamers__list--not-logged-in' : ''}`}>
        {streamersList.map(streamer => (<li
          className={`streamers__card streamer-info__card ${!context.isAuthenticated
            ? 'streamers__card--not-logged-in' : ''}`}
          key={streamer.id}>
          <div className='streamers__about streamer-info__about'>
            <div
              className={`streamers__avatar-wrapper streamer-info__avatar-wrapper ${streamer.is_registered
                ? 'streamer-info__avatar-wrapper--verified' : ''}`}>
              <img
                className='streamers__avatar streamer-info__avatar'
                src={streamer.picture}
                alt=''
              />
            </div>
            <div className='streamers__nickname-wrapper streamer-info__nickname-wrapper'>
              <span className='streamers__nickname streamer-info__nickname'>
                {streamer.twitch_username}
                <button
                    className='streamer-info__copy-button'
                    onClick={() => {
                      onCopyLinkClick(streamer);
                    }}>
                  <CopyIcon alt={t('copyLink')} title={t('copyLink')}   />
                </button>
              </span>
              {streamer.is_registered
                ? (<span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                        src={require('../../assets/images/gyre-verified-icon.svg').default}
                        alt='gyre verified'
                      />
                    </span>)
                : null}
              {streamer.partner === PartnershipStatus.Partner
                ? (<span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                        src={require('../../assets/images/twitch-partner-icon.svg').default}
                        alt='twitch partner'
                      />
                    </span>)
                : null}
            </div>
            {admin
              ? (
                <button
                  className='streamers__login-as-admin-btn'
                  onClick={() => fakeLogin(streamer.id)}>
                  <LockIcon />
                </button>
              ) : null}
            <TwitchUserLink
              classNames='streamers__twitch-link '
              link={`https://www.twitch.tv/${streamer.twitch_username}`}
            />
            {streamer.is_registered
              ? (
                <Link className='streamers__chat-link' to={`/chat/${streamer.id}`}>
                  <ChatIcon />
                  {t('startChat')}
                </Link>
              ) : null}
            {width >= 1280
              ? (
                <SuggestBtn 
                  hasEmail={streamer.has_email}
                  onClick={() => {
                    setSelectedStreamer(streamer);
                    if (context.isAuthenticated) {
                      if (streamer.has_email) {
                        onClick();
                        getSelectedStreamer(streamer.id);
                      } else {
                        setShowInviteStreamerModal(true);
                      }
                    } else {
                      openSignUpModal();
                    }
                  }}
                  enabled={canSuggestionsBeSent}
                />
              ) : null}
            <div className='streamers__break streamer-info__break' />
            <StreamerGames
              classNames='streamers__games-list'
              gameList={streamer.games}
            />
          </div>
          <div className='streamers__info streamer-info__info'>
            <ul className='streamers__info-list streamer-info__info-list'>
              <li className='streamers__info-item streamer-info__info-item'>
                    <span className='streamers__info-title streamer-info__info-title'>
                      {t('streamersComponent.streamerInfo.avgViews')}
                    </span>
                <span className='streamers__info-value streamer-info__info-value'>
                      {getAvgViewersBucket(streamer.avg_viewers)}
                    </span>
              </li>
              <li className='streamers__info-item streamer-info__info-item'>
                    <span className='streamers__info-title streamer-info__info-title'>
                      {t('streamersComponent.streamerInfo.followers')}
                    </span>
                    <span className='streamers__info-value streamer-info__info-value'>
                      {valueToStringOrNoData(streamer.followers)}
                    </span>
              </li>
              <li className='streamers__info-item streamer-info__info-item'>
                    <span className='streamers__info-title streamer-info__info-title'>
                      {t('streamersComponent.streamerInfo.localTime')}
                    </span>
                <span className='streamers__info-value streamer-info__info-value'>
                      {streamer.stream_hours_min && streamer.stream_hours_max
                        ? `${moment
                          .utc()
                          .hour(streamer.stream_hours_min)
                          .tz(SettingsService.getUserTimezone())
                          .locale('en')
                          .format('hh:00 A')} - ${moment
                          .utc()
                          .hour(streamer.stream_hours_max)
                          .tz(SettingsService.getUserTimezone())
                          .locale('en')
                          .format('hh:00 A')}`
                        : t('streamersComponent.streamerInfo.noData')}
                    </span>
              </li>
              <li className='streamers__info-item streamer-info__info-item'>
                    <span className='streamers__info-title streamer-info__info-title'>
                      {t('streamersComponent.streamerInfo.mature')}
                    </span>
                <span className='streamers__info-value streamer-info__info-value'>
                    {getIsMatureString(streamer)}
                    </span>
              </li>
              <li className='streamers__info-item streamer-info__info-item'>
                    <span className='streamers__info-title streamer-info__info-title'>
                      {t('streamersComponent.streamerInfo.language')}
                    </span>
                <span className='streamers__info-value streamer-info__info-value'
                      title={setStreamerLanguage(streamer.languages, false)}>
                      {setStreamerLanguage(streamer.languages)}
                    </span>
              </li>
              <li className='streamers__info-item streamer-info__info-item'>
                <span className='streamers__info-title streamer-info__info-title'>
                  {t('streamersComponent.streamerInfo.location')}
                </span>
                <span className='streamers__info-value streamer-info__info-value'>
                  {streamer.timezone}
                </span>
              </li>
            </ul>
          </div>
          {width < 1280
            ? (
              <SuggestBtn 
                hasEmail={streamer.has_email}
                onClick={() => {
                  setSelectedStreamer(streamer);
                  if (context.isAuthenticated) {
                    if (streamer.has_email) {
                      onClick();
                      getSelectedStreamer(streamer.id);
                    } else {
                      setShowInviteStreamerModal(true);
                    }
                  } else {
                    openSignUpModal();
                  }
                }}
                enabled={canSuggestionsBeSent}
              />
            ) : null}
        </li>))}
      </ul>
      {context.isAuthenticated ? null : (<div className='streamers__login-message'>
        <h2 className='streamers__login-text'>
          {t('streamersComponent.loginStatus.p1')} <br />
          <span>{t('streamersComponent.loginStatus.p2')}</span>
        </h2>
        <button className='primary-button streamers__login-button' onClick={openSignUpModal}>
          <TwitchIcon />
          {t('streamersComponent.loginStatus.signUp')}
        </button>
      </div>)}

      {showInviteStreamerModal && 
          <InviteStreamerModal streamer={selectedStreamer} onClick={ () => {setShowInviteStreamerModal(false)}}>
          </InviteStreamerModal>
      }
    </section>);
  }
};

export default Streamers;
