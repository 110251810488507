import {useEffect, useState} from 'react';
import {ReactComponent as PlusIcon} from '../../assets/images/plus-icon.svg';
import {ReactComponent as TwitchIcon} from '../../assets/images/twitch-icon.svg';
import {useTranslation} from 'react-i18next';
import SettingsService from '../../services/SettingService';
import StreamerGames from '../find-collab/StreamerGames.js';
import SuggestCollab from '../find-collab/SuggestCollab.js';
import {getAvgViewersBucket, getIsMatureString, setStreamerLanguage, useWindowSize} from "../../utils/Utils";

const moment = require('moment');

const ChatInfo = ({
  setShowInfo,
  streamerInfo
}) => {
  const [width] = useWindowSize();
  const {t} = useTranslation();

  const [suggest, setSuggest] = useState(false);
  const openSuggestForm = () => {
    setSuggest(!suggest);
  };

  useEffect(() => {
    if (suggest) {
      document.body.classList.add('scroll-off');
    } else {
      document.body.classList.remove('scroll-off');
    }
  }, [suggest]);

  return (
    <>
      {width < 1280 && width >= 768
        ? (
          <div className='chat-info__background' onClick={() => setShowInfo(false)} />
        )
        : null}
      <section className='chat-info'>
        {width < 1280
          ? (
            <div className='chat-info__header'>
              {width < 768
                ? (
                  <button className='chat-info__go-back-btn' onClick={() => setShowInfo(false)} />
                )
                : null}
              <h2 className='chat-info__title'>Courtney Henry's info</h2>
              <button
                className={
                  width < 768 ? `chat-window__about-streamer-button ` : 'chat-info__close-button'
                }
                onClick={() => setShowInfo(false)}
              />
            </div>
          )
          : null}
        <div className='chat-info__container'>
          <div className='chat-info__info-container'>
            <div className='chat-info__profile'>
              <div className='chat-info__streamer-avatar-wrapper'>
                <img src={streamerInfo.picture} alt='' className='chat-info__streamer-avatar' />
              </div>
              <div className='chat-info__nickname-wrapper'>
                <span className='chat-info__nickname'>{streamerInfo.twitch_username}</span>
                <span className='chat-info__status chat-info__status--offline'>
                  {t('ChatsPage.offlineStatus')}
                </span>
              </div>
            </div>
            <div className='chat-info__section'>
              <h3 className='chat-info__section-title'>{t('ChatsPage.optionsTitle')}</h3>
              <a
                className='chat-info__options-button chat-info__twitch-link'
                href={`https://www.twitch.tv/${streamerInfo.twitch_username}`}
                target='_blank'
                rel='noreferrer'>
                <TwitchIcon />
                {t('twitchUserLink')}
              </a>
              <button
                className='chat-info__options-button chat-info__suggest-collab-button'
                onClick={openSuggestForm}>
                <PlusIcon />
                {t('suggestBtn')}
              </button>
            </div>
            <div className='chat-info__section'>
              <h3 className='chat-info__section-title'>{t('ChatsPage.informationTitle')}</h3>
              <ul className='chat-info__info-list'>
                <li className='chat-info__info-item chat-info__info-item--column'>
                  <span className='chat-info__info-title'>Categories</span>
                  <StreamerGames
                    classNames='chat-info__games-list'
                    gameList={streamerInfo.games}
                  />
                </li>
                <li className='chat-info__info-item'>
                  <span className='chat-info__info-title'>
                    {t('collaborationRequestPage.streamerInfo.averageViews')}
                  </span>
                  <span className='chat-info__info-value'>
                    {streamerInfo.avg_viewers
                      ? getAvgViewersBucket(streamerInfo.avg_viewers)
                      : t('streamersComponent.streamerInfo.noData')}
                  </span>
                </li>
                <li className='chat-info__info-item'>
                  <span className='chat-info__info-title'>
                    {t('collaborationRequestPage.streamerInfo.followers')}
                  </span>
                  <span className='chat-info__info-value'>
                    {streamerInfo.followers
                      ? streamerInfo.followers.toLocaleString('en')
                      : t('streamersComponent.streamerInfo.noData')}
                  </span>
                </li>
                <li className='chat-info__info-item'>
                  <span className='chat-info__info-title'>
                    {t('collaborationRequestPage.streamerInfo.time')}
                  </span>
                  <span className='chat-info__info-value'>
                    {streamerInfo.stream_hours_min && streamerInfo.stream_hours_max
                      ? `${moment
                        .utc()
                        .hour(streamerInfo.stream_hours_min)
                        .tz(SettingsService.getUserTimezone())
                        .locale('en')
                        .format('hh:00 A')} - ${moment
                        .utc()
                        .hour(streamerInfo.stream_hours_max)
                        .tz(SettingsService.getUserTimezone())
                        .locale('en')
                        .format('hh:00 A')}`
                      : t('streamersComponent.streamerInfo.noData')}
                  </span>
                </li>
                <li className='chat-info__info-item'>
                  <span className='chat-info__info-title'>
                    {t('collaborationRequestPage.streamerInfo.mature')}
                  </span>
                  <span className='chat-info__info-value'>
                    {getIsMatureString(streamerInfo)}
                  </span>
                </li>
                <li className='chat-info__info-item'>
                  <span className='chat-info__info-title'>
                    {t('collaborationRequestPage.streamerInfo.language')}
                  </span>
                  <span className='chat-info__info-value'>
                    {setStreamerLanguage(streamerInfo.languages)}
                  </span>
                </li>
                <li className='chat-info__info-item'>
                  <span className='chat-info__info-title'>
                    {t('streamersComponent.streamerInfo.location')}
                  </span>
                  <span className='chat-info__info-value'>
                    {streamerInfo.timezone}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {suggest
        ? (
          <SuggestCollab
            onClick={openSuggestForm}
            selectedStreamer={streamerInfo}
          />
        )
        : null}
    </>
  );
};

export default ChatInfo;
