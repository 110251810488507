import {Helmet} from 'react-helmet';
import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import EventRequestsSorting from '../components/my-gyre-events/EventRequestsSorting';
import EventCard from '../components/my-gyre-events/EventCard';
import GyreSpinner from '../components/GyreSpinner.js';
import ConfirmCollabModal from '../components/ConfirmCollabModal.js';
import GyreEventModal from '../components/my-gyre-events/GyreEventModal';
import EditEventModal from '../components/my-gyre-events/EventEditModalDialog';
import StreamersService from "../services/StreamersService";
import EventsService, {EventStatuses} from "../services/EventsService";
import GamesService from "../services/GamesService";
import AppContext from "../store/AppContext";
import EventCreateModal from "../components/events/EventCreateModalDialog";

const MyGyreEventPage = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [requestsCategory, setRequestsCategory] = useState('outgoing');
  const [gamesOnPage, setGamesOnPage] = useState();
  const [events, setEvents] = useState();
  const [countActiveEvent, setCountActiveEvent] = useState(0);
  const [limitEvents, setLimitEvent] = useState(0);
  const [gamesSelectedEvent, setGamesSelectedEvent] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [sortingMethod, setSortingMethod] = useState('newest');
  const [checkedFilters, setCheckedFilters] = useState([]);
  const [newStatus, setNewStatus] = useState('');

  const [confirmModal, setConfirmModal] = useState(false);
  const [isEventModal, setIsEventModal] = useState(false);
  const [isEditEventModal, setIsEditEventModal] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const context = useContext(AppContext);

  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const toggleEventModal = () => {
    setIsEventModal(!isEventModal);
  };

  const toggleEditEventModal = () => {
    setIsEditEventModal(!isEditEventModal);
  };

  const getEventData = async () => {
    setLoading(true);
    const streamersIds = [];
    let eventData;

    if (requestsCategory === 'outgoing'){
      EventsService.getMyEventsLimit().then(limits => {
        setCountActiveEvent(limits.active);
        setLimitEvent(limits.available);
      });
      
      eventData = await EventsService.getMyEvents(sortingMethod);
    } else {
      eventData = await EventsService.getSubmittedEvents(sortingMethod);
    }

    await eventData.forEach(item => {
      streamersIds.push(item.author_id);
    });

    const streamersInfoData = await StreamersService.getStreamersByIds(streamersIds, false, false, false)
      .catch(error => {
        console.log(error);
      });

    eventData.forEach(event => {
      streamersInfoData.streamers.forEach(streamer => {
        if (event.author_id === streamer.id) {
          event.streamer = streamer;
        }
      });
    });

    setGamesOnPage(streamersInfoData.games);
    setEvents(eventData);
    setLoading(false);
  };

  useEffect(() => {
    getEventData();
  }, [sortingMethod, requestsCategory]);

  const getGamesForSelectedEvent = async () => {
    const res = await GamesService.getGames(selectedEvent.game_ids);

    const newGamesArray = res.data.map((item, i) => {
      return {
        value: item.id,
        label: item.name
      };
    });

    setGamesSelectedEvent(newGamesArray)
    return newGamesArray
  };

  const determineStatus = status => {
    switch (status) {
      case EventStatuses.pending:
        return t('statuses.filters.pending');
      case EventStatuses.completed:
        return t('statuses.filters.completed');
      case EventStatuses.cancelled:
        return t('statuses.filters.cancelled');
      default:
        return '';
    }
  };

  const eventCard = (event) =>{
    return (
      <EventCard
        event={event}
        key={event.id}
        getEventData={getEventData}
        requestsCategory={requestsCategory}
        determineStatus={determineStatus}
        toggleEventModal={toggleEventModal}
        getSelectedEvent={getSelectedEvent}
        setNewStatus={setNewStatus}
        toggleConfirmModal={toggleConfirmModal}
      />
    );
  }

  const renderEventList = arr => {
    return arr.map((event) => {
      if (checkedFilters.length === 0 || checkedFilters.includes(event.status)) {
        return eventCard(event)
      }
    });
  };

  const getSelectedEvent = (eventId) => {
    events.forEach(event => {
      if (event.id === eventId) {
        setSelectedEvent(event);
      }
    });
  };

  const openCreateEventModal = () => {
    if (context.isAuthenticated) {
      setShowCreateEventModal(true);
    } 
  };

  const closeCreateEventModal = (refresh = false) => {
    setShowCreateEventModal(false);
    if (refresh)
      getEventData();
  };

  return (
    <>
      <Helmet>
        <title>{t('myEventRequestsPage.helmetTitle')}</title>
      </Helmet>
      <section className='my-gyre-events'>
        <div className='my-gyre-events__container'>
          <div className='my-gyre-events__header'>
            <div className='my-gyre-events__title-wrapper'>
              <h2 className='main__title'>{t('myEventRequestsPage.mainTitle')}</h2>
            </div>
            <div className='my-gyre-events__info-wrapper'>
              <div className='my-gyre-events__info'>
                <p className='my-gyre-events__info-description'>
                  {t('myEventRequestsPage.wrapperInfo')}
                  <span>:</span>
                </p>
                <span className='my-gyre-events__info-counter'>
                  {!loading ? countActiveEvent : '-'}
                </span>
              </div>
              <div className='my-gyre-events__info'>
                <p className='my-gyre-events__info-description'>
                  {t('myEventRequestsPage.counterInfo')}
                  <span>:</span>
                </p>
                <span className='my-gyre-events__info-counter'>
                  {!loading ? limitEvents : '-'}
                </span>
              </div>
            </div>
          </div>
          <div className='my-gyre-events__switcher'>
            <div className='my-gyre-events__switcher-wrapper'>
              <button
                className={`my-gyre-events__switch-button ${
                  requestsCategory === 'outgoing'
                    ? 'my-gyre-events__switch-button--current'
                    : ''
                }`}
                onClick={() => {
                  setRequestsCategory('outgoing');
                  setCheckedFilters([]);
                }}>
                {t('myEventRequestsPage.outgoingRequests')}
              </button>
              <button
                className={`my-gyre-events__switch-button ${
                  requestsCategory === 'incoming'
                    ? 'my-gyre-events__switch-button--current'
                    : ''
                }`}
                onClick={() => {
                  setRequestsCategory('incoming');
                  setCheckedFilters([]);
                }}>
                {t('myEventRequestsPage.incomingRequests')}
              </button>
              {context.isAuthenticated ? (
              <button
                  className='events__create-event-button my-gyre-events__create-event-button'
                  onClick={openCreateEventModal}>
                + {t('gyreEvents.createEvent')}
              </button>) : null}
            </div>
            <p className='my-gyre-events__switcher-description'>
              {requestsCategory === 'outgoing'
                ? t('myEventRequestsPage.outgoingDescription')
                : t('myEventRequestsPage.incomingDescription')}
            </p>
          </div>
          <div className='my-gyre-events__main-container'>
            <EventRequestsSorting
              checkedFilters={checkedFilters}
              setCheckedFilters={setCheckedFilters}
              sortingMethod={sortingMethod}
              setSortingMethod={setSortingMethod}
              loading={loading}
              requestsCategory={requestsCategory}
            />
            <div className='my-gyre-events__requests-wrapper'>
              {loading ? (
                <GyreSpinner loading={true} size={70} />
              ) : (
                <ul className='my-gyre-events__event-list' id='event-list'>
                  {['outgoing', 'incoming'].includes(requestsCategory)
                    ? renderEventList(events)
                    : null}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
      {isEventModal ? (
        <GyreEventModal
          onCloseClick={toggleEventModal}
          event={selectedEvent}
          gamesOnPage={gamesOnPage}
          getGamesForSelectedEvent={getGamesForSelectedEvent}
          getEventData={getEventData}
          requestsCategory={requestsCategory}
          determineStatus={determineStatus}
          toggleEventModal={toggleEventModal}
          getSelectedEvent={getSelectedEvent}
          setNewStatus={setNewStatus}
          toggleConfirmModal={toggleConfirmModal}
          toggleEditEventModal={toggleEditEventModal}
        />
      ) : null}
      
      {isEditEventModal ? (
        <EditEventModal
          event={selectedEvent}
          getEventData={getEventData}
          selectedStreamer={selectedEvent.streamer}
          getGamesForSelectedEvent={getGamesForSelectedEvent}
          gamesSelectedEvent={gamesSelectedEvent}
          toggleEventModal={toggleEventModal}
          toggleEditEventModal={toggleEditEventModal}
        />
      ) : null}

      {confirmModal && newStatus === EventStatuses.completed ? (
        <ConfirmCollabModal
          title={t('myEventRequestsPage.confirmModalTitle')}
          message={t('myEventRequestsPage.acceptedMessage')}
          onClick={toggleConfirmModal}
          goTo={window.location.pathname}
        />
      ) : confirmModal && newStatus === EventStatuses.cancelled ? (
        <ConfirmCollabModal
          title={t('myEventRequestsPage.confirmModalTitle')}
          message={t('myEventRequestsPage.rejectedMessage')}
          onClick={toggleConfirmModal}
          goTo={window.location.pathname}
        />
      ) : confirmModal && newStatus === EventStatuses.error ? (
        <ConfirmCollabModal
          title={t('suggestCollabComponent.form.ModalErrorTitle')}
          message={t('suggestCollabComponent.form.ModalErrorMessage')}
          onClick={toggleConfirmModal}
          showEmail={true}
          goTo={window.location.pathname}
        />
      ) : null}
      {showCreateEventModal ? (
          <EventCreateModal
              onCloseClick={closeCreateEventModal}
          />
      ) : null}
    </>
  );
};

export default MyGyreEventPage;
