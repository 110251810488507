import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const NotFound = () => {
  const {t} = useTranslation();

  return (
    <section className='not-found'>
      <div className='not-found__container'>
        <b className='not-found__error-code'>404</b>
        <p className='not-found__description'>{t('notFoundMsg.text')}</p>
        <Link className='primary-button' to='/'>
          {t('notFoundMsg.link')}
        </Link>
      </div>
    </section>
  );
};

export default NotFound;
