import {Helmet} from 'react-helmet';
import Promo from '../components/landing-page/Promo';
import About from '../components/landing-page/About';
import Features from '../components/landing-page/Features';
import Quote from '../components/landing-page/Quote.js';
import Steps from '../components/landing-page/Steps';
import Footer from '../components/Footer';
import {useTranslation} from 'react-i18next';
import {useContext, useEffect} from "react";
import AppContext from "../store/AppContext";

const LandingPage = ({
    openSignUpModal,
    register=false
}) => {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  
  useEffect(() => { 
    if (register && !context.isAuthenticated) {
        openSignUpModal();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('landingPage.helmetTitle')}</title>
      </Helmet>
      <main>
        <Promo />
        <About />
        <Features />
        <Quote />
        <Steps />
      </main>
      <Footer openSignUpModal={openSignUpModal} loggedInStatus={context.isAuthenticated} />
    </>
  );
};

export default LandingPage;
