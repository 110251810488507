import {ReactComponent as CompletionIcon} from '../../assets/images/completion-icon.svg';
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import {EventStatuses} from "../../services/EventsService";

const ResponseButtons = ({
                           setEventStatus,
                           eventId,
                           setNewStatus,
                           toggleConfirmModal,
                           getEventData
                         }) => {
  const {t} = useTranslation();
  const {REACT_APP_GYRE_BACKEND} = process.env;

  const updateEventStatus = status => {
    let data = queryString.stringify({
      completed: status === EventStatuses.completed,
    });

    fetch(`${REACT_APP_GYRE_BACKEND}/api/event/${eventId}/close`, {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: data
    })
      .then(res => {
        if (res.ok) {
          setEventStatus(status);
          setNewStatus(status);
          getEventData();
        }
      })
      .catch(error => {
        console.log(error)
        setNewStatus(EventStatuses.error);
      })
      .finally(() => {
        toggleConfirmModal();
      });
  };

  return (
    <>
      <button
        className='my-gyre-events__response-button my-gyre-events__completion-button'
        onClick={() => updateEventStatus(EventStatuses.completed)}>
        <CompletionIcon />
        {t('myEventRequestsPage.markCompletedRequestButton')}
      </button>
      <button
        className='my-gyre-events__response-button my-gyre-events__cancel-button'
        onClick={() => updateEventStatus(EventStatuses.cancelled)}>
        <CloseIcon />
        {t('myEventRequestsPage.markCanceledRequestButton')}
      </button>
    </>
  );
};

export default ResponseButtons;
