import {useState, useEffect} from 'react';
import Collapsible from 'react-collapsible';
import DimmedSelector from '../selectors/DimmedSelector.js';
import {useTranslation} from 'react-i18next';
import {useWindowSize} from "../../utils/Utils";

const CollabRequestsSorting = ({
  collabStatuses,
  checkedFilters,
  setCheckedFilters,
  sortingMethod,
  setSortingMethod,
  loading,
  requestsCategory
}) => {
  const [width] = useWindowSize();
  const {t} = useTranslation();
  const [collabCounter, setCollabCounter] = useState('');

  useEffect(() => {
    if (!loading) {
      setCollabCounter(document.getElementById('collab-list').childElementCount);
    }
  }, [loading, checkedFilters, sortingMethod, requestsCategory]);

  const sortingOptions = [
    {
      value: 'newest',
      label: t('myCollabRequestsPage.newest')
    },
    {
      value: 'oldest',
      label: t('myCollabRequestsPage.oldest')
    }
  ];

  const handleChange = selectedOption => {
    setSortingMethod(selectedOption.value);
  };

  const getSelectedOption = () => {
    return sortingOptions.find(i => i.value == sortingMethod) || sortingOptions[0];
  };

  const statusOptions = [
    {
      id: 1,
      value: collabStatuses.pending,
      label: t('statuses.filters.pending')
    },
    {
      id: 2,
      value: collabStatuses.rejected,
      label: t('statuses.filters.rejected')
    },
    {
      id: 3,
      value: collabStatuses.noResponse,
      label: t('statuses.filters.noResponse')
    },
    {
      id: 4,
      value: collabStatuses.accepted,
      label: t('statuses.filters.accepted')
    },
    {
      id: 5,
      value: collabStatuses.completed,
      label: t('statuses.filters.completed')
    },
    {
      id: 6,
      value: collabStatuses.cancelled,
      label: t('statuses.filters.cancelled')
    }
  ];

  const handleToggle = value => {
    const currentIndex = checkedFilters.indexOf(value);
    const newChecked = [...checkedFilters];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedFilters(newChecked);
  };

  return (
    <aside className='my-collab-requests__filters-wrapper'>
      <Collapsible
        trigger='Filters'
        className='my-collab-requests__accordion'
        open={width < 1280 ? false : true}>
        <span className='my-collab-requests__filter-label'>
          {t('myCollabRequestsPage.filterSortByLable')}
        </span>
        <DimmedSelector
          defaultValue={getSelectedOption()}
          options={sortingOptions}
          onChange={handleChange}
          marginBottom={'24px'}
        />
        <span className='my-collab-requests__filter-label'>
          {t('myCollabRequestsPage.filterStatusLabel')}
        </span>
        <ul className='filters__checkbox-list my-collab-requests__checkbox-list'>
          {statusOptions.map((checkbox, i) => (
            <li className='filters__checkbox-item' key={i}>
              <input
                className='filters__checkbox visually-hidden'
                type='checkbox'
                name={checkbox.value}
                id={`${checkbox.value}-input`}
                value={checkbox.value}
                onChange={() => handleToggle(checkbox.value)}
                checked={checkedFilters.includes(checkbox.value) ? true : false}
              />
              <label className='filters__checkbox-label' htmlFor={`${checkbox.value}-input`}>
                {checkbox.label}
              </label>
            </li>
          ))}
        </ul>
        <span className='my-collab-requests__list-counter'>
          {`${collabCounter} ${t('myCollabRequestsPage.inTheList')}`}
        </span>
      </Collapsible>
    </aside>
  );
};

export default CollabRequestsSorting;
