import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import StreamersService from "../services/StreamersService";
import TwitchUserLink from "../components/TwitchUserLink";
import StreamerGames from "../components/find-collab/StreamerGames";
import {PartnershipStatus} from "../utils/Collections";
import {getAvgViewersBucket, getIsMatureString, setStreamerLanguage, valueToStringOrNoData} from "../utils/Utils";
import SettingsService from "../services/SettingService";
import {Helmet} from "react-helmet";
import GyreSpinner from "../components/GyreSpinner";
import SuggestBtn from "../components/find-collab/SuggestBtn";
import {ReactComponent as ChatIcon} from "../assets/images/new-chat-icon.svg";
import SuggestCollab from "../components/find-collab/SuggestCollab";
import CollabService from "../services/CollabService";

const moment = require('moment');

const StreamerPage = () => {

    const {t} = useTranslation();

    const [streamer, setStreamer] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(t('streamersComponent.noResult'));
    const {streamerId} = useParams();
    const [canSuggestionsBeSent, setCanSuggestionsBeSent] = useState(false);

    const [suggest, setSuggest] = useState(false);
    const openSuggestForm = () => {
        setSuggest(!suggest);
    };
    
    const loadStreamer = async (streamerId) => {
        const streamer = await StreamersService.getStreamer(streamerId);
        setStreamer(streamer);
    };


    const loadCollabLimits = async () => {
        const collabData = await CollabService.getCurrentUserCollabs()
        setCanSuggestionsBeSent(collabData.count < collabData.limit);
    };
    
    useEffect( () => {
        if (streamerId) {
            loadStreamer(streamerId).then( () => {
                setLoading(false);
            }).catch((reason) => {
                setError(reason.toString());
            });

            loadCollabLimits();
        }
        
    }, []);

    if (loading) {
        return <GyreSpinner loading={true} size={70} />;
    } 
    else if (!streamer)
        return (
            <div>
                {error}
            </div>
        );
    else    
    return (
    <>
    <Helmet>
        <title>{t('streamerPage.helmetTitle')}</title>
    </Helmet>
    <section className='main'>
        <div className='main__wrapper'>
            <h2 className='main__title'>{t('streamerPage.mainTitle')}</h2>
            <div className='collaboration-request__streamer-card'>
                <div className='collaboration-request__about streamer-info__about'>
                    <div
                        className={`collaboration-request__avatar-wrapper
                                streamer-info__avatar-wrapper
                                ${
                            streamer.is_registered
                                ? 'streamer-info__avatar-wrapper--verified'
                                : ''
                        }`}>
                        <img
                            className='collaboration-request__avatar streamer-info__avatar'
                            src={streamer.picture}
                            alt=''
                        />
                    </div>
                    <div className='collaboration-request__nickname-wrapper streamer-info__nickname-wrapper'>
                                  <span className='collaboration-request__nickname streamer-info__nickname'>
                                    {streamer.twitch_username}
                                  </span>
                        {streamer.is_registered ? (
                            <span className='streamer__partner-icon streamer-info__partner-icon'>
                                      <img
                                          src={require('../assets/images/gyre-verified-icon.svg').default}
                                          alt='gyre verified' title='Registered on Gyre'
                                      />
                                    </span>
                        ) : null}
                        {streamer.partner === PartnershipStatus.Partner ? (
                            <span className='streamer__partner-icon streamer-info__partner-icon'>
                                      <img
                                          src={require('../assets/images/twitch-partner-icon.svg').default}
                                          alt='twitch partner' title='Twitch Partner'
                                      />
                                    </span>
                        ) : null}
                    </div>
                    <TwitchUserLink
                        classNames='streamers__twitch-link'
                        link={`https://www.twitch.tv/${streamer.twitch_username}`}
                    />
                    {streamer.is_registered
                        ? (
                            <Link className='streamers__chat-link' to={`/chat/${streamer.id}`}>
                                <ChatIcon />
                                {t('startChat')}
                            </Link>
                        ) : null}
                    <SuggestBtn
                        hasEmail={streamer.has_email}
                        onClick={() => {
                            if (streamer.has_email) {
                                openSuggestForm();
                            }
                        }}
                        enabled={canSuggestionsBeSent}
                    />
                    <div className='collaboration-request__break streamer-info__break' />
                    <StreamerGames
                        classNames='collaboration-request__games-list'
                        gameList={streamer.games}
                    />
                </div>
                <div className='collaboration-request__info streamer-info__info'>
                    <ul className='collaboration-request__info-list streamer-info__info-list'>
                        <li className='collaboration-request__info-item streamer-info__info-item'>
                                    <span className='collaboration-request__info-title streamer-info__info-title'>
                                      {t('collaborationRequestPage.streamerInfo.averageViews')}
                                    </span>
                                    <span className='collaboration-request__info-value streamer-info__info-value'>
                                      {streamer.avg_viewers
                                          ? getAvgViewersBucket(streamer.avg_viewers)
                                          : t('streamersComponent.streamerInfo.noData')}
                                    </span>
                        </li>
                        <li className='collaboration-request__info-item streamer-info__info-item'>
                                    <span className='collaboration-request__info-title streamer-info__info-title'>
                                      {t('collaborationRequestPage.streamerInfo.followers')}
                                    </span>
                                    <span className='collaboration-request__info-value streamer-info__info-value'>
                                      {valueToStringOrNoData(streamer.followers)}
                                    </span>
                        </li>
                        <li className='collaboration-request__info-item streamer-info__info-item'>
                                    <span className='collaboration-request__info-title streamer-info__info-title'>
                                      {t('collaborationRequestPage.streamerInfo.time')}
                                    </span>
                            <span className='collaboration-request__info-value streamer-info__info-value'>
                                      {streamer.stream_hours_min
                                      && streamer.stream_hours_max
                                          ? `${moment
                                              .utc()
                                              .hour(streamer.stream_hours_min)
                                              .tz(SettingsService.getUserTimezone())
                                              .locale('en')
                                              .format('hh:00 A')} - ${moment
                                              .utc()
                                              .hour(streamer.stream_hours_max)
                                              .tz(SettingsService.getUserTimezone())
                                              .locale('en')
                                              .format('hh:00 A')}`
                                          : t('streamersComponent.streamerInfo.noData')}
                                    </span>
                        </li>
                        <li className='collaboration-request__info-item streamer-info__info-item'>
                                    <span className='collaboration-request__info-title streamer-info__info-title'>
                                      {t('collaborationRequestPage.streamerInfo.mature')}
                                    </span>
                            <span className='collaboration-request__info-value streamer-info__info-value'>
                                {getIsMatureString(streamer)}
                            </span>
                        </li>
                        <li className='collaboration-request__info-item streamer-info__info-item'>
                                    <span className='collaboration-request__info-title streamer-info__info-title'>
                                      {t('collaborationRequestPage.streamerInfo.language')}
                                    </span>
                            <span className='collaboration-request__info-value streamer-info__info-value'>
                                      {streamer.languages
                                          ? setStreamerLanguage(streamer.languages)
                                          : t('streamersComponent.streamerInfo.noData')}
                                    </span>
                        </li>
                        <li className='collaboration-request__info-item streamer-info__info-item'>
                        <span className='collaboration-request__info-title streamer-info__info-title'>
                          {t('streamersComponent.streamerInfo.location')}
                        </span>
                            <span className='collaboration-request__info-value streamer-info__info-value'>
                          {streamer.timezone}
                        </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    {suggest ? (
        <SuggestCollab
            onClick={openSuggestForm}
            selectedStreamer={streamer}
        />
    ) : null}
    </>);
};

export default StreamerPage;