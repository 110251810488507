import {useTranslation} from 'react-i18next';
import {ReactComponent as TwitchIcon} from '../assets/images/twitch-icon.svg';

const TwitchUserLink = ({link, classNames}) => {
  const {t} = useTranslation();

  return (
    <a
      className={`twitch-user-link ${classNames}`}
      href={link}
      target='_blank'
      rel='noreferrer'
      title={t('twitchUserLink')}
      aria-label='Open twitch'>
      <TwitchIcon />
      {t('twitchUserLink')}
    </a>
  );
};

export default TwitchUserLink;
