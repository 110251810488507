import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ResponseButtons from './ResponseButtons.js';
import SettingsService from '../../services/SettingService.js';
import {EventStatuses} from "../../services/EventsService";
import {ReactComponent as CopyIcon} from "../../assets/images/copy-icon.svg";
import Utils from "../../utils/Utils";

const moment = require('moment');

const EventCard = ({
                     event,
                     getEventData,
                     requestsCategory,
                     determineStatus,
                     toggleEventModal,
                     getSelectedEvent,
                     setNewStatus,
                     toggleConfirmModal,
                   }) => {
  const {t, i18n} = useTranslation();
  const [eventStatus, setEventStatus] = useState();

  useEffect(() => {
    setEventStatus(event.status);
  }, [event]);

  const handleClick = () => {
    getSelectedEvent(event.id, eventStatus);
    toggleEventModal();
  };

  const onCopyLinkClick = () => {
    Utils.copyToClipboard(window.location.host + '/gyre-events/' + event.id);
  };

  return (
    event.streamer ?
      <li className='my-gyre-events__event-item' key={event.id}>
        <div className='my-gyre-events__streamer-info'>
          <div className='my-gyre-events__streamer-info-container'>
            {requestsCategory === 'incoming' ?
              <div
                  className={`streamers__avatar-wrapper streamer-info__avatar-wrapper ${
                      event.streamer.is_content_creator ? 'streamer-info__avatar-wrapper--system' : (event.streamer.is_registered ? 'streamer-info__avatar-wrapper--verified' : '')
                }`}>
                <img
                  className='my-gyre-events__streamer-avatar streamer-info__avatar'
                  src={event.streamer.is_content_creator ?  require('../../assets/images/logo-min.svg').default: event.streamer.picture}
                  alt=''
                />
              </div>
              : null}
            <div className='my-gyre-events__streamer-info-wrapper'>
              <div className='my-gyre-events__nickname-wrapper streamer-info__nickname-wrapper'>
              <span className='my-gyre-events__streamer-nickname streamer-info__nickname'>
                {requestsCategory === 'incoming'
                  ? event.streamer.twitch_username
                  : event.event_name}
              </span>
              <button
                  className='event-details__button event-details__copy-button'
                  onClick={() => {
                      onCopyLinkClick();
                  }}>
                  <CopyIcon alt={t('copyLink')} title={t('copyLink')}  />
              </button>
                  
                {event.streamer.is_registered && requestsCategory === 'incoming' ? (
                  <span className='streamer__partner-icon streamer-info__partner-icon'>
                  <img
                    src={require('../../assets/images/gyre-verified-icon.svg').default}
                    alt='gyre verified'
                  />
                </span>
                ) : null}
                {event.streamer.is_content_creator && requestsCategory === 'incoming' ? (
                <span className='streamer__partner-icon streamer-info__partner-icon'>
                  <img
                      src={require('../../assets/images/twitch-system-icon.svg').default}
                      alt='Gyre system' title='Gyre System'
                  />
                </span>
                ) :
                    (event.streamer.partner === 'Partner' && requestsCategory === 'incoming' ? (
                    <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                          src={require('../../assets/images/twitch-partner-icon.svg').default}
                          alt='twitch partner'
                      />
                    </span>
                    ) : null)
                }
              </div>
              <div className='my-gyre-events__status-wrapper'>
                  <span
                    className={`my-gyre-events__status my-gyre-events__status--${eventStatus}`}>
                    {determineStatus(eventStatus)}
                  </span>
                  <span className='my-gyre-events__request-date'>
                    {`${requestsCategory === 'outgoing' ? t('myGyreEventsModal.createdAt') : t('myEventRequestsPage.requested')} ${moment
                      .utc(event.created_at)
                      .tz(SettingsService.getUserTimezone())
                      .locale(i18n.language)
                      .format('LLL')}`}
                  </span>
                  {event.is_private && (
                      <div className='event-details__private_label'>
                          {t('EventCreateModalComponent.form.collaboration.private')}
                      </div>)}
              </div>
            </div>
          </div>
          <button
            className='my-gyre-events__view-request-button my-gyre-events__view-request-button--desktop'
            onClick={handleClick}>
            {t('myEventRequestsPage.viewRequestButton')}
          </button>
        </div>
        {eventStatus === EventStatuses.pending && requestsCategory === 'outgoing' ? (
          <div className='my-gyre-events__response-buttons-wrapper'>
            <ResponseButtons
              setEventStatus={setEventStatus}
              eventId={event.id}
              setNewStatus={setNewStatus}
              toggleConfirmModal={toggleConfirmModal}
              getEventData={getEventData}
            />
          </div>
        ) : null}
      </li> : null
  );
};

export default EventCard;
