import {useTranslation} from 'react-i18next';
import {HashLink} from 'react-router-hash-link';

const LandingNavigation = () => {
  const {t} = useTranslation();

  return (
    <nav className='landing-navigation'>
      <ul className='landing-navigation__nav-list'>
        <li className='landing-navigation__nav-item'>
          <HashLink className='landing-navigation__nav-link' to='/#about' smooth>
            {t('landingPage.navigation.about')}
          </HashLink>
        </li>
        <li className='landing-navigation__nav-item'>
          <HashLink className='landing-navigation__nav-link' to='/#features' smooth>
            {t('landingPage.navigation.features')}
          </HashLink>
        </li>
        <li className='landing-navigation__nav-item'>
          <HashLink className='landing-navigation__nav-link' to='/#steps' smooth>
            {t('landingPage.navigation.howItWorks')}
          </HashLink>
        </li>
      </ul>
    </nav>
  );
};

export default LandingNavigation;
