import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowRightIcon} from '../assets/images/arrow-right-icon.svg';

const CollaborateNowBtn = ({classNames}) => {
  const {t} = useTranslation();

  return (
    <Link className={`${classNames} primary-button`} to='/find-collab'>
      <ArrowRightIcon height='24' width='24' />
      {t('collaborateNowBtn')}
    </Link>
  );
};

export default CollaborateNowBtn;
