import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ResponseButtons from './ResponseButtons.js';
import SettingsService from '../../services/SettingService.js';
import {Link} from "react-router-dom";
import {ReactComponent as ChatIcon} from "../../assets/images/new-chat-icon.svg";
import {PartnershipStatus} from "../../utils/Collections";

const moment = require('moment');

const CollabCard = ({
  userId,
  collab,
  collabStatuses,
  determineStatus,
  openCollabModal,
  getSelectedCollab,
  setNewStatus,
  showConfirmModal
}) => {
  const {t, i18n} = useTranslation();
  const [collabStatus, setCollabStatus] = useState();

  useEffect(() => {
    setCollabStatus(collab.status);
  }, [collab]);

  return (
    collab.streamer ?
      <li className='my-collab-requests__collab-item' key={collab.id}>
        <div className='my-collab-requests__streamer-info'>
          <div className='my-collab-requests__streamer-info-container'>
            <div
              className={`my-collab-requests__avatar-wrapper streamer-info__avatar-wrapper ${
                collab.streamer.is_registered ? 'streamer-info__avatar-wrapper--verified' : ''
              }`}>
              <img
                className='my-collab-requests__streamer-avatar streamer-info__avatar'
                src={collab.streamer.picture}
                alt=''
              />
            </div>
            <div className='my-collab-requests__streamer-info-wrapper'>
              <div className='my-collab-requests__nickname-wrapper streamer-info__nickname-wrapper'>
              <span className='my-collab-requests__streamer-nickname streamer-info__nickname'>
                {collab.streamer.twitch_username}
              </span>
                {collab.streamer.is_registered ? (
                  <span className='streamer__partner-icon streamer-info__partner-icon'>
                  <img
                    src={require('../../assets/images/gyre-verified-icon.svg').default}
                    alt='gyre verified'
                  />
                </span>
                ) : null}
                {collab.streamer.partner === PartnershipStatus.Partner ? (
                  <span className='streamer__partner-icon streamer-info__partner-icon'>
                  <img
                    src={require('../../assets/images/twitch-partner-icon.svg').default}
                    alt='twitch partner'
                  />
                </span>
                ) : null}
              </div>
              <div className='my-collab-requests__status-wrapper'>
              <span
                className={`my-collab-requests__status my-collab-requests__status--${collabStatus}`}>
                {determineStatus(collabStatus)}
              </span>
                <span className='my-collab-requests__request-date'>
                {`${t('myCollabRequestsPage.requested')} ${moment
                  .utc(collab.created_at)
                  .tz(SettingsService.getUserTimezone())
                  .locale(i18n.language)
                  .format('LLL')}`}
              </span>
              </div>
            </div>
          </div>
          <div className='my-collab-requests__streamer-info-container'>
            {collab.streamer.is_registered
                ? (
                    <Link className='streamers__chat-link' to={`/chat/${collab.streamer.id}`}>
                      <ChatIcon />
                      {t('startChat')}
                    </Link>
                ) : null}
            <button
                className='my-collab-requests__view-request-button my-collab-requests__view-request-button--desktop'
                onClick={() => {
                  getSelectedCollab(collab.id, collabStatus);
                  openCollabModal();
                }}>
              {t('myCollabRequestsPage.viewRequestButton')}
            </button>
          </div>

        </div>
        {(collabStatus === collabStatuses.pending && collab.sender_id !== userId) ||
        collabStatus === collabStatuses.accepted ? (
          <div className='my-collab-requests__response-buttons-wrapper'>
            <ResponseButtons
              collabStatus={collabStatus}
              collabStatuses={collabStatuses}
              setCollabStatus={setCollabStatus}
              collabId={collab.id}
              setNewStatus={setNewStatus}
              showConfirmModal={showConfirmModal}
            />
          </div>
        ) : null}
        <button
          className='my-collab-requests__view-request-button my-collab-requests__view-request-button--mobile'
          onClick={() => {
            getSelectedCollab(collab.id, collabStatus);
            openCollabModal();
          }}>
          {t('myCollabRequestsPage.viewRequestButton')}
        </button>
      </li> : null
  );
};

export default CollabCard;
