import CollaborateNowBtn from '../CollaborateNowBtn';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import i18n from 'i18next';
import {useEffect, useState} from 'react';

const Quote = () => {
  const allQuotes = [
    {
      nickname: 'Shipbroman',
      text: 'Yes my feedback is positive and all is well ❤️ Happy with your service',
      locale: 'en',
      twitchLink: 'https://www.twitch.tv/shipbroman'
    },
    {
      nickname: 'GekkoTreesTV',
      text:
        'My first collab with MrTweety was awesome! We played Apex and had a bunch of cool game moments. I liked him he jokes almost all the time lol\n' +
        "The Internet connection was not so good but that wasn't the big problem. Appreciate you guys for working with me! I'm down for the next events, It'd be interesting to see what happens next",
      locale: 'en',
      twitchLink: 'https://www.twitch.tv/gekkotreestv'
    },
    {
      nickname: 'Blaz3d',
      text: "I had a great Warzone collab with CTgaminggg, he was a cool dude! Like the idea, keep it going guys! And I'm down for the next events for sure",
      locale: 'en',
      twitchLink: 'https://www.twitch.tv/blaz3d'
    },
    {
      nickname: 'MrTweety',
      text: 'So I have to say that so far I have had two successful collabs and have really enjoyed what they did and what came of them will definitely recommend this to my friends and fellow streamers who are struggling to make friends or get some growth, these guys work with you and are not like other companies who would just say here this person works with out even looking into the people, these guys connect you first by sending you each others links to check out if you both like what you see they go from there and i really appreciate that long story short this company if it keeps doing things right will go far',
      locale: 'en',
      twitchLink: 'https://www.twitch.tv/mrtweety'
    },
    {
      nickname: 'SpookSter',
      text: 'Great service! Thank you so much for the amazing help @Raditor576 👍',
      locale: 'en',
      twitchLink: 'https://www.twitch.tv/mrspooksterxd'
    },
    {
      nickname: 'ThatProgrammer',
      text: 'Had a fun time with @KingWolf and made a lot of memories in the short time we streamed together, definitely gonna stream together in the future. It was a blast!',
      locale: 'en',
      twitchLink: 'https://www.twitch.tv/ThatProgrammerTV'
    },
    {
      nickname: 'PeKay',
      text: 'Had a lot of fun last night streaming with @kreeveyates! For sure a good experience. Thanks to the people who helped in the process',
      locale: 'en',
      twitchLink: 'https://www.twitch.tv/pekaay'
    },
    {
      nickname: 'ENVY',
      text: 'Первый коллаб с @GODLESS прошел пушечно. Спасибо @gyre Team за такую возможность! Желаем стремительного роста и новых стримеров в пуле💯',
      locale: 'ru',
      twitchLink: 'https://twitch.tv/envytheslayer'
    },
    {
      nickname: 'RU_TRUE',
      text: 'Спасибо ребятам за организацию коллаборации. Все было на высоте, надеюсь на последующее сотрудничество. Отдельное спасибо sauceat )',
      locale: 'ru',
      twitchLink: 'https://www.twitch.tv/ru_true'
    },
    {
      nickname: 'Rony4ka',
      text: 'Спасибо большое за организацию каллаба, все прошло прекрасно',
      locale: 'ru',
      twitchLink: 'https://www.twitch.tv/rony4ka'
    },
    {
      nickname: 'DENDY',
      text: 'Спасибо за организацию коллабы мужики, все было по высшему разряду, приятно пообщались, поиграли, кайф. С нетерпением жду следующей совместки.',
      locale: 'ru',
      twitchLink: 'https://www.twitch.tv/dendy_q'
    },
    {
      nickname: 'Dei_FoR',
      text: 'Хотел сказать всем спасибо за мафию, максимально круто провели время, и спасибо организаторам что собрали нас всех, ребята тоже максимально классные, особенно зип куку.',
      locale: 'ru',
      twitchLink: 'https://www.twitch.tv/dei_for322g'
    },
    {
      nickname: '96_Oddix',
      text: 'Что ж, после стрима эмоции и впечатления остались хорошими! Все было по красоте, организованно и весело. Нравится что все были готовы поддержать и помочь по любым вопросам, Так что так держать! Спасибо за такую возможность!',
      locale: 'ru',
      twitchLink: 'https://www.twitch.tv/96_oddix'
    }
  ];

  const [quotesToShow, setQuotesToShow] = useState([]);

  useEffect(() => {
    setQuotesToShow(allQuotes.filter(item => item.locale == i18n.language));
  }, [i18n.language]);

  return (
    <section className='quote'>
      <div className='quote__container'>
        <Splide
          options={{
            type: 'fade',
            perPage: 1,
            rewind: true,
            pagination: false,
            autoplay: true,
            interval: 3000,
            speed: 400,
            classes: {
              // Add classes for arrows.
              arrows: 'splide__arrows quote__arrows',
              arrow: 'splide__arrow quote__arrow',
              prev: 'splide__arrow--prev quote__arrow--prev',
              next: 'splide__arrow--next quote__arrow--next'
            },
            breakpoints: {
              767: {
                arrows: false,
                rewind: true,
                perPage: 1
              }
            }
          }}>
          {quotesToShow.map(streamer => {
            return (
              <SplideSlide>
                <div className='quote__component'>
                  <p className='quote__text'>{streamer.text}</p>
                  <div className='quote__info'>
                    <a href={streamer.twitchLink} className='quote__author-name'>
                      {streamer.nickname}
                    </a>
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
        <CollaborateNowBtn classNames='quote__collaborate-btn' />
      </div>
    </section>
  );
};

export default Quote;
