import queryString from "query-string";

const {REACT_APP_GYRE_BACKEND} = process.env;

class NotificationsService {
    static getNotifications = async () => {
        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/notifications/my`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }).then(res => res.json());
    }
    
    static sendBugReport = (title, content) => {
        if (!title || !content)  return;
        
        const reqParams = queryString.stringify({
            bugreport_title: title,
            bugreport_content: content
        }, {
            skipEmptyString: true,
            skipNull: true
        });
        
        return fetch(`${REACT_APP_GYRE_BACKEND}/api/notifications/bugreport`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: reqParams
        }).catch(error => {
            console.log(error);
        });
    }
}

export default NotificationsService;