import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg';

const ImagePreview = ({image, onClick}) => {
  return (
    <section className='image-preview'>
      <div className='image-preview__background' onClick={onClick} />
      <div className='image-preview__wrapper'>
        <img
          className='image-preview__image'
          src={require(`../../assets/images/screen-${image}.jpg`)}
          alt=''
        />
        <button className='image-preview__close-button' aria-label='close image' onClick={onClick}>
          <CloseIcon />
        </button>
      </div>
    </section>
  );
};

export default ImagePreview;
