import {useTranslation} from 'react-i18next';

const StreamerGames = ({gameList: allGamesList, streamerGameIds, classNames}) => {
  const {t} = useTranslation();

  const getStreamerGamesList = (streamerGameIds) => {
    if (!allGamesList) return [];
    if (!streamerGameIds) return allGamesList.slice(0, 10); // no filter, return max 10 games just in case
    
    return allGamesList.filter(game => {
      return streamerGameIds.includes(game.id);
    });
  };
  
  const loadStreamerGames = () => {
    const parsedGamesList = getStreamerGamesList(streamerGameIds);
    
    if (parsedGamesList.length > 3) {
      const shortList = parsedGamesList.slice(0, 2);
      const moreGames = parsedGamesList.slice(2);
      return (
        <>
          {shortList.map((game, i) => {
            return (
              <li className='streamer-info__games-item' key={i}>
                {game.name}
              </li>
            );
          })}
          <li className='streamer-info__more-games-item'>
            <button className='streamer-info__show-more-games-btn'>
              {t('streamersComponent.showMoreBtn')}
              <span className='elipse' />
              <span className='elipse' />
              <span className='elipse' />
            </button>
            <ul className='streamer-info__more-games-list'>
              {moreGames.map((game, i) => {
                return (
                  <li className='streamer-info__games-item' key={i}>
                    {game.name}
                  </li>
                );
              })}
            </ul>
          </li>
        </>
      );
    } else {
      return parsedGamesList.map((game, i) => {
        return (
          <li className='streamer-info__games-item' key={i}>
            {game.name}
          </li>
        );
      });
    }
  };
  
  
  return (
    <>
      <ul className={`${classNames} streamer-info__games-list`}>
        {loadStreamerGames()}
      </ul>
    </>
  );
};

export default StreamerGames;
