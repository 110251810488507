import {useLayoutEffect, useState} from 'react';
import ISO6391 from 'iso-639-1';
import Timezones from './Timezones.js';
import i18n from 'i18next';

const {REACT_APP_GYRE_BACKEND} = process.env;

class Utils {

    static copyToClipboard = str => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText)
            return navigator.clipboard.writeText(str);
        return Promise.reject('The Clipboard API is not available.');
    };

}

export default Utils;

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
export const setMinValue = (filter, maxValue) => {
  if (filter.length && !filter.includes(maxValue)) {
    return Math.min(...filter);
  } else if (filter.length && filter.includes(maxValue)) {
    return Math.min(...filter);
  } else if (filter.includes(maxValue)) {
    return maxValue;
  }
};
export const setMaxValue = (filter, maxValue) => {
  if (filter.length && !filter.includes(maxValue)) {
    return Math.max(...filter);
  }
};
export const getAvgViewersBucket = avgViewers => {
  if (avgViewers <= 50) {
    return '0 - 50';
  } else if (avgViewers < 250) {
    return '50 - 250';
  } else if (avgViewers < 500) {
    return '250 - 500';
  } else if (avgViewers < 1000) {
    return '500 - 1K';
  } else if (avgViewers >= 1000) {
    return '1K+';
  }
};

export const getIsMatureString = (streamer) => {
  return streamer.has_mature_content === undefined
      ? i18n.t('streamersComponent.streamerInfo.noData')
      : streamer.has_mature_content
          ? i18n.t('yes')
          : i18n.t('no');
}

export const setStreamerLanguage = (languages, truncate = true) => {
  if (languages) {
    const langs = languages.map(item => {
      if (item === 'other') {
        return 'Other';
      } else {
        return ISO6391.getName(item);
      }
    });

    if (langs) {
      if (langs.length > 1) {
        if (truncate)
          return langs[0] + "...";
        else return langs.join(", ");
      }
      return langs;
    }
  }

  return i18n.t('streamersComponent.streamerInfo.noData');
};

export const valueToStringOrNoData = (value) => {
  if (value !== null && value !== undefined) return value.toLocaleString();
  return i18n.t('streamersComponent.streamerInfo.noData');
}

export const getStreamerLocation = (timezones, streamerId) => {
  if (Object.keys(timezones).includes(streamerId.toString())) {
    let result;
    Object.keys(timezones).map(timezoneId => {
      let tzOffset;
      if (streamerId == timezoneId) {
        Timezones.map(tz => {
          if (tz.tzCode === timezones[timezoneId]) {
            tzOffset = tz.offset;
          }
        });
        result = `${timezones[timezoneId]} (${tzOffset})`;
      }
    });
    return result;
  } else {
    return i18n.t('streamersComponent.streamerInfo.noData');
  }
};

export const getAbilityToRespondForCurrentUser = async (selectedEvent, setAbility) => {
  if (selectedEvent === undefined) {
    return;
  }
  
  let responseData = await fetch(`${REACT_APP_GYRE_BACKEND}/api/event/my_responses`, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  }).then(res => res.json())
    .catch(error => {
      console.log(error);
    });
  
  setAbility(true);

   for (const item of responseData) {
    if (item.id === selectedEvent.id) {
      setAbility(false);
      break;
    }
  }
};