import Footer from '../components/Footer';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

const PrivacyPolicyPage = ({
  openSignUpModal
}) => {
  const {t} = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('privacyPolicyPage.helmetTitle')}</title>
      </Helmet>
      <main>
        <section className='privacy-policy'>
          <div className='privacy-policy__container'>
            <h2 className='privacy-policy__main-title'>{t('privacyPolicyPage.mainTitle')}</h2>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.intro.p1')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.intro.p2')}</p>
            <p className='privacy-policy__paragraph'>
              {t('privacyPolicyPage.intro.p3')}{' '}
              <a
                className='privacy-policy__link'
                href='https://www.privacypolicygenerator.info/'
                target='_blank'
                rel='noreferrer'>
                {t('privacyPolicyPage.policyGenerator.descriptionLink')}
              </a>
            </p>
            <h3 className='privacy-policy__title'>{t('privacyPolicyPage.consent.title')}</h3>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.consent.p1')}</p>
            <h3 className='privacy-policy__title'>{t('privacyPolicyPage.infoWeCollect.title')}</h3>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.infoWeCollect.p1')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.infoWeCollect.p2')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.infoWeCollect.p3')}</p>
            <h3 className='privacy-policy__title'>{t('privacyPolicyPage.howWeUseInfo.title')}</h3>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.howWeUseInfo.p1')}</p>
            <ul className='privacy-policy__list'>
              <li className='privacy-policy__item'>{t('privacyPolicyPage.howWeUseInfo.l1')}</li>
              <li className='privacy-policy__item'>{t('privacyPolicyPage.howWeUseInfo.l2')}</li>
              <li className='privacy-policy__item'>{t('privacyPolicyPage.howWeUseInfo.l3')}</li>
              <li className='privacy-policy__item'>{t('privacyPolicyPage.howWeUseInfo.l4')}</li>
              <li className='privacy-policy__item'>{t('privacyPolicyPage.howWeUseInfo.l5')}</li>
              <li className='privacy-policy__item'>{t('privacyPolicyPage.howWeUseInfo.l6')}</li>
              <li className='privacy-policy__item'>{t('privacyPolicyPage.howWeUseInfo.l7')}</li>
            </ul>
            <h3 className='privacy-policy__title'>{t('privacyPolicyPage.logFiles.title')}</h3>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.logFiles.p1')}</p>
            <h3 className='privacy-policy__title'>{t('privacyPolicyPage.cookies.title')}</h3>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.cookies.p1')}</p>
            <p className='privacy-policy__paragraph'>
              {t('privacyPolicyPage.cookies.p2')}{' '}
              <a
                className='privacy-policy__link'
                href='https://www.generateprivacypolicy.com/#cookies'
                target='_blank'
                rel='noreferrer'>
                {t('privacyPolicyPage.policyGenerator.cookiesArticle')}
              </a>
            </p>
            <h3 className='privacy-policy__title'>
              {t('privacyPolicyPage.advertisingPartners.title')}
            </h3>
            <p className='privacy-policy__paragraph'>
              {t('privacyPolicyPage.advertisingPartners.p1')}
            </p>
            <p className='privacy-policy__paragraph'>
              {t('privacyPolicyPage.advertisingPartners.p2')}
            </p>
            <p className='privacy-policy__paragraph'>
              {t('privacyPolicyPage.advertisingPartners.p3')}
            </p>
            <h3 className='privacy-policy__title'>{t('privacyPolicyPage.thirdParty.title')}</h3>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.thirdParty.p1')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.thirdParty.p2')}</p>
            <h3 className='privacy-policy__title'>{t('privacyPolicyPage.ccpaRights.title')}</h3>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.ccpaRights.p1')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.ccpaRights.p2')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.ccpaRights.p3')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.ccpaRights.p4')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.ccpaRights.p5')}</p>
            <h3 className='privacy-policy__title'>{t('privacyPolicyPage.gdpr.title')}</h3>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.gdpr.p1')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.gdpr.p2')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.gdpr.p3')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.gdpr.p4')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.gdpr.p5')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.gdpr.p6')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.gdpr.p7')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.gdpr.p8')}</p>
            <h3 className='privacy-policy__title'>{t('privacyPolicyPage.childrenInfo.title')}</h3>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.childrenInfo.p1')}</p>
            <p className='privacy-policy__paragraph'>{t('privacyPolicyPage.childrenInfo.p2')}</p>
          </div>
        </section>
      </main>
      <Footer openSignUpModal={openSignUpModal} />
    </>
  );
};

export default PrivacyPolicyPage;
