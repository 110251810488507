import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import TextareaAutosize from "react-textarea-autosize";
import {Fragment, useContext, useEffect, useState} from "react";
import AppContext from "../store/AppContext";
import useInput from "../utils/Input";
import NotificationsService from "../services/NotificationsService";

const SupportPage = () => {
    const {t} = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const context = useContext(AppContext);
    const [showErrors, setShowErrors] = useState(false);

    const title = useInput('', {
        isEmpty: true,
        minLength: 5,
        maxLength: 50
    });

    const content = useInput('', {
        isEmpty: true,
        minLength: 50,
        maxLength: 500
    });
    
    const sendSupportRequest = () => {
        if (content.isValid && title.isValid && !submitted) {
            setSubmitted(true);
            NotificationsService.sendBugReport(title.value, content.value).catch(error => {
                window.alert(error);
            });
        }
        else {
            setShowErrors(true);
        }
    };
    
    const renderAuthenticated = () => {
        return (
        <Fragment>
            {!submitted ? (
                <Fragment>
                    {content.minLengthError && showErrors && <div className="support__error">{t('supportPage.contentMinLengthError')}</div>}
                    {content.maxLengthError && showErrors && <div className="support__error">{t('supportPage.contentMaxLengthError')}</div>}
                    {title.minLengthError && showErrors && <div className="support__error">{t('supportPage.titleMinLengthError')}</div>}
                    {title.maxLengthError && showErrors && <div className="support__error">{t('supportPage.titleMaxLengthError')}</div>}
                    <div className="support__input-wrapper">
                        <div className='support__block-title'>
                            {t('supportPage.title')}
                        </div>
                        <input
                            className='support__input support__title'
                            id='title-input'
                            name='title'
                            autoComplete='off'
                            maxLength='300'
                            disabled={submitted}
                            value={title.value}
                            onChange={e => title.onChange(e)}
                            onBlur={e => title.onBlur(e)}
                        />
                    </div>
                    <div className="support__input-wrapper">
                        <div className='support__block-title'>
                            {t('supportPage.description')}
                        </div>
                        <TextareaAutosize
                            className='support__input support__input--textarea support__description'
                            id='title-description'
                            disabled={submitted}
                            name='description'
                            autoComplete='off'
                            maxLength='300'
                            placeholder={t('supportPage.descriptionPlaceholder')}
                            value={content.value}
                            onChange={e => content.onChange(e)}
                            onBlur={e => content.onBlur(e)}
                        />
                    </div>
                </Fragment>
            ) : (
                <div className="support__input-wrapper">
                    {t('supportPage.submittedConfirmation')}
                </div>
            )}
            <div>
                <button
                    type='button'
                    id='submit-button'
                    disabled={submitted}
                    onClick={sendSupportRequest}
                    className='support__submit-button'>
                    {t('supportPage.submit')}
                </button>
            </div>
        </Fragment>);
    }
    
    const renderUnauthenticated = () => {
        return (
            <Fragment>
                {t('supportPage.pleaseContactUsAt')}
                <div className="support__input-wrapper">
                    <a className='support__email' href='mailto:support@gyre.tv'>
                        support@gyre.tv
                    </a>
                </div>
                <div className="support__input-wrapper">
                    <a
                        href='https://discord.gg/ZeSeUt3wEj'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Discord'>
                        {t('footer.discordBtn')}
                    </a>
                </div>
            </Fragment>
        )
    }
    
    return (    
    <>
        <Helmet>
            <title>{t('supportPage.helmetTitle')}</title>
        </Helmet>
        <section className='main'>
            <div className='main__wrapper'>
                <h2 className='main__title'>{t('supportPage.mainTitle')}</h2>
                <p className='main__description'>
                    {t('supportPage.mainDescription')}
                    <a
                        className='support__link-inline'
                        href='https://discord.gg/ZeSeUt3wEj'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Discord'>
                        {t('footer.discordBtn')}
                    </a>
                </p>
                {context.isAuthenticated ? renderAuthenticated() : renderUnauthenticated()}
            </div>
        </section>
    </>);
};

export default SupportPage;