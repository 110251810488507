import TwitchUserLink from '../TwitchUserLink';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import GyreSpinner from '../GyreSpinner';
import StreamerGames from '../find-collab/StreamerGames';
import {ReactComponent as TwitchIcon} from '../../assets/images/twitch-icon.svg';
import {ReactComponent as ChatIcon} from '../../assets/images/new-chat-icon.svg';
import {useEffect} from "react";
import {PartnershipStatus} from "../../utils/Collections";

const Events = ({
  onClick,
  eventsList,
  gameList,
  loading,
  loggedInStatus,
  openSignUpModal,
  scrollToTop  
}) => {
  const {t} = useTranslation();

  const renderStreamerInfo = (event) => {
    return (
        <>
          <div
              className={`streamers__avatar-wrapper streamer-info__avatar-wrapper ${
                  event.streamer.is_content_creator ? 'streamer-info__avatar-wrapper--system' : (event.streamer.is_registered ? 'streamer-info__avatar-wrapper--verified' : '')
              }`}>
            <img
                className='streamers__avatar streamer-info__avatar'
                src={event.streamer.is_content_creator ?  require('../../assets/images/logo-min.svg').default: event.streamer.picture}
                alt=''
                title={event.streamer.is_content_creator ? "Moderator" : null}
            />
          </div>
          <div className='streamers__nickname-wrapper streamer-info__nickname-wrapper'>
                  <span className='streamers__nickname streamer-info__nickname'>
                    {event.streamer.twitch_username}
                  </span>
            {event.streamer.is_registered ? (
                <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                          src={require('../../assets/images/gyre-verified-icon.svg').default}
                          alt='gyre verified' title='Registered Gyre user'
                      />
                    </span>
            ) : null}

            {event.streamer.is_content_creator ? (
                <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                          src={require('../../assets/images/twitch-system-icon.svg').default}
                          alt='Gyre system' title='Gyre System'
                      />
                    </span>
            ) : (event.streamer.partner === PartnershipStatus.Partner ? (
                <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                          src={require('../../assets/images/twitch-partner-icon.svg').default}
                          alt='twitch partner' title='Twitch Partner'
                      />
                    </span>
            ) : null)}
          </div>
          {event.streamer.is_content_creator && event.streamer.avg_viewers < 30 ? null : (
              <TwitchUserLink
                  classNames='events__twitch-link '
                  link={`https://www.twitch.tv/${event.streamer.twitch_username}`}
              />)
          }
          {event.streamer.is_registered ? (
              <Link className='streamers__chat-link' to={`/chat/${event.streamer.id}`}>
                <ChatIcon />
                {t('gyreEvents.apply')}
              </Link>
          ) : null}
        </>
    );
  };

  useEffect(() => {
    const htmlElement = document.getElementById('gyre_event_container');
    if (htmlElement && scrollToTop) {
      htmlElement.parentElement.scrollIntoView({behavior: "smooth"});
    }
  }, [eventsList]);

  if (loading) {
    return <GyreSpinner loading={true} size={70} />;
  } else if (eventsList.length === 0) {
    return <h2 id='gyre_event_container'>{t('gyreEvents.noResult')}</h2>;
  } else {
    return (
      <section className='events' id='gyre_event_container'>
        <ul
          className={`events__list ${
            loggedInStatus === false ? 'events__list--not-logged-in' : ''
          }`}>
          {eventsList.map(event => (
            <li
              className={`events__card streamer-info__card ${
                loggedInStatus === false ? 'events__card--not-logged-in' : ''
              }`}
              key={event.id}>
              <div className='streamers__about streamer-info__about'>
                {renderStreamerInfo(event)}
                
                <button
                  className='events__suggest-button suggest-button'
                  onClick={() => {
                    if (loggedInStatus) {
                      onClick(event);
                    } else {
                      openSignUpModal();
                    }
                  }}>
                  {t('viewDetails')}
                </button>
                <div className='streamers__break streamer-info__break' />
                <StreamerGames
                  classNames='streamers__games-list'
                  gameList={gameList}
                  streamerGameIds={event.game_ids}
                />
              </div>
              <div className='streamers__info streamer-info__info'>
                <div className='events__event-title'>{event.event_name}</div>
                <div className='events__event-desc'>{event.description}</div>
              </div>
            </li>
          ))}
        </ul>
        {loggedInStatus ? null : (
          <div className='events__login-message'>
            <h2 className='events__login-text'>
              {t('gyreEvents.loginStatus.p1')} <br /> <span>{t('gyreEvents.loginStatus.p2')}</span>
            </h2>
            <button className='primary-button events__login-button' onClick={openSignUpModal}>
              <TwitchIcon />
              {t('gyreEvents.loginStatus.signUp')}
            </button>
          </div>
        )}
      </section>
    );
  }
};

export default Events;
