import {useTranslation} from 'react-i18next';

const NextStepBtn = ({onClick}) => {
  const {t} = useTranslation();

  return (
    <button className='next-step-button' type='button' onClick={onClick}>
      {t('nextStepBtn')}
    </button>
  );
};

export default NextStepBtn;
