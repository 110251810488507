const {REACT_APP_GYRE_BACKEND} = process.env;
const CACHE_KEY = 'gyre_user' + REACT_APP_GYRE_BACKEND;

class AuthService {  

  static checkAuthorization = async (onSuccess) => {
    let cacheUser = this.getUser();
    if (cacheUser && ((new Date() - cacheUser.sessionCreated)/1000/60) < 1) {
      // cache user token for 1 minute
      if (onSuccess)
        onSuccess(cacheUser);
      
      return cacheUser;
    }

    let user = null;
    await fetch(`${REACT_APP_GYRE_BACKEND}/api/me`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then(data => {
      if (data.ok) {
        user = data.json();
        if (onSuccess)
          onSuccess(user);
      }
    }).catch(error => {
      console.log(error);
      user = null;
      window.sessionStorage.clear();
      localStorage.clear();
    });

    if (user) await user.then(u => {
      u.sessionCreated = new Date();
      window.sessionStorage.setItem(CACHE_KEY, JSON.stringify(u));
    });

    return user;
  };

  static getUser = () => {
    const user = window.sessionStorage.getItem(CACHE_KEY);
    if (user)
      return JSON.parse(user);
    else 
      return null;
  };

  static clearUser = () => {
    window.sessionStorage.setItem(CACHE_KEY, null);
  };


}

export default AuthService;