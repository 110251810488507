import queryString from "query-string";
const {REACT_APP_GYRE_BACKEND} = process.env;

const EventStatuses = {
    pending: 'PENDING',
    completed: 'COMPLETED',
    cancelled: 'CANCELLED',
    error: 'ERROR'
}

class EventsService {
    static getEvent = async (eventId) => {
        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/event/${eventId}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }).then(res => res.json());
    }

    static getEvents = async (params) => {
        const reqParams = queryString.stringify(params, {
            skipEmptyString: true,
            skipNull: true
        });
        
        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/event/search?${reqParams}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }).then(res => res.json());
    }

    static getMyEvents = async (sortingMethod, pendingOnly=false) => {
        const reqParams = queryString.stringify({
            pending_only: pendingOnly,
            sorting: sortingMethod
        }, {
            skipEmptyString: true,
            skipNull: true
        });

        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/event/my_events?${reqParams}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }).then(res => res.json());
    }
    
    static getEventResponses = async (eventId) => {
        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/event/${eventId}/response`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then(res => res.json())
            .catch(error => {
                console.log(error);
            });
    }

    static getMyEventsLimit = async () => {
        const eventData = await fetch(`${REACT_APP_GYRE_BACKEND}/api/event/limits`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then(res => res.json())
            .catch(error => {
                console.log(error);
            });

        return {
            available: eventData.available,
            active: eventData.count,
            count: eventData.count,
            limit: eventData.limit
        }
    };

    static getSubmittedEvents = async (sortingMethod) => {
        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/event/my_responses?sorting=${sortingMethod}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then(res => res.json())
            .catch(error => {
                console.log(error);
            });
    };
}

export default EventsService;
export {EventStatuses};