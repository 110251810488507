import {Helmet} from 'react-helmet';
import {useContext, useEffect, useState} from 'react';
import CollabRequestModal from '../components/my-collab-requests/CollabRequestModal';
import {useTranslation} from 'react-i18next';
import CollabRequestsSorting from '../components/my-collab-requests/CollabRequestsSorting.js';
import CollabCard from '../components/my-collab-requests/CollabCard.js';
import GyreSpinner from '../components/GyreSpinner.js';
import ConfirmCollabModal from '../components/ConfirmCollabModal.js';
import StreamersService from "../services/StreamersService";
import AppContext from "../store/AppContext";
import CollabService from "../services/CollabService";

const MyCollabRequestsPage = () => {
  const {t} = useTranslation();
  const {REACT_APP_GYRE_BACKEND} = process.env;
  const context = useContext(AppContext);
  
  const [requestsCategory, setRequestsCategory] = useState('incoming');
  const [gamesList, setGamesList] = useState();
  const [timezoneList, setTimezoneList] = useState();
  const [collabs, setCollabs] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedCollab, setSelectedCollab] = useState();
  const [selectedCollabStatus, setSelectedCollabStatus] = useState('');
  const [sortingMethod, setSortingMethod] = useState('newest');
  const [checkedFilters, setCheckedFilters] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [newStatus, setNewStatus] = useState('');

  const [collabModal, setCollabModal] = useState(false);
  const openCollabModal = () => {
    setCollabModal(!collabModal);
  };

  const collabStatuses = {
    pending: 'PENDING',
    noResponse: 'NO_RESPONSE',
    accepted: 'ACCEPTED',
    rejected: 'REJECTED',
    completed: 'COMPLETED',
    cancelled: 'CANCELLED',
    error: 'error'
  };
  
  useEffect(() => {
    let hasPending = false;
    for (let collab in collabs) {
      if (collab.status === collabStatuses.pending) {
        hasPending = true;
        break;
      }
    }
    
    CollabService.hasPendingCollabRequests.set(hasPending);
  }, [newStatus]);

  const getCollabData = async () => {
    setLoading(true);
    const streamersIds = [];
    const collabData = await fetch(
      `${REACT_APP_GYRE_BACKEND}/api/collab_request?sorting=${sortingMethod}`,
      {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      }
    )
      .then(res => res.json())
      .catch(error => {
        console.log(error);
      });

    await collabData.created.forEach(item => {
      streamersIds.push(item.receiver_id);
    });

    await collabData.received.forEach(item => {
      streamersIds.push(item.sender_id);
    });

    const streamersInfoData = await StreamersService.getStreamersByIds(streamersIds, false, false, false)
      .catch(error => {
        console.log(error);
      });

    setGamesList(streamersInfoData.games);
    setTimezoneList(streamersInfoData.streamers_timezone);

    collabData.created.forEach(collab => {
      streamersInfoData.streamers.forEach(streamer => {
        if (collab.receiver_id === streamer.id) {
          collab.streamer = streamer;
        }
      });
    });

    collabData.received.forEach(collab => {
      streamersInfoData.streamers.forEach(streamer => {
        if (collab.sender_id === streamer.id) {
          collab.streamer = streamer;
        }
      });
    });

    setCollabs(collabData);
    setLoading(false);
  };

  useEffect(() => {
    getCollabData();
  }, [sortingMethod]);

  const determineStatus = status => {
    switch (status) {
      case collabStatuses.noResponse:
        return t('statuses.filters.noResponse');
      case collabStatuses.pending:
        return t('statuses.filters.pending');
      case collabStatuses.rejected:
        return t('statuses.filters.rejected');
      case collabStatuses.accepted:
        return t('statuses.filters.accepted');
      case collabStatuses.completed:
        return t('statuses.filters.completed');
      case collabStatuses.cancelled:
        return t('statuses.filters.cancelled');
      default:
        break;
    }
  };

  const renderCollabList = arr => {
    return arr.map((collab, i) => {
      if (checkedFilters.length !== 0) {
        if (checkedFilters.includes(collab.status)) {
          return (
            <CollabCard
              key={collab.id}
              userId={context.user.id}
              collabStatuses={collabStatuses}
              collab={collab}
              determineStatus={determineStatus}
              openCollabModal={openCollabModal}
              getSelectedCollab={getSelectedCollab}
              setNewStatus={setNewStatus}
              showConfirmModal={showConfirmModal}
            />
          );
        }
      } else {
        return (
          <CollabCard
            key={i}
            userId={context.user.id}
            collabStatuses={collabStatuses}
            collab={collab}
            determineStatus={determineStatus}
            openCollabModal={openCollabModal}
            getSelectedCollab={getSelectedCollab}
            newStatus={newStatus}
            setNewStatus={setNewStatus}
            showConfirmModal={showConfirmModal}
          />
        );
      }
    });
  };

  const getSelectedCollab = (collabId, status) => {
    setSelectedCollabStatus(status);
    if (requestsCategory === 'outgoing') {
      collabs.created.forEach(collab => {
        if (collab.id === collabId) {
          setSelectedCollab(collab);
        }
      });
    } else if (requestsCategory === 'incoming') {
      collabs.received.forEach(collab => {
        if (collab.id === collabId) {
          setSelectedCollab(collab);
        }
      });
    }
  };

  const showConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  return (
    <>
      <Helmet>
        <title>{t('myCollabRequestsPage.helmetTitle')}</title>
      </Helmet>
      <section className='my-collab-requests'>
        <div className='my-collab-requests__container'>
          <div className='my-collab-requests__header'>
            <div className='my-collab-requests__title-wrapper'>
              <h2 className='main__title'>{t('myCollabRequestsPage.mainTitle')}</h2>
            </div>
            <div className='my-collab-requests__info-wrapper'>
              <div className='my-collab-requests__info'>
                <p className='my-collab-requests__info-description'>
                  {t('myCollabRequestsPage.wrapperInfo')}
                  <span>:</span>
                </p>
                <span className='my-collab-requests__info-counter'>
                  {!loading ? collabs.count : '-'}
                </span>
              </div>
              <div className='my-collab-requests__info'>
                <p className='my-collab-requests__info-description'>
                  {t('myCollabRequestsPage.counterInfo')}
                  <span>:</span>
                </p>
                <span className='my-collab-requests__info-counter'>
                  {!loading ? (collabs.limit - collabs.count) : '-'}
                </span>
              </div>
            </div>
          </div>
          {/*<div className='my-collab-requests__warning-message'>*/}
          {/*  <h3 className='my-collab-requests__warning-title'>*/}
          {/*    {t('myCollabRequestsPage.warningMessage.title')}*/}
          {/*  </h3>*/}
          {/*  <p className='my-collab-requests__warning-description'>*/}
          {/*    {t('myCollabRequestsPage.warningMessage.description')}*/}
          {/*  </p>*/}
          {/*  <button className='my-collab-requests__warning-button'>*/}
          {/*    {t('myCollabRequestsPage.warningMessage.button')}*/}
          {/*  </button>*/}
          {/*</div>*/}
          <div className='my-collab-requests__switcher'>
            <div className='my-collab-requests__switcher-wrapper'>
              <button
                  className={`my-collab-requests__switch-button ${
                      requestsCategory === 'incoming'
                          ? 'my-collab-requests__switch-button--current'
                          : ''
                  }`}
                  onClick={() => {
                    setRequestsCategory('incoming');
                    setCheckedFilters([]);
                  }}>
                {t('myCollabRequestsPage.incomingRequests')}
              </button>
              <button
                className={`my-collab-requests__switch-button ${
                  requestsCategory === 'outgoing'
                    ? 'my-collab-requests__switch-button--current'
                    : ''
                }`}
                onClick={() => {
                  setRequestsCategory('outgoing');
                  setCheckedFilters([]);
                }}>
                {t('myCollabRequestsPage.outgoingRequests')}
              </button>
            </div>
            <p className='my-collab-requests__switcher-description'>
              {requestsCategory === 'outgoing'
                ? t('myCollabRequestsPage.outgoingDescription')
                : t('myCollabRequestsPage.incomingDescription')}
            </p>
          </div>
          <div className='my-collab-requests__main-container'>
            <CollabRequestsSorting
              collabStatuses={collabStatuses}
              checkedFilters={checkedFilters}
              setCheckedFilters={setCheckedFilters}
              sortingMethod={sortingMethod}
              setSortingMethod={setSortingMethod}
              loading={loading}
              requestsCategory={requestsCategory}
            />
            <div className='my-collab-requests__requests-wrapper'>
              {loading ? (
                <GyreSpinner loading={true} size={70} />
              ) : (
                <ul className='my-collab-requests__collab-list' id='collab-list'>
                  {requestsCategory === 'outgoing'
                    ? renderCollabList(collabs.created)
                    : requestsCategory === 'incoming'
                      ? renderCollabList(collabs.received)
                      : null}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
      {collabModal ? (
        <CollabRequestModal
          onClick={openCollabModal}
          gamesList={gamesList}
          timezoneList={timezoneList}
          collabStatuses={collabStatuses}
          selectedCollab={selectedCollab}
          determineStatus={determineStatus}
          selectedCollabStatus={selectedCollabStatus}
          setSelectedCollabStatus={setSelectedCollabStatus}
          setNewStatus={setNewStatus}
          showConfirmModal={showConfirmModal}
        />
      ) : null}
      {confirmModal && newStatus === collabStatuses.accepted ? (
        <ConfirmCollabModal
          title={t('collaborationRequestPage.confirmModalTitle')}
          message={t('collaborationRequestPage.acceptedMessage')}
          onClick={showConfirmModal}
          goTo={window.location.pathname}
        />
      ) : confirmModal && newStatus === collabStatuses.rejected ? (
        <ConfirmCollabModal
          title={t('collaborationRequestPage.confirmModalTitle')}
          message={t('collaborationRequestPage.rejectedMessage')}
          onClick={showConfirmModal}
          goTo={window.location.pathname}
        />
      ) : confirmModal && newStatus === collabStatuses.error ? (
        <ConfirmCollabModal
          title={t('suggestCollabComponent.form.ModalErrorTitle')}
          message={t('suggestCollabComponent.form.ModalErrorMessage')}
          onClick={showConfirmModal}
          showEmail={true}
          goTo={window.location.pathname}
        />
      ) : null}
    </>
  );
};

export default MyCollabRequestsPage;
