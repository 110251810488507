import {Link, withRouter} from 'react-router-dom';
import LandingNavigation from '../landing-page/LandingNavigation';
import NavigationBar from './NavigationBar';
import UserBar from './UserBar';
import {useContext} from "react";
import AppContext from "../../store/AppContext";

const PageHeader = ({
    openSignUpModal
}) => {
    
    const context = useContext(AppContext);
  return (
    <header
      className={`page-header ${
        window.location.pathname === '/' ? 'page-header--transparent' : ''
      } ${window.location.pathname !== '/' ? 'page-header--white' : ''}`}>
      <div className="page-header__wrapper">
        {!context.isAuthenticated ? (
          <LandingNavigation />
        ) : (
          <NavigationBar />
        )}
        <Link className="page-header__logo-link" to="/">
          <div className="page-header__logo" />
        </Link>
        <UserBar openSignUpModal={openSignUpModal} />
      </div>
    </header>
  );
};

export default withRouter(PageHeader);
