const SentMessage = ({
  message,
  date
}) => {
  return (
    <div className='chat-window__message-wrapper chat-window__message-wrapper--sent'>
      <div className='chat-window__message-block-wrapper chat-window__message-block-wrapper--sent'>
        <div className='chat-window__message-block chat-window__message-block--sent'>
          <p className='chat-window__message-text chat-window__message-text--sent'>
            {message}
          </p>
        </div>
        <span className='chat-window__message-time'>{date}</span>
      </div>
    </div>
  );
};

export default SentMessage;