import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const cookiesAgreement = () => {
  if (!localStorage.getItem('cookiesAgreement')) {
    localStorage.setItem('cookiesAgreement', JSON.stringify({accept: false}));
  }
};

const CookieBanner = ({cookiesAccept}) => {
  const {t} = useTranslation();

  cookiesAgreement();

  const agreement = JSON.parse(localStorage.getItem('cookiesAgreement'));

  if (!agreement.accept) {
    return (
      <section className='cookie-banner'>
        <div className='cookie-banner__container'>
          <div className='cookie-banner__modal'>
            <div className='cookie-banner__wrapper'>
              <h3 className='cookie-banner__title'>{t('cookieBanner.title')}</h3>
              <p className='cookie-banner__description'>
                {t('cookieBanner.description')}{' '}
                <Link className='cookie-banner__privacy-link' to='/privacy-policy'>
                  {t('cookieBanner.privacyPolicyLink')}
                </Link>
              </p>
            </div>
            <button
              className='cookie-banner__accept-button red-bordered-button'
              onClick={() => {
                cookiesAccept();
                localStorage.setItem('cookiesAgreement', JSON.stringify({accept: true}));
              }}>
              {t('cookieBanner.acceptBtn')}
            </button>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export default CookieBanner;
