import {useState} from 'react';
import ImagePreview from './ImagePreview';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowRightIcon} from '../../assets/images/arrow-right-icon.svg';
import {ReactComponent as SearchIcon} from '../../assets/images/search.svg';
import {Link} from 'react-router-dom';

const Steps = () => {
  const [imageView, setImageView] = useState(false);
  const [imageNumber, setImageNumber] = useState(null);
  const openImagePreview = number => {
    setImageView(!imageView);
    setImageNumber(number);
  };

  const {t} = useTranslation();

  return (
    <section className='steps' id='steps'>
      <div className='steps__wrapper'>
        <h2 className='steps__title h2-title'>{t('landingPage.steps.title')}</h2>
        <ol className='steps__step-list'>
          <li
            className='steps__step-item steps__step-item--1'
            onClick={() => {
              openImagePreview('1');
            }}>
            <div className='steps__step-image-wrapper'>
              <div className='steps__step-image-open'>
                <SearchIcon />
              </div>
              <img
                className='steps__step-image'
                src={require('../../assets/images/step-1@1x.jpg')}
                srcSet={`${require('../../assets/images/step-1@2x.jpg')} 2x`}
                alt=''
              />
            </div>
            <p className='steps__step-description'>{t('landingPage.steps.step1')}</p>
          </li>
          <li
            className='steps__step-item steps__step-item--2'
            onClick={() => {
              openImagePreview('2');
            }}>
            <div className='steps__step-image-wrapper'>
              <div className='steps__step-image-open'>
                <SearchIcon />
              </div>
              <img
                className='steps__step-image'
                src={require('../../assets/images/step-2@1x.jpg')}
                srcSet={`${require('../../assets/images/step-2@2x.jpg')} 2x`}
                alt=''
              />
            </div>
            <p className='steps__step-description'>{t('landingPage.steps.step2')}</p>
          </li>
          <li
            className='steps__step-item steps__step-item--3'
            onClick={() => {
              openImagePreview('3');
            }}>
            <div className='steps__step-image-wrapper'>
              <div className='steps__step-image-open'>
                <SearchIcon />
              </div>
              <img
                className='steps__step-image'
                src={require('../../assets/images/step-3@1x.jpg')}
                srcSet={`${require('../../assets/images/step-3@2x.jpg')} 2x`}
                alt=''
              />
            </div>
            <p className='steps__step-description'>{t('landingPage.steps.step3')}</p>
          </li>
          <Link className='steps__step-link' to='/find-collab'>
            <li className='steps__step-item steps__step-item--last'>
              <div className='steps__step-item-btn'>
                <ArrowRightIcon />
              </div>
              <p className='steps__step-description'>{t('landingPage.steps.step4')}</p>
            </li>
          </Link>
        </ol>
      </div>
      {imageView ? <ImagePreview image={imageNumber} onClick={openImagePreview} /> : null}
    </section>
  );
};

export default Steps;
