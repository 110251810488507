import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ReactComponent as DiscordIcon} from '../../assets/images/discord-icon.svg';
import {ReactComponent as PencilIcon} from '../../assets/images/pencil-icon.svg';
import {ReactComponent as ArrowRightIcon} from '../../assets/images/arrow-right-icon.svg';

const BottomBanners = ({toggleCreateEventModal}) => {
  const {t} = useTranslation();

  return (
    <div className='bottom-banners__wrapper'>
      <section className='bottom-banners__collab-hub'>
        <div className='bottom-banners__text'>
          <div className='big-text'>{t('bottomBanners.collabHubText1')}</div>
          <div className='small-text'>{t('bottomBanners.collabHubText2')}</div>
        </div>
        <div className='bottom-banners__icon'>
          <PencilIcon />
        </div>
        <div className='bottom-banners__link'>
          <a
            className='bottom-banners__collab-hub-btn primary-button'
            onClick={() => toggleCreateEventModal()}>
            <ArrowRightIcon className='small-arrow' />
            {t('bottomBanners.collabHubBtnText')}
          </a>
        </div>
      </section>
      <section className='bottom-banners__discord'>
        <div className='bottom-banners__text'>
          <div className='big-text'>{t('bottomBanners.discordText1')}</div>
          <div className='small-text'>{t('bottomBanners.discordText2')}</div>
        </div>
        <div className='bottom-banners__icon'>
          <DiscordIcon />
        </div>
        <div className='bottom-banners__link'>
          <a
            className='bottom-banners__discord-btn primary-button'
            href='https://discord.gg/ZeSeUt3wEj'
            target='_blank'
            rel='noreferrer'
            aria-label='Discord'>
            <ArrowRightIcon className='small-arrow' />
            {t('bottomBanners.discordBtnText')}
          </a>
        </div>
      </section>
    </div>
  );
};

export default BottomBanners;
