import queryString from "query-string";
const {REACT_APP_GYRE_BACKEND} = process.env;

class GamesService {
    static getGames = async (gamesIds) => {
        return await fetch(
            `${REACT_APP_GYRE_BACKEND}/api/games?${queryString.stringify(
                {
                    game_ids: gamesIds
                })
            }`, 
            {
                    method: 'GET',
                    credentials: 'include',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    }
                }
        ).then(res => res.json()).catch(error => {
            console.log(error);
        });
    }

    static getGamesByName = async (name) => {
        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/games?name=${name}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }).then(res => res.json());
    }
}

export default GamesService;