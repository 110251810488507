import {Helmet} from 'react-helmet';
import {useEffect, useState} from 'react';
import Collapsible from 'react-collapsible';
import Sorting from '../components/find-collab/Sorting';
import Events from '../components/events/Events';
import Filters from '../components/find-collab/Filters';
import Pagination from '../components/Pagination';
import BottomBanners from '../components/find-collab/BottomBanners';
import {useTranslation} from 'react-i18next';
import EventDetailsModal from '../components/events/EventDetailsModal';
import EventCreateModal from '../components/events/EventCreateModalDialog';
import SettingsService from '../services/SettingService.js';
import {useParams} from "react-router-dom";
import EventsService from "../services/EventsService";
import StreamersService from "../services/StreamersService";
import GamesService from "../services/GamesService";
import {getAbilityToRespondForCurrentUser, setMaxValue, setMinValue, useWindowSize} from "../utils/Utils";

const moment = require('moment');
let searchTimeout = 0;

const FindEventsPage = ({
  api,
  loggedInStatus,
  openSignUpModal,
  user 
}) => {
  const {t} = useTranslation();
  const [width] = useWindowSize();
  
  const {eventId} = useParams();
  const [searchByEventId, setSearchByEventId] = useState(true);
  
  const [scrollToTop, setScrollToTop] = useState(false);
  const [paginationOffsetIndex, setPaginationOffsetIndex] = useState(0);
  const [count, setCount] = useState();
  const [sortingMethod, setSortingMethod] = useState('newest');
  const [userName, setUserName] = useState('');
  const [eventsList, setEventsList] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [streamDays, setStreamDays] = useState([]);
  const [hoursMin, setHoursMin] = useState();
  const [hoursMax, setHoursMax] = useState();
  const [language, setLanguage] = useState(SettingsService.getUserLanguage());
  const [partners, setPartners] = useState('');
  const [game, setGame] = useState('');
  const [followers, setFollowers] = useState([]);
  const [avgViewers, setAvgViewers] = useState([]);
  const [mature, setMature] = useState([]);
  const [activeStreamers, setActiveStreamers] = useState(false);
  const [gyreOnly, setGyreOnly] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [abilityToRespondToAnEvent, setAbilityToRespondToAnEvent] = useState(false);

  const [needApplyFilters, setNeedApplyFilters] = useState(false);

  const applyFilters = (delay = 100) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setNeedApplyFilters(true);
    }, delay);
  }

  const sortingOptions = [
    {
      value: 'newest',
      label: t('myEventRequestsPage.newest')
    },
    {
      value: 'oldest',
      label: t('myEventRequestsPage.oldest')
    }
  ];

  useEffect(() => {
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const param = urlParams.get('admin');
    if (param === 'true') {
      setAdmin(true);
    }
  }, []);
  
  const ITEMS_PER_PAGE = 7;

  const getUtcTime = (time) => {
    return moment(time, 'HH:mm').utc().format('H');
  };

  const resetFilters = () => {
    setSortingMethod('newest');
    setFollowers([]);
    setAvgViewers([]);
    setHoursMin(null);
    setHoursMax(null);
    setStreamDays([]);
    setLanguage(SettingsService.getUserLanguage());
    setPartners('');
    setGame('');
    setMature([]);
    setActiveStreamers(false);
    setGyreOnly(false);
    setUserName('');
    setPaginationOffsetIndex(0);
    setSearchByEventId(false);
  };
  
  const getEvents = async () => {
    setLoading(true);
    const streamersIds = [];
    let gamesIds = [];
    let events = [];
    let eventsCount = 0;
    let eventsData;
    
    if (searchByEventId && eventId) {
      eventsData = await EventsService.getEvent(eventId);
      
      if (eventsData) {
        const event = eventsData.event;
        event.streamer = eventsData.author;
        gamesIds = event.game_ids;
        events.push(event);
        eventsCount = 1;
        setSelectedEvent(event);
      }
    }
    else {
      let params;
      
      if (userName && userName.length > 3) {
        // search by user name only
        params = {
          username: userName,
          active_only: false,
          registered_only: false,
          has_email_only: false,
          limit: ITEMS_PER_PAGE,
          offset: paginationOffsetIndex
        };
      }
      else {
        params = {
          sorting: sortingMethod,
          followers_min: setMinValue(followers, '10000'),
          followers_max: setMaxValue(followers, '10000'),
          avg_viewers_min: setMinValue(avgViewers, '1000'),
          avg_viewers_max: setMaxValue(avgViewers, '1000'),
          stream_hours_min: hoursMin ? getUtcTime(hoursMin) : null,
          stream_hours_max: hoursMax ? getUtcTime(hoursMax) : null,
          stream_days: streamDays,
          languages: language,
          partnerships: partners,
          game_ids: game,
          mature: mature,
          active_only: activeStreamers,
          registered_only: gyreOnly,
          valid_only: false,
          offset: paginationOffsetIndex,
          username: userName || '',
          limit: ITEMS_PER_PAGE
        };
      }
      
      eventsData = await EventsService.getEvents(params);
      events = eventsData.events;
      eventsCount = eventsData.meta.count;

      await events.forEach(item => {
        streamersIds.push(item.author_id);
        item.game_ids.forEach(game => gamesIds.push(game));
      });
      
      const streamersData = await StreamersService.getStreamersByIds(streamersIds, false, false, false);

      events.forEach(event => {
        event.streamer = {};
        for (const streamer of streamersData.streamers) {
          if (event.author_id === streamer.id) {
            event.streamer = streamer;
            break;
          }
        }
      });
    }

    setCount(eventsCount);
    
    const games = await GamesService.getGames(gamesIds);

    setGameList(games.data);

    setEventsList(events);
    setLoading(false);
  };

  useEffect(() => {
    if (userName.length > 2 || !userName) {
      setPaginationOffsetIndex(0);
      applyFilters(1000);
    }
  }, [userName]);

  useEffect(() => {
    setPaginationOffsetIndex(0);
    applyFilters();
  }, [sortingMethod]);

  useEffect(() => {
    applyFilters();
  }, [paginationOffsetIndex]);

  useEffect(() => {
    if (needApplyFilters) {
      getEvents();
      setNeedApplyFilters(false);
    }
  }, [needApplyFilters]);
  
  const [selectedEvent, setSelectedEvent] = useState();

  const toggleEventDetails = e => {
    setSelectedEvent(e);
    setShowEventDetailsModal(!showEventDetailsModal);
  };

  const toggleCreateEventModal = () => {
    if (loggedInStatus) {
      setShowCreateEventModal(!showCreateEventModal);
    } else {
      openSignUpModal();
    }
  };

  useEffect(() => {
    getAbilityToRespondForCurrentUser(selectedEvent, setAbilityToRespondToAnEvent);
    if (searchByEventId && eventId && selectedEvent) {
      setShowEventDetailsModal(true);
    }
  }, [selectedEvent]);


  return (
    <>
      <Helmet>
        <title>{t('gyreEvents.helmetTitle')}</title>
      </Helmet>
      <section className='main'>
        <div className='main__wrapper'>
          <div className='events__header'>
            <div className='events__title-wrapper'>
              <h2 className='main__title events__title'>{t('gyreEvents.mainTitle')}</h2>
              <p className='main__description events__description'>{t('gyreEvents.mainDescription')}</p>
            </div>
            <button
              className='events__create-event-button' onClick={toggleCreateEventModal}>
              + {t('gyreEvents.createEvent')}
            </button>
          </div>
          <div className='main__container'>
            <aside className='main__filters'>
              <Collapsible trigger={t('filtersComponent.title')} open={width >= 1280}>
                <Filters
                  applyFilters={applyFilters}
                  streamDays={streamDays}
                  setStreamDays={setStreamDays}
                  hoursMin={hoursMin}
                  hoursMax={hoursMax}
                  setHoursMin={setHoursMin}
                  setHoursMax={setHoursMax}
                  language={language}
                  setLanguage={setLanguage}
                  partners={partners}
                  setPartners={setPartners}
                  game={game}
                  setGame={setGame}
                  followers={followers}
                  setFollowers={setFollowers}
                  avgViewers={avgViewers}
                  setAvgViewers={setAvgViewers}
                  mature={mature}
                  setMature={setMature}
                  activeStreamers={activeStreamers}
                  setActiveStreamers={setActiveStreamers}
                  gyreOnly={gyreOnly}
                  setGyreOnly={setGyreOnly}
                  api={api}
                  resetFilters={resetFilters}
                  setPaginationOffsetIndex={setPaginationOffsetIndex}
                  setScrollToTop={setScrollToTop}
                />
              </Collapsible>
            </aside>
            <div className='main__streamers-wrapper'>
              <Sorting
                sortingOptions={sortingOptions}
                loggedInStatus={loggedInStatus}
                sortingMethod={sortingMethod}
                setSortingMethod={setSortingMethod}
                userName={userName}
                setUserName={setUserName}
                setPaginationOffsetIndex={setPaginationOffsetIndex}
              />
              <Events
                onClick={toggleEventDetails}
                eventsList={eventsList}
                gameList={gameList}
                loading={loading}
                loggedInStatus={loggedInStatus}
                openSignUpModal={openSignUpModal}
                admin={admin}
                scrollToTop={scrollToTop}
              />
              <Pagination setScrollToTop={setScrollToTop} paginationOffsetIndex={paginationOffsetIndex} setPaginationOffsetIndex={setPaginationOffsetIndex} count={count} itemsPerPageIndex={5} />
              <section className='bottom-banners'>
                <BottomBanners loggedInStatus={loggedInStatus} openSignUpModal={openSignUpModal} toggleCreateEventModal={toggleCreateEventModal} />
              </section>
            </div>
          </div>
        </div>
      </section>
      {showEventDetailsModal ? (
        <EventDetailsModal
          onCloseClick={toggleEventDetails}
          event={selectedEvent}
          gameList={gameList}
          abilityToRespondToAnEvent={abilityToRespondToAnEvent}
          showEventDetailsModal={showEventDetailsModal}
          setShowEventDetailsModal={setShowEventDetailsModal}
        />
      ) : null}
      {showCreateEventModal ? (
        <EventCreateModal
          onCloseClick={toggleCreateEventModal}
          user={user}
        />
      ) : null}
    </>
  );
};

export default FindEventsPage;
