import ChatListCard from './ChatListCard.js';
import {useTranslation} from 'react-i18next';
import {Link, useParams} from 'react-router-dom';
import {useContext, useEffect, useState} from "react";
import StreamersService from "../../services/StreamersService";
import AppContext from "../../store/AppContext";
import ChatService from "../../services/ChatService";

const ChatList = ({chatData, sentMessageText, onChatSelected}) => {
  const {t} = useTranslation();
  const {chatId} = useParams();
  const [selectedChat, setSelectedChat] = useState(null);
  const context = useContext(AppContext);

  useEffect(() => { 
      if(!selectedChat) {
          let chat = null;
          if (chatData.chats.length > 0) {
              // existing chat
              chat = chatData.chats.find(chat => chat.streamer.id === +chatId);
              if (chat)
                onChatSelectedHandler(chat);
          }
          
          if (!chat) {
              // new chat
              StreamersService.getStreamer(+chatId).then(streamer => {
                  chat = ChatService.createNewChat(context.user, streamer);
                  chatData.chats.unshift(chat);
                  onChatSelectedHandler(chat);
              })

          }
      }
  }, [chatData]);
  
  const onChatSelectedHandler = (chat) => {
      setSelectedChat(chat);
      onChatSelected(chat);
  };
  
  return (
    <section className='chat-list'>
      <div className='chat-list__container'>
        <h2 className='chat-list__title'>{t('ChatsPage.chatList')}</h2>
        <ul className='chat-list__list'>
          {chatData.chats.length !== 0 ? (
            chatData.chats.map(chat => (
              <ChatListCard
                key={chat.streamer.id}
                sentMessageText={sentMessageText}
                onChatSelected={onChatSelectedHandler}
                selected={chat === selectedChat}
                chat={chat}
              />
            ))
          ) : (
            <li className='chat-list__card chat-list__card--empty'>
              <Link to={`/find-collab`} className='chat-list__link-to-chat'>
                <div className='chat-list__avatar-and-status'>
                  <div className='chat-list__avatar-wrapper'>
                    <img
                      className='chat-list__avatar'
                      src={require('../../assets/images/smile-icon.svg').default}
                      alt=''
                    />
                  </div>
                </div>
                <div className='chat-list__info-wrapper chat-list__info-wrapper--empty'>
                  <h3 className={`chat-list__streamer-nickname`}>{t('ChatsPage.futurePartner')}</h3>
                  <div className='chat-list__last-message-wrapper'>
                    <p className='chat-list__last-message'>{t('ChatsPage.clickAndFind')}</p>
                  </div>
                </div>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
};

export default ChatList;
