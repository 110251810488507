import CollaborateNowBtn from '../CollaborateNowBtn';
import {useTranslation} from 'react-i18next';

const Promo = () => {
  const {t} = useTranslation();

  return (
    <section className='promo'>
      <div className='promo__dec promo__dec--border-red' />
      <div className='promo__dec promo__dec--thin-bg-red' />
      <div className='promo__wrapper'>
        <b className='promo__lead'>
          {t('landingPage.promo.leadP1')} <span>{t('landingPage.promo.leadP2')}</span>
        </b>
        <p className='promo__description'>{t('landingPage.promo.description')}</p>
        <CollaborateNowBtn classNames='promo__collaborate-btn' />
      </div>
      <div className='promo__dec promo__dec--thin-bg-white' />
      <div className='promo__dec promo__dec--bold-bg-red' />
    </section>
  );
};

export default Promo;
