import ObservableProperty from "../store/ObservableProperty";
import queryString from "query-string";
import AuthService from "./AuthService";
const {REACT_APP_GYRE_BACKEND} = process.env;

class CollabService {
    static getCurrentUserCollabs = async (params) => {
        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/collab_request`, {
            method: 'GET', credentials: 'include', mode: 'cors', headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then(res => res.json())
            .catch(error => {
                console.log(error);
            });
    }
    
    static sendCollabRequest = (params) => {
        return fetch(`${REACT_APP_GYRE_BACKEND}/api/collab_request`, {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: queryString.stringify(params)
        });
    }

    static getCollabRequestPrefill = async (receiverID, receiverLanguages, senderLanguage) => {
        let language = "en";
/*
        if (senderLanguage !== "en" && receiverLanguages.length > 0) {
            if (receiverLanguages.indexOf("ru") !== -1 && senderLanguage === "ru") language = "ru";
            else if (receiverLanguages.indexOf("es") !== -1 && senderLanguage === "es") language = "es";
            else if (receiverLanguages.indexOf("fr") !== -1 && senderLanguage === "fr") language = "fr";
            else if (receiverLanguages.indexOf("de") !== -1  && senderLanguage === "de") language = "de";
            else if (receiverLanguages.indexOf("it") !== -1  && senderLanguage === "it") language = "it";
        }
*/

        let cachedResponse = localStorage.getItem(AuthService.getUser().streamer_id + receiverID + language);
        try {
            if (cachedResponse) {
                cachedResponse = JSON.parse(cachedResponse);
                return new Promise((resolve) => {
                    resolve(cachedResponse);
                });
            }
        }
        catch (e) {
            localStorage.removeItem(AuthService.getUser().streamer_id + receiverID + language);
        }
        
        const reqParams = queryString.stringify({
            receiver_id: receiverID,
            language: language
        }, {
            skipEmptyString: true,
            skipNull: true
        });
        
        return fetch(`${REACT_APP_GYRE_BACKEND}/api/collab_request/prefill?${reqParams}`, {
            method: 'GET', credentials: 'include', mode: 'cors', headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res) localStorage.setItem(AuthService.getUser().streamer_id + receiverID + language, JSON.stringify(res));
            return res;
        }).catch(error => {
            console.log(error);
        });
    }

    static hasPendingCollabRequests = new ObservableProperty(false);
}


export default CollabService;