import {useEffect, useState} from "react";

const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChange = e => {
        setValue(e.target.value);
    };

    const onBlur = e => {
        setDirty(true);
    };
    
    const setValueFromOutside = (value) => {
        setValue(value);
        setDirty(true);
    };

    return {
        value,
        onChange,
        onBlur,
        ...valid,
        isDirty,
        setValue: setValueFromOutside
    };
};

const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(false);
    const [minLengthError, setMinLengthError] = useState(false);
    const [maxLengthError, setMaxLengthError] = useState(false);
    const [isValidStreamUrl, setIsValidStreamUrl] = useState(true);
    const [isValid, setIsValid] = useState(true);
    
    const validate = () => {
        let isValidState = true;
        for (const validation in validations) {
            switch (validation) {
                case 'minLength':
                    if (value.length < validations[validation])
                    {
                        setMinLengthError(true);
                        isValidState = false;
                    }
                    else {
                        setMinLengthError(false);
                    }
                    break;

                case 'maxLength':
                    if (value.length > validations[validation])
                    {
                        setMaxLengthError(true);
                        isValidState = false;
                    }
                    else {
                        setMaxLengthError(false);
                    }
                    break;

                case 'isEmpty':
                    if (value) {
                        setEmpty(false);
                    }
                    else {
                        setEmpty(true);
                        isValidState = false;
                    }
                    break;

                case 'validStreamUrl':
                    if (checkUrlLeadsOnStreamRecord(value)) {
                        setIsValidStreamUrl(true)
                    }
                    else {
                        setIsValidStreamUrl(false);
                        isValidState = false;
                    }
                    break;

                default:
                    break;
            }
        }
        setIsValid(isValidState);
        return isValidState;
    }

    useEffect(() => {
        validate();
    }, [value]);

    return {
        isEmpty,
        minLengthError,
        maxLengthError,
        isValidStreamUrl,
        isValid: isValid
    };
};

const checkUrlLeadsOnStreamRecord = (url) => {
    const validURLS = [
        'https://www.twitch.tv/',
        'https://twitch.tv/',
        'twitch.tv/',
        'https://www.m.twitch.tv',
        'https://m.twitch.tv',
        'm.twitch.tv/',
        'https://www.youtube.com/',
        'youtube.com/',
        'https://www.m.youtube.com/',
        'm.youtube.com/',
        'https://m.youtube.com/',
        'https://youtube.com/',
        'https://youtu.be/',
        'youtu.be/'
    ]

    for (const item of validURLS) {
        if (url.toLowerCase().startsWith(item)) return true;
    }
    return false;
}

export default useInput;
export {useValidation};