import {useState, useEffect} from 'react';
import TwitchUserLink from '../TwitchUserLink';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg';
import SettingsService from '../../services/SettingService';
import ResponseButtons from '../my-gyre-events/ResponseButtons';
import StreamerGames from '../find-collab/StreamerGames';
import {ReactComponent as ArrowBottomIcon} from '../../assets/images/arrow-bottom-icon.svg';
import {ReactComponent as ChatIcon} from '../../assets/images/new-chat-icon.svg';
import {Link} from 'react-router-dom';
import {ReactComponent as CopyIcon} from "../../assets/images/copy-icon.svg";
import Utils, {
  getAvgViewersBucket,
  getIsMatureString,
  setStreamerLanguage,
  valueToStringOrNoData
} from "../../utils/Utils";
import EventsService, {EventStatuses} from "../../services/EventsService";


const moment = require('moment');
require('moment/min/locales.min');

const GyreEventModal = ({
                          onCloseClick,
                          event,
                          getEventData,
                          gamesOnPage,
                          getGamesForSelectedEvent,
                          requestsCategory,
                          determineStatus,
                          setNewStatus,
                          toggleConfirmModal,
                          toggleEditEventModal,
                          toggleEventModal
                        }) => {
  const {t, i18n} = useTranslation();
  const [listGameNames, setListGameNames] = useState([]);
  const [infoVisible, setInfoVisible] = useState(false);
  const [eventStatus, setEventStatus] = useState();
  const [eventResponsesList, setEventResponsesList] = useState([]);
  const [displayEventResponsesList, setDisplayEventResponsesList] = useState([]);
  const [countEventResponses, setCountEventResponses] = useState(0);


  const getNameGamesSelectedEvent = async () => {
    const gameList = await getGamesForSelectedEvent();

    const gameNames = gameList.map(item => {
      return item.label;
    });

    setListGameNames(gameNames);
  };

  const getEventResponse = async () => {
    if (requestsCategory === 'incoming'){
      return null;
    }
    
    const eventResponses = await EventsService.getEventResponses(event.id);

    setEventResponsesList(eventResponses)
    setDisplayEventResponsesList(eventResponses.slice(0, 3))
    setCountEventResponses(eventResponses.length)
  };

  useEffect(() => {
    getNameGamesSelectedEvent();
    getEventResponse();
  }, []);

  useEffect(() => {
    setEventStatus(event.status);
  }, [event]);

  useEffect(() => {
    if (infoVisible){
      setDisplayEventResponsesList(eventResponsesList)
    } else {
      setDisplayEventResponsesList(eventResponsesList.slice(0, 3))
    }
  }, [infoVisible]);

  const handleClick = () => {
    toggleEditEventModal();
    toggleEventModal();
  };

  const onCopyLinkClick = () => {
    Utils.copyToClipboard(window.location.host + '/gyre-events/' + event.id);
  };

  const getInfoAboutResponsesToMyEvent = () => {
    return (
      <div>
        <div className='my-gyre-events-modal__section-title'>
          {countEventResponses !== 0
              ? countEventResponses + ' ' + t('myGyreEventsModal.countPeopleToJoin')
              : t('myGyreEventsModal.noPeopleToJoin')
          }
        </div>
        {countEventResponses !== 0
          ?
          <div>
            {displayEventResponsesList.map((streamer) => {
              return (
                <div>
                  <div className='my-gyre-events-modal__streamer-card'>
                    <div className='my-gyre-events__streamer-info'>
                      <div
                        className={`my-gyre-events__avatar-wrapper streamer-info__avatar-wrapper ${
                          streamer.is_registered
                            ? 'streamer-info__avatar-wrapper--verified'
                            : ''
                        }`}>
                        <img
                          className='my-gyre-events__streamer-avatar streamer-info__avatar'
                          src={streamer.picture}
                          alt=''
                        />
                      </div>
                      <div
                        className='my-gyre-events__streamer-info-wrapper my-gyre-events__streamer-info-wrapper--modal'>
                        <div className='my-gyre-events__nickname-wrapper streamer-info__nickname-wrapper'>
                      <span className='my-gyre-events__streamer-nickname streamer-info__nickname'>
                        {streamer.twitch_username}
                      </span>
                          {streamer.is_registered ? (
                            <span className='streamer__partner-icon streamer-info__partner-icon'>
                          <img
                            src={require('../../assets/images/gyre-verified-icon.svg').default}
                            alt='gyre verified'
                          />
                        </span>
                          ) : null}
                          {streamer.partner === 'Partner' ? (
                            <span className='streamer__partner-icon streamer-info__partner-icon'>
                          <img
                            src={require('../../assets/images/twitch-partner-icon.svg').default}
                            alt='Twitch partner' title='Twitch partner'
                          />
                        </span>
                          ) : null}
                        </div>
                      </div>
                      <Link className='streamers__chat-link' to={`/chat/${streamer.id}`}>
                        <ChatIcon />
                        {t('myGyreEventsModal.startChat')}
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          :
          <div>
            <div className='my-gyre-events-modal__streamer-card'>
              <div className='my-gyre-events__streamer-info'>
                <div
                  className={`my-gyre-events__avatar-wrapper streamer-info__avatar-wrapper streamer-info__avatar-wrapper--verified`}>
                  <img
                    className='my-gyre-events__streamer-avatar streamer-info__avatar'
                    src='https://papik.pro/uploads/posts/2021-09/1631745489_17-papik-pro-p-smailik-risunok-prostoi-20.png'
                  />
                </div>
                <div
                  className='my-gyre-events__streamer-info-wrapper my-gyre-events__streamer-info-wrapper--modal'>
                  <div className='my-gyre-events__nickname-wrapper streamer-info__nickname-wrapper'>
                      <span className='my-gyre-events__streamer-nickname streamer-info__nickname'>
                        {t('myGyreEventsModal.futureBuddy')}
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {countEventResponses !== 0 ?
          <button
            onClick={() => setInfoVisible(!infoVisible)}
            className={`my-gyre-events-modal__show-more-button ${
              infoVisible ? 'my-gyre-events-modal__show-more-button--opened' : ''
            }`}>
            <ArrowBottomIcon />
            {!infoVisible ? t('myGyreEventsModal.showMore') : t('myGyreEventsModal.showLess')}
          </button>
          : null}
      </div>
    )
  }

  const getInfoAboutMyResponsesToEvent = () => {
    return (
      <div>
        <div className='my-gyre-events-modal__streamer-card'>
          <div className='my-gyre-events__streamer-info'>
            <div
              className={`my-gyre-events__avatar-wrapper streamer-info__avatar-wrapper ${
                event.streamer.is_registered
                  ? 'streamer-info__avatar-wrapper--verified'
                  : ''
              }`}>
              <img
                className='my-gyre-events__streamer-avatar streamer-info__avatar'
                src={event.streamer.picture}
                alt=''
              />
            </div>
            <div
              className='my-gyre-events__streamer-info-wrapper my-gyre-events__streamer-info-wrapper--modal'>
              <div className='my-gyre-events__nickname-wrapper streamer-info__nickname-wrapper'>
                <span className='my-gyre-events__streamer-nickname streamer-info__nickname'>
                  {event.streamer.twitch_username}
                </span>
                {event.streamer.is_registered ? (
                  <span className='streamer__partner-icon streamer-info__partner-icon'>
                    <img
                      src={require('../../assets/images/gyre-verified-icon.svg').default}
                      alt='gyre verified'
                    />
                  </span>
                ) : null}
                {event.streamer.partner === 'Partner' ? (
                  <span className='streamer__partner-icon streamer-info__partner-icon'>
                    <img
                      src={require('../../assets/images/twitch-partner-icon.svg').default}
                      alt='twitch partner'
                    />
                  </span>
                ) : null}
              </div>
            </div>
            {event.streamer.is_registered ? (
            <Link className='streamers__chat-link-short' to={`/chat/${event.streamer.id}`} title={t('myGyreEventsModal.startChat')} >
              <ChatIcon />
            </Link>) : null}
            {event.streamer.is_content_creator && event.streamer.avg_viewers < 30 ? null : (
              <TwitchUserLink classNames='streamers__twitch-link'
                link={`https://www.twitch.tv/${event.streamer.twitch_username}`}
              />
            )}
          </div>
          <div
            className={`my-gyre-events-modal__more-content ${
              infoVisible ? 'my-gyre-events-modal__more-content--visible' : ''
            }`}>
              <span className='my-gyre-events-modal__games-label'>
                {t('myGyreEventsModal.streamerInfo.games')}
              </span>
            <div className='streamer-info__about'>
              <div className='streamer-info__break' />
              <StreamerGames
                classNames='my-gyre-events-modal__games-list'
                gameList={gamesOnPage}
                streamerGameIds={event.streamer.game_ids}
              />
            </div>
            <div className='streamer-info__info'>
              <ul className='streamer-info__info-list my-gyre-events-modal__info-list'>
                <li className='streamer-info__info-item my-gyre-events-modal__info-item'>
                  <span className='streamer-info__info-title'>
                    {t('streamerInfo.avgViews')}
                  </span>
                  <span className='streamer-info__info-value'>
                    {getAvgViewersBucket(event.streamer.avg_viewers)}
                  </span>
                </li>
                <li className='streamer-info__info-item my-gyre-events-modal__info-item'>
                  <span className='streamer-info__info-title'>
                    {t('streamerInfo.followers')}
                  </span>
                  <span className='streamer-info__info-value'>
                    {valueToStringOrNoData(event.streamer.followers)}
                  </span>
                </li>
                <li className='streamer-info__info-item my-gyre-events-modal__info-item'>
                  <span className='streamer-info__info-title'>
                    {t('streamerInfo.localTime')}
                  </span>
                  <span className='streamer-info__info-value'>
                    {event.streamer.stream_hours_min && event.streamer.stream_hours_max
                      ? `${moment
                        .utc()
                        .hour(event.streamer.stream_hours_min)
                        .tz(SettingsService.getUserTimezone())
                        .locale('en')
                        .format('hh:00 A')} - 
                        ${moment
                        .utc()
                        .hour(event.streamer.stream_hours_max)
                        .tz(SettingsService.getUserTimezone())
                        .locale('en')
                        .format('hh:00 A')}`
                      : 'No data'}
                  </span>
                </li>
                <li className='streamer-info__info-item my-gyre-events-modal__info-item'>
                  <span className='streamer-info__info-title'>
                    {t('streamerInfo.mature')}
                  </span>
                  <span className='streamer-info__info-value'>
                    {getIsMatureString(event.streamer)}
                  </span>
                </li>
                <li className='streamer-info__info-item my-gyre-events-modal__info-item'>
                  <span className='streamer-info__info-title'>
                    {t('streamerInfo.language')}
                  </span>
                  <span className='streamer-info__info-value'>
                    {setStreamerLanguage(event.streamer.languages)}
                  </span>
                </li>
                <li className='streamer-info__info-item my-gyre-events-modal__info-item'>
                  <span className='streamer-info__info-title'>
                    {t('streamerInfo.twitchPartner')}
                  </span>
                  <span className='streamer-info__info-value'>
                    {event.streamer.partner === 'Partner'
                      ? t('filtersComponent.partnership.partner')
                      : t('filtersComponent.partnership.affiliate')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <button
            onClick={() => setInfoVisible(!infoVisible)}
            className={`my-gyre-events-modal__show-more-button ${
              infoVisible ? 'my-gyre-events-modal__show-more-button--opened' : ''
            }`}>
            <ArrowBottomIcon />
            {!infoVisible ? t('myGyreEventsModal.showMore') : t('myGyreEventsModal.showLess')}
          </button>
        </div>
      </div>
    )
  }


  return (
    <section className='event-details'>
      <div className='event-details__background' />
      <div className='event-details__modal-wrapper'>
        <div className='event-details__modal'>
          <div className='event-details__wrapper'>
            <div className='event-details__header'>
              <h2 className='event-details__title'>{event.event_name}
                <button
                    className='event-details__button event-details__copy-button'
                    onClick={() => {
                      onCopyLinkClick();
                    }}>
                  <CopyIcon alt={t('copyLink')} title={t('copyLink')}  />
                </button>
              </h2>
              <button
                className='event-details__button event-details__close-button'
                onClick={() => {
                  onCloseClick();
                }}>
                <CloseIcon />
              </button>
            </div>
            <div className='my-gyre-events-modal__status-wrapper'>
              <span
                className={`my-gyre-events-modal__status my-gyre-events-modal__status--${eventStatus}`}>
                {determineStatus(eventStatus)}
              </span>
              <span className='my-gyre-events-modal__request-date'>
                {`${requestsCategory === 'outgoing' ? t('myGyreEventsModal.createdAt') : t('myGyreEventsModal.requested')} ${moment
                  .utc(event.created_at)
                  .tz(SettingsService.getUserTimezone())
                  .locale(i18n.language)
                  .format('LLL')}`}
              </span>
              {event.is_private && (
                  <div className='event-details__private_label'>
                    {t('EventCreateModalComponent.form.collaboration.private')}
                  </div>)}
            </div>

            {requestsCategory === 'outgoing' ? (
              getInfoAboutResponsesToMyEvent()
              ) :
              getInfoAboutMyResponsesToEvent()
            }

            <div>
              <div className='event-details__about streamer-info__about'>
                <div className='event-details__section-title'>
                  {t('myGyreEventsModal.eventDetails.eventDescription')}
                </div>
                <div className='event-details__break streamer-info__break' />
                <div className='event-details__section-text'>{event.description}</div>
              </div>
              <div className='event-details__about streamer-info__about'>
                <div className='event-details__section-title'>
                  {t('myGyreEventsModal.eventDetails.whatIStream')}
                </div>
                <div className='event-details__break streamer-info__break' />
                <div>
                  {listGameNames.map((game) => {
                    return (
                      <button className='event-details__games-item-button'>
                        {game}
                      </button>
                    );
                  })}
                </div>
              </div>
              <div className='event-details__about streamer-info__about'>
                <div className='event-details__section-title'>
                  {t('myGyreEventsModal.eventDetails.whenIStream')}
                </div>
                <div className='event-details__break streamer-info__break' />
                <div className='event-details__section-text'>{event.when}</div>
              </div>
              <div className='event-details__about streamer-info__about'>
                <div className='event-details__section-title'>
                  {t('myGyreEventsModal.eventDetails.aboutMe')}
                </div>
                <div className='event-details__break streamer-info__break' />
                <div className='event-details__section-text event-details__section-author'>{event.about_creator}</div>
              </div>
            </div>
          </div>
          <div className='event-details__indent' />
          {eventStatus === EventStatuses.pending && requestsCategory === 'outgoing' ? (
            <div>
              <div className='my-gyre-events-modal__response-buttons-wrapper'>
                <ResponseButtons
                  setEventStatus={setEventStatus}
                  eventId={event.id}
                  setNewStatus={setNewStatus}
                  toggleConfirmModal={toggleConfirmModal}
                  getEventData={getEventData}
                />
              </div>
              <div>
                <button
                  type='button'
                  onClick={handleClick}
                  id='accept-event'
                  className='event-details__send-request-button'>
                  {t('myGyreEventsModal.editEvent')}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default GyreEventModal;
