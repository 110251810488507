import {useTranslation} from 'react-i18next';
import {useState, useEffect} from 'react';

const SuggestBtn = ({onClick, enabled, hasEmail=false}) => {
  const {t} = useTranslation();
  
  const handleClick = () => {
    if (enabled){
      onClick();
    }
  };
  
  return (
    <div className='suggest-button__wrapper'>
      <button className={`suggest-button ${!enabled ? 'suggest-button--disabled' : !hasEmail && 'suggest-button--no-email'} `} 
              onClick={handleClick} title={!hasEmail ? t('findCollabPage.userHasNoEmail') : ""}>
        {t('suggestBtn')}
      </button>
      {!enabled ? <p className='suggest-button__disabled-description'>
        {t('findCollabPage.maxCountCollabMessage')}
      </p> : ''}
    </div>
  );
};

export default SuggestBtn;
