import queryString from "query-string";
import {getStreamerLocation} from "../utils/Utils";
const {REACT_APP_GYRE_BACKEND} = process.env;

const getStreamerGamesList = (allGamesList, streamerGameIds) => {
    if (!streamerGameIds || !allGamesList) return [];
    return allGamesList.filter(game => {
        return streamerGameIds.includes(game.id);
    });
};

class StreamersService {
    
    static getStreamers = async (params) => {
        if ( (!params.streamer_ids || params.streamer_ids.length === 0) && !params.limit) {
            // can't allow for asking all streamers from backend if no ids are provided
            params.limit = 5;
        }
        
        const streamersParams = queryString.stringify(
            params,
            {
                skipEmptyString: true,
                skipNull: true
            });

        return await fetch(`${REACT_APP_GYRE_BACKEND}/api/streamers?${streamersParams}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
            .then( streamers => streamers.json())
            .then( streamersData => {
                streamersData.streamers.forEach( (s) => {
                    s.timezone = getStreamerLocation(streamersData.streamers_timezone, s.id);
                    s.games = getStreamerGamesList(streamersData.games, s.game_ids);
                } );
                return streamersData;
        });
    }

    static getStreamersByIds(ids, activeOnly = false, registeredOnly = false, validOnly = true) {
        return StreamersService.getStreamers({
            streamer_ids: ids,
            active_only: activeOnly,
            registered_only: registeredOnly,
            valid_only: validOnly
        });
    }

    static getStreamer = async (streamerId, activeOnly = false, registeredOnly = false, validOnly = true) => {
        const params = queryString.stringify({
            streamer_ids: [streamerId],
            active_only: activeOnly,
            registered_only: registeredOnly,
            valid_only: validOnly
        }, 
            {
                skipEmptyString: true,
                skipNull: true
            });
        
        const streamersData = await fetch(`${REACT_APP_GYRE_BACKEND}/api/streamers?${params}`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        }).then(streamersData => streamersData.json());
        
        const streamers = streamersData.streamers;
        
        if (streamers.length > 0) {
            const streamer = streamers[0];
            streamer.timezone = getStreamerLocation(streamersData.streamers_timezone, streamer.id);
            streamer.games = getStreamerGamesList(streamersData.games, streamer.game_ids);            
            return streamer;
        }
        else  {
            return null;
        }
    }
}

export default StreamersService;