import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import StreamerGames from './StreamerGames.js';
import {useContext, useEffect, useState} from 'react';
import EventDetailsModal from '../events/EventDetailsModal.js';
import {getAbilityToRespondForCurrentUser} from "../../utils/Utils";
import StreamersService from "../../services/StreamersService";
import AppContext from "../../store/AppContext";
import GamesService from "../../services/GamesService";
import EventsService from "../../services/EventsService";
import {PartnershipStatus} from "../../utils/Collections";
import TwitchUserLink from "../TwitchUserLink";
import {ReactComponent as ChatIcon} from "../../assets/images/new-chat-icon.svg";

const TopEvents = ({
  openSignUpModal
}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [eventList, setEventList] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [abilityToRespondToAnEvent, setAbilityToRespondToAnEvent] = useState(false);
  const context = useContext(AppContext);

  const getEvents = async () => {
    const streamersIds = [];
    const gamesIds = [];
    const params = {
      pending_only: true,
      offset: 0,
      limit: 3,
      sorting: 'newest',
      active_only: false,
      valid_only: true,
      registered_only: false,
      event_game_ids: context.isAuthenticated && context.user.user_filters.game_ids.length !== 0
        ? context.user.user_filters.game_ids
        : null,
      languages: context.isAuthenticated && context.user.user_filters.languages[0] !== ''
        ? context.user.user_filters.languages
        : null
    };

    const eventsData = await EventsService.getEvents(params);
    await eventsData.events.forEach(item => {
      streamersIds.push(item.author_id);
      item.game_ids.forEach(game => gamesIds.push(game));
    });
    
    if (eventsData.events.length === 0) {
      setLoading(false);
      return;
    }

    const streamersData = await StreamersService.getStreamersByIds(streamersIds, false, false, false);
    const games = await GamesService.getGames(gamesIds);

    setGameList(games.data);

    eventsData.events.forEach(event => {
      streamersData.streamers.forEach(streamer => {
        if (event.author_id === streamer.id) {
          event.streamer = streamer;
        }
      });
    });

    setEventList(eventsData.events);
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
  }, []);

  const toggleEventDetails = e => {
    setSelectedEvent(e);
    setShowEventDetailsModal(!showEventDetailsModal);
  };

  useEffect(() => {
    getAbilityToRespondForCurrentUser(selectedEvent, setAbilityToRespondToAnEvent);
  }, [selectedEvent]);

  const renderStreamerInfo = (event) => {
    return (
        <>
          <div
              className={`streamers__avatar-wrapper streamer-info__avatar-wrapper ${
                  event.streamer.is_content_creator ? 'streamer-info__avatar-wrapper--system' : (event.streamer.is_registered ? 'streamer-info__avatar-wrapper--verified' : '')
              }`}>
            <img
                className='streamers__avatar streamer-info__avatar'
                src={event.streamer.is_content_creator ?  require('../../assets/images/logo-min.svg').default: event.streamer.picture}
                alt=''
                title={event.streamer.is_content_creator ? "Moderator" : null}
            />
          </div>
          <div className='streamers__nickname-wrapper streamer-info__nickname-wrapper'>
                  <span className='streamers__nickname streamer-info__nickname' title={event.streamer.twitch_username}>
                    { event.streamer.twitch_username}
                  </span>
            {event.streamer.is_registered ? (
                <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                          src={require('../../assets/images/gyre-verified-icon.svg').default}
                          alt='gyre verified' title='Registered Gyre user'
                      />
                    </span>
            ) : null}

            {event.streamer.is_content_creator ? (
                <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                          src={require('../../assets/images/twitch-system-icon.svg').default}
                          alt='Gyre system' title='Gyre System'
                      />
                    </span>
            ) : (event.streamer.partner === PartnershipStatus.Partner ? (
                <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                          src={require('../../assets/images/twitch-partner-icon.svg').default}
                          alt='twitch partner' title='Twitch Partner'
                      />
                    </span>
            ) : null)}
          </div>
          {event.streamer.is_content_creator && event.streamer.avg_viewers < 30 ? null : (
            <TwitchUserLink
                classNames='events__twitch-link '
                link={`https://www.twitch.tv/${event.streamer.twitch_username}`}
            />)
          }
          {event.streamer.is_registered ? (
              <Link className='streamers__chat-link' to={`/chat/${event.streamer.id}`}>
                <ChatIcon />
                {t('gyreEvents.apply')}
              </Link>
          ) : null}
        </>
    );
  };

  return (<>
    {!loading && eventList.length !== 0 ? (<section className='top-events'>
      <div className='top-events__header'>
        <div className='top-events__title-wrapper'>
          <h3 className='top-events__title'>{t('findCollabPage.gyreEvents')}</h3>
          <p className='top-events__description'>{t('findCollabPage.gyreEventsDescr')}</p>
        </div>
        <Link to='/gyre-events' className='top-events__all-events-link'>
          <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0857 11.6715 16.0989 12.2811 15.7372 12.6757L10.2372 18.6757C9.86396 19.0828 9.23139 19.1103 8.82427 18.7372C8.41716 18.364 8.38965 17.7314 8.76284 17.3243L13.6159 12.0301L8.29289 6.70711Z' fill='#ffffff' />
          </svg>
          {t('findCollabPage.viewAll')}
        </Link>
      </div>
      <ul className='top-events__list'>
        {eventList.map(event => (<li className='top-events__item' key={event.id} onClick={() => {
          if (context.isAuthenticated) {
            toggleEventDetails(event);
          } else {
            openSignUpModal();
          }
        }}>
          <div className='streamer-info__about top-events__about'>
            <div
                className={`streamers__avatar-wrapper streamer-info__avatar-wrapper ${
                    event.streamer.is_content_creator ? 'streamer-info__avatar-wrapper--system' : (event.streamer.is_registered ? 'streamer-info__avatar-wrapper--verified' : '')
                }`}>
              <img
                className='streamers__avatar streamer-info__avatar'
                src={event.streamer.is_content_creator ?  require('../../assets/images/logo-min.svg').default: event.streamer.picture}
                alt=''
              />
            </div>
            <div className='streamers__nickname-wrapper streamer-info__nickname-wrapper'>
                  <span className='streamers__nickname streamer-info__nickname' title={event.streamer.twitch_username}>
                    {event.streamer.twitch_username}
                  </span>
              {event.streamer.is_registered ? (
                    <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                        src={require('../../assets/images/gyre-verified-icon.svg').default}
                        alt='gyre verified' title='Registered Gyre user'
                      />
                    </span>) : null}
              {event.streamer.is_content_creator ? (
                    <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                          src={require('../../assets/images/twitch-system-icon.svg').default}
                          alt='Gyre system' title='Gyre System'
                      />
                    </span>
              ) : event.streamer.partner === 'Partner' ? (
                  <span className='streamer__partner-icon streamer-info__partner-icon'>
                      <img
                          src={require('../../assets/images/twitch-partner-icon.svg').default}
                          alt='twitch partner' title='Twitch Partner'
                      />
                    </span>) : null}
            </div>
            <div className='streamers__break streamer-info__break' />
            <StreamerGames
              classNames='top-events__games-list'
              gameList={gameList}
              streamerGameIds={event.game_ids}
            />
          </div>
          <div className='top-events__description-wrapper'>
            <p className='top-events__event-title'>{event.event_name}</p>
            <p className='top-events__event-description'>
              {event.description}
            </p>
          </div>
        </li>))}
      </ul>
    </section>) : null}
    {showEventDetailsModal ? (<EventDetailsModal
      onCloseClick={toggleEventDetails}
      event={selectedEvent}
      abilityToRespondToAnEvent={abilityToRespondToAnEvent}
      gameList={gameList}
      showEventDetailsModal={showEventDetailsModal}
      setShowEventDetailsModal={setShowEventDetailsModal}
    />) : null}
  </>);
};

export default TopEvents;