import {useState, useEffect} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import TwitchUserLink from '../TwitchUserLink';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon.svg';
import {ReactComponent as CopyIcon} from '../../assets/images/copy-icon.svg';
import SettingsService from '../../services/SettingService';
import StreamerGames from '../find-collab/StreamerGames.js';
import queryString from 'query-string';
import ConfirmCollabModal from '../ConfirmCollabModal';
import {SubmitStatuses} from "../../utils/Collections";
import Utils, {getAvgViewersBucket, getIsMatureString, valueToStringOrNoData} from "../../utils/Utils";
import GamesService from "../../services/GamesService";
import EventsService, {EventStatuses} from "../../services/EventsService";
import {ReactComponent as ArrowBottomIcon} from "../../assets/images/arrow-bottom-icon.svg";
import {Link} from "react-router-dom";
import {ReactComponent as ChatIcon} from "../../assets/images/new-chat-icon.svg";

const moment = require('moment');
require('moment/min/locales.min');

const EventDetailsModal = ({
  onCloseClick,
  event,
  gameList,
  abilityToRespondToAnEvent,
  showEventDetailsModal,
  setShowEventDetailsModal
}) => {
  const {t, i18n} = useTranslation();
  const {REACT_APP_GYRE_BACKEND} = process.env;
  const [submitStatus, setSubmitStatus] = useState();
  const [messageIsOpened, setMessageIsOpened] = useState(false);
  const [buttonTextApply, setButtonTextApply] = useState(t('gyreEvents.acceptEvent'));
  const maxLengthBigField = '200';
  const [games, setGames] = useState(gameList);
  

  const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true);
    const [minLengthError, setMinLengthError] = useState(false);

    useEffect(() => {
      for (const validation in validations) {
        switch (validation) {
          case 'minLength':
            value.length < validations[validation]
              ? setMinLengthError(true)
              : setMinLengthError(false);
            break;

          case 'isEmpty':
            value ? setEmpty(false) : setEmpty(true);
            break;

          default:
            break;
        }
      }
    }, [value]);

    return {
      isEmpty,
      minLengthError
    };
  };

  const useInput = (initinalValue, validations) => {
    const [value, setValue] = useState(initinalValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChange = e => {
      setValue(e.target.value);
    };

    const onBlur = e => {
      setDirty(true);
    };

    return {
      value,
      onChange,
      onBlur,
      ...valid,
      isDirty
    };
  };

  const throwInputError = input => {
    if (input.isDirty && input.isEmpty) {
      return (
        <span className='event-details__error-message'>
          {t('gyreEvents.emptyFieldError')}
        </span>
      );
    } else if (input.isDirty && input.minLengthError) {
      return (
        <span className='event-details__error-message'>
          {t('gyreEvents.charactersError')}
        </span>
      );
    }
  };

  const messageToInput = useInput('', {
    isEmpty: true,
    minLength: 10
  });

  const validColors = input => {
    if (input.isDirty && input.minLengthError) {
      return 'error';
    } else if (input.isDirty && input.minLengthError === false) {
      return 'success';
    }
  };

  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (messageToInput.minLengthError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [messageToInput]);

  const getPrettyDate = utcDateString => {
    const date = moment(utcDateString).tz(SettingsService.getUserTimezone()).locale(i18n.language);
    const YESTERDAY = moment().clone().subtract(1, 'days').startOf('day');
    const TODAY = moment().clone().startOf('day');

    if (date.isSame(TODAY, 'd')) {
      return t('gyreEvents.dateToday') + ' ' + date.format('LT');
    } else if (date.isSame(YESTERDAY, 'd')) {
      return t('gyreEvents.dateYesterday') + ' ' + date.format('LT');
    }
    return date.format('lll');
  };

  const confirmEventModalOnSuccessHandler = () => {
    setShowEventDetailsModal(!showEventDetailsModal);
    window.location.assign(`/chat/${event.streamer.id}`);
  };
  
  const onCopyLinkClick = () => {
    Utils.copyToClipboard(window.location.host + '/gyre-events/' + event.id);
  };
  
  const confirmEventModalOnErrorHandler = () => {
    setShowEventDetailsModal(!showEventDetailsModal);
  };

  let data = queryString.stringify({
    cover_letter: messageToInput.value
  });

  const sendEventResponseRequest = () => {
    const submitButton = document.getElementById('accept-event');
    submitButton.disabled = true;

    fetch(`${REACT_APP_GYRE_BACKEND}/api/event/${event.id}/response`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: data
    }).then(res => {
      if (res.ok) {
        setSubmitStatus(SubmitStatuses.Success);
      } else {
        setSubmitStatus(SubmitStatuses.Error);
      }
    });
  };

  const handleClick = e => {
    if (!messageIsOpened) {
      setMessageIsOpened(true);
      setButtonTextApply(t('gyreEvents.apply'));
    } else {
      if (!formValid) {
        e.target.classList.add('event-details__send-request-button--error');
        e.target.disabled = true;
        setButtonTextApply(t('gyreEvents.errorApply'));

        setTimeout(() => {
          e.target.classList.remove('event-details__send-request-button--error');
          e.target.disabled = false;
          setButtonTextApply(t('gyreEvents.apply'));
        }, 2000);
      } else {
        sendEventResponseRequest();
      }
    }
  };
  
  useEffect(() => {
    if (!games) {
      // lazy load games
      GamesService.getGames(event.game_ids).then(gamesData => {
          setGames(gamesData.data);
        });
    }

    if (!eventResponsesList) {
      // lazy load event responses
      EventsService.getEventResponses(event.id).then(eventResponses => {
        setEventResponsesList(eventResponses);
        setDisplayEventResponsesList(eventResponses.slice(0, 3));
        setCountEventResponses(eventResponses.length);
      });
    }
  }, []);

  const [eventResponsesList, setEventResponsesList] = useState(null);
  const [displayEventResponsesList, setDisplayEventResponsesList] = useState([]);
  const [countEventResponses, setCountEventResponses] = useState(0);
  const [isResponsesListCollapsed, setIsResponsesListCollapsed] = useState(false);

  useEffect(() => {
    if (eventResponsesList) {
      if (isResponsesListCollapsed){
        setDisplayEventResponsesList(eventResponsesList)
      } else {
        setDisplayEventResponsesList(eventResponsesList.slice(0, 3))
      }
    }
  }, [isResponsesListCollapsed]);

  const renderInfoAboutResponsesToEvent = () => {
    return (
        <>
          {countEventResponses > 0
              &&
              <div className='event-details__about streamer-info__about'>
              <div className='event-details__section-title'>
                  {countEventResponses > 0
                      ? countEventResponses + ' ' + t('myGyreEventsModal.countPeopleToJoin')
                      : t('myGyreEventsModal.noPeopleToJoin')
                  }
              </div>
              <div className='event-details__break streamer-info__break' />
              <div>
                {displayEventResponsesList.map((streamer) => {
                  return (
                    <div className='my-gyre-events__streamer-info'>
                            <div
                                className={`my-gyre-events__avatar-wrapper streamer-info__avatar-wrapper-small ${
                                    streamer.is_registered
                                        ? 'streamer-info__avatar-wrapper--verified'
                                        : ''
                                }`}>
                              <img
                                  className='my-gyre-events__streamer-avatar streamer-info__avatar'
                                  src={streamer.picture}
                                  alt=''
                              />
                            </div>
                            <div
                                className='my-gyre-events__streamer-info-wrapper my-gyre-events__streamer-info-wrapper--modal'>
                              <div className='my-gyre-events__nickname-wrapper streamer-info__nickname-wrapper'>
                              <span className='streamer-info__nickname-small'>
                                {streamer.twitch_username}
                              </span>
                                {streamer.is_registered ? (
                                    <span className='streamer__partner-icon streamer-info__partner-icon'>
                                <img
                                    src={require('../../assets/images/gyre-verified-icon.svg').default}
                                    alt='gyre verified'
                                />
                              </span>
                                ) : null}
                                {streamer.partner === 'Partner' ? (
                                    <span className='streamer__partner-icon streamer-info__partner-icon'>
                                      <img
                                          src={require('../../assets/images/twitch-partner-icon.svg').default}
                                          alt='Twitch partner' title='Twitch partner'
                                      />
                                    </span>
                                ) : null}
                              </div>
                              
                        </div>
                    </div>
                  );
                })}
              </div>
                {countEventResponses > 1 && (
                  <button
                      onClick={() => setIsResponsesListCollapsed(!isResponsesListCollapsed)}
                      className={`my-gyre-events-modal__show-more-button ${
                          isResponsesListCollapsed ? 'my-gyre-events-modal__show-more-button--opened' : ''
                      }`}>
                    <ArrowBottomIcon />
                    {!isResponsesListCollapsed ? t('myGyreEventsModal.showMore') : t('myGyreEventsModal.showLess')}
                  </button>
                )}
              </div>
          }
        </>
    )
  }

  return submitStatus === SubmitStatuses.Success
    ? (
      <ConfirmCollabModal
        title={t('EventCreateModalComponent.form.ModalSuccessTitle')}
        message={t('gyreEvents.ModalSuccessMessage')}
        onClick={confirmEventModalOnSuccessHandler}
        goTo={'/gyre-events'}
      />
    ) : submitStatus === SubmitStatuses.Error
      ? (
        <ConfirmCollabModal
          title={t('EventCreateModalComponent.form.ModalErrorTitle')}
          message={t('EventCreateModalComponent.form.ModalErrorMessage')}
          showEmail={true}
          onClick={confirmEventModalOnErrorHandler}
          goTo='/gyre-events'
        />
      ) : (
        <section className='event-details'>
          <div className='event-details__background' />
          <div className='event-details__modal-wrapper'>
            <div className='event-details__modal'>
              <div className='event-details__wrapper'>
                <div className='event-details__header'>
                  <h2 className='event-details__title'>
                    {event.event_name}
                    <button
                        className='event-details__button event-details__copy-button'
                        onClick={() => {
                          onCopyLinkClick();
                        }}>
                      <CopyIcon alt={t('copyLink')} title={t('copyLink')}  />
                    </button>
                  </h2>
                  <button
                    className='event-details__button event-details__close-button'
                    onClick={() => {
                      onCloseClick();
                    }}>
                    <CloseIcon />
                  </button>
                </div>
                <div className='event-details__header-time'>
                  {t('gyreEvents.eventDetails.createdAt')} {getPrettyDate(event.created_at)}
                  {event.is_private && (
                  <div className='event-details__private_label'>
                    {t('EventCreateModalComponent.form.collaboration.private')}
                  </div>)}
                </div>

                <div className='event-details__streamer-card'>
                  <div className='event-details__info streamer-info__info'>
                    <div className='event-details__about streamer-info__about'>
                      <div
                        className={`event-details__avatar-wrapper streamer-info__avatar-wrapper 
                          ${event.streamer.is_content_creator ? 'streamer-info__avatar-wrapper--system' : (event.streamer.is_registered ? 'streamer-info__avatar-wrapper--verified' : '')}`}>
                        <img
                          className='event-details__avatar streamer-info__avatar'
                          src={event.streamer.is_content_creator ?  require('../../assets/images/logo-min.svg').default: event.streamer.picture}
                          alt=''
                        />
                      </div>
                      <div className='event-details__nickname-wrapper streamer-info__nickname-wrapper'>
                    <span className='event-details__nickname streamer-info__nickname'>
                      {event.streamer.twitch_username}
                    </span>
                        {event.streamer.is_registered ? (
                          <span className='streamer__partner-icon streamer-info__partner-icon'>
                        <img
                          src={require('../../assets/images/gyre-verified-icon.svg').default}
                          alt='gyre verified' title='Registered Gyre user'
                        />
                      </span>
                        ) : null}
                      {event.streamer.is_content_creator ? (
                      <span className='streamer__partner-icon streamer-info__partner-icon'>
                        <img
                            src={require('../../assets/images/twitch-system-icon.svg').default}
                            alt='Gyre system' title='Gyre System'
                        />
                      </span>
                      ) :
                          (event.streamer.partner === 'Partner' ? (
                          <span className='streamer__partner-icon streamer-info__partner-icon'>
                            <img
                                src={require('../../assets/images/twitch-partner-icon.svg').default}
                                alt='twitch partner'
                            />
                          </span>
                          ) : null)
                      }
                      </div>
                      {event.streamer.is_registered ? (
                          <Link className='streamers__chat-link-short' to={`/chat/${event.streamer.id}`} title={t('myGyreEventsModal.startChat')} >
                            <ChatIcon />
                          </Link>) : null}
                      {event.streamer.is_content_creator && event.streamer.avg_viewers < 30 ? null : (
                        <TwitchUserLink
                          classNames='event-details__twitch-link'
                          link={`https://www.twitch.tv/${event.streamer.twitch_username}`}
                      />)}
                    </div>
                    <ul className='event-details__info-list'>
                      <li className='event-details__info-item streamer-info__info-item'>
                    <span className='event-details__info-title streamer-info__info-title'>
                      {t('streamerInfo.avgViews')}
                    </span>
                        <span className='event-details__info-value streamer-info__info-value'>
                      {event.streamer.avg_viewers
                        ? getAvgViewersBucket(event.streamer.avg_viewers)
                        : 'No data'}
                    </span>
                      </li>
                      <li className='event-details__info-item streamer-info__info-item'>
                    <span className='event-details__info-title streamer-info__info-title'>
                      {t('streamerInfo.followers')}
                    </span>
                        <span className='event-details__info-value streamer-info__info-value'>
                          {valueToStringOrNoData(event.streamer.followers)}
                    </span>
                      </li>
                      <li className='event-details__info-item streamer-info__info-item'>
                    <span className='event-details__info-title streamer-info__info-title'>
                      {t('streamerInfo.whenIStream')}
                    </span>
                        <span className='event-details__info-value streamer-info__info-value'>
                      {event.streamer.stream_hours_min && event.streamer.stream_hours_max
                        ? `${moment
                          .utc()
                          .hour(event.streamer.stream_hours_min)
                          .tz(SettingsService.getUserTimezone())
                          .locale('en')
                          .format('hh:00 A')} - ${moment
                          .utc()
                          .hour(event.streamer.stream_hours_max)
                          .tz(SettingsService.getUserTimezone())
                          .locale('en')
                          .format('hh:00 A')}`
                        : 'No data'}
                    </span>
                      </li>
                      <li className='event-details__info-item streamer-info__info-item'>
                    <span className='event-details__info-title streamer-info__info-title'>
                      {t('streamerInfo.mature')}
                    </span>
                        <span className='event-details__info-value streamer-info__info-value'>
                          {getIsMatureString(event.streamer)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div className='event-details__about streamer-info__about'>
                    <div className='event-details__section-title'>
                      {t('gyreEvents.eventDetails.eventDescription')}
                    </div>
                    <div className='event-details__break streamer-info__break' />
                    <div className='event-details__section-text'>{event.description}</div>
                  </div>
                  <div className='event-details__about streamer-info__about'>
                    <div className='event-details__section-title'>
                      {t('gyreEvents.eventDetails.whatIStream')}
                    </div>
                    <div className='event-details__break streamer-info__break' />
                    <StreamerGames
                      classNames='event-details__games-list'
                      gameList={games}
                      streamerGameIds={event.game_ids}
                    />
                  </div>
                  <div className='event-details__about streamer-info__about'>
                    <div className='event-details__section-title'>
                      {t('gyreEvents.eventDetails.whenIStream')}
                    </div>
                    <div className='event-details__break streamer-info__break' />
                    <div className='event-details__section-text'>{event.when}</div>
                  </div>
                  <div className='event-details__about streamer-info__about'>
                    <div className='event-details__section-title'>
                      {t('gyreEvents.eventDetails.aboutMe')}
                    </div>
                    <div className='event-details__break streamer-info__break' />
                    <div className='event-details__section-text event-details__section-author'>{event.about_creator}</div>
                  </div>
                </div>
                {renderInfoAboutResponsesToEvent()}
                <div>
                  {messageIsOpened ? (
                    <div>
                      <div className='event-details__section-title'>
                        {t('gyreEvents.eventDetails.messageTo') + ' ' + event.streamer.twitch_username + ':'}
                      </div>
                      <div className='event-details__input-wrapper event-details__message-to-text'>
                        <TextareaAutosize
                          className={`event-details__input event-details__input--textarea ${validColors(
                            messageToInput
                          )} ${messageToInput.value.length > 0 ? 'filled' : ''}`}
                          name='about-yourself'
                          id='about-yourself-input'
                          placeholder={t('gyreEvents.eventDetails.messagePlaceholder') + ' ' + event.streamer.twitch_username}
                          value={messageToInput.value}
                          autoComplete='off'
                          maxLength={maxLengthBigField}
                          onChange={e => messageToInput.onChange(e)}
                          onBlur={e => messageToInput.onBlur(e)}
                        />
                      </div>
                      {throwInputError(messageToInput)}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='event-details__indent' />
              {event.status === EventStatuses.pending && abilityToRespondToAnEvent ? (
                <button
                  type='button'
                  onClick={e => handleClick(e)}
                  id='accept-event'
                  className='event-details__send-request-button'>
                  {t(buttonTextApply)}
                </button>
              ) : null}
            </div>
          </div>
        </section>
      );
};

export default EventDetailsModal;
